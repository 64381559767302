import React, {useEffect, useState} from 'react';
import {Select} from "antd";

const MultiSingleMenuWithSearch = (props) => {
    const { options, sortAlphabetically, width, searchable, notMultiple, labelProp, valueProp, clearOptionLabel, clearOptionValue, dontSort, style, ...rest } = props;
    const [newOptions, setNewOptions] = useState([]);

    useEffect(() => {
        if(options) {
            const temp = options.reduce((acc, item, index) => [...acc, {...item, label: item[labelProp ?? 'name'], value: item[valueProp ?? 'id'], key: index}],[])
            setNewOptions(temp)
        }
    }, [options]);

    return (
        <>
            <Select mode={notMultiple ? null : 'multiple'}
                    style={style ? style : {width: width ?? '100%'}}
                    maxTagCount={'responsive'}
                    getPopupContainer={() => document.getElementsByClassName('event-click-popup')[0]}
                    options={
                        clearOptionLabel ?
                        [{label: clearOptionLabel, value: clearOptionValue || null},...newOptions] :
                        newOptions
                    }
                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input)}
                    filterSort={
                        (optionA, optionB) =>
                            (optionA.label !== clearOptionLabel && optionB.label !== clearOptionLabel) && !dontSort &&
                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    showSearch={searchable}
                    {...rest}
            />
        </>
    );
};

export default MultiSingleMenuWithSearch;