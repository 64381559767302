import React from 'react';
import {Checkbox} from "antd";

const CustomCheckbox = (props) => {
    const { onChange, text, isChecked, returnEvent, ...rest } = props;

    return (
        <Checkbox checked={isChecked} onChange={(e) => onChange ? onChange(returnEvent ? e : e.target.checked) : null} {...rest}>{text}</Checkbox>
    );
};

export default CustomCheckbox;