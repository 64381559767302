import {Colors} from "../../styles/Colors";
import {getBorderColorByType} from "../../services/Helpers";
import {Avatar, Badge} from "antd";
import AppText from "./AppText";
import {isRTL} from "../../services/i18n";
import {IconSwitcher} from "../Icons8Generator/IconSwitcher";
import {GlobalConsts} from "../../styles/GlobalConsts";
import {FontAwesomeIcon} from "@fortawesome/react-native-fontawesome";
import React from "react";

const UserAvatar = (props) => {
    const { imageUri, firstName, lastName, size, bgColor, userType, textSize, badgeIconName, noAvatarSpace, badgeIconPrefix, style, ...rest } = props;

    return (
        <>
            <Avatar
                style={{
                    border: userType && userType !== 'user' && 'solid 2px ' + getBorderColorByType(userType),
                    boxShadow: userType && userType !== 'user' && 'inset 0px 0px 0px 2px' + Colors.newDesign.white,
                    backgroundColor: bgColor || Colors.newDesign.avatarBgColor,
                    alignItems: 'center',
                    display: 'flex',
                    margin: !noAvatarSpace && '0 3px',
                    minWidth: size || 30,
                    minHeight: size || 30,
                    ...style
                }}
                size={size || 30} //pixels - pass number to the prop and not for example '20px' if it gets distorted
                src={imageUri ? <img src={imageUri} /> : null}
                {...rest}
            >
                <AppText fontSize={textSize || '14px'}>
                    {firstName?.substr(0,1).toUpperCase()}{lastName?.substr(0,1).toUpperCase()}
                </AppText>
            </Avatar>
            {
                badgeIconName &&
                <div style={{ position: 'absolute', marginTop: -20, right: isRTL() && 26, left: !isRTL() && 26, backgroundColor: 'white', borderRadius: '100%', padding: '2px' }}>
                    <FontAwesomeIcon icon={IconSwitcher(badgeIconPrefix || GlobalConsts.iconPrefix.LIGHT,badgeIconName)} size={GlobalConsts.iconSize.XXS} />
                </div>
            }
        </>
    );
};

export default UserAvatar;
