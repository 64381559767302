import React, {useEffect, useMemo, useState} from 'react';
import {HStack, VStack, Text as NBText} from "native-base";
import AppText from "../../../../../common/AppText";
import {GlobalStyleAttributes, WebGlobalStyles} from "../../../../../../styles/GlobalStyles";
import {isRTL, t} from "../../../../../../services/i18n";
import moment from "moment";
import {dbDateFormat, dbTimeFormat, isMobile, sendDataEvent} from "../../../../../../services/Helpers";
import {fetchApi} from "../../../../../../services/HTTP";
import {Colors} from "../../../../../../styles/Colors";
import {useDispatch, useSelector} from "react-redux";
import {ActivityIndicator} from "react-native";
import {updateSummary} from "../../../../../../redux/stepperSlice";
import SharedTimepicker from "../../../../../common/SharedTimepicker";
import {FontAwesomeIcon} from "@fortawesome/react-native-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {flowOptions} from "../FlowOptions";
import {Radio} from "antd";
import CustomButtonV2 from "../../../../../common/CustomButtonV2";

const AvailabilitySlotSelection = (props) => {
    const { values, errors, setFieldValue, target, disableDate } = props;
    const box = useSelector((state) => state.box.box);
    const flowName = useSelector((state) => state.stepper.name);
    const editType = useSelector((state) => state.stepper.editType);
    const lang = useSelector((state) => state.userSettings.lang);
    const [slots, setSlots] = useState(null);
    const [selectedSlot, setSelectedSlot] = useState(null);
    const [isCurrentTargetSlot, setIsCurrentTargetSlot] = useState(false);
    const timeFormat = useMemo(() => box ? box.locations_box[0].time_format : null,[box])
    const mobile = isMobile
    const dispatch = useDispatch()

    useEffect(() => {
        if(values.box_category_fk) {
            getAvailabilitySlots()
        }
    }, [values.box_category_fk]);

    useEffect(() => {
        generateSummary()
    }, [values, errors]);

    useEffect(() => {
        if(values.date) {
            getAvailabilitySlots()
        }
    }, [values.date]);

    useEffect(() => {
        if(editType) {
            onCurrentTargetSlotSelected()
        }
    }, [editType]);

    useEffect(() => {
        sendDataEvent('add appointment pop up (with availability)- choose slot');
    }, []);

    const getAvailabilitySlots = async () => {
        const params = {
            availabilityId: target?.availability_id ?? target.id,
            date: values.date,
            locations_box_id: values.locations_box_fk,
            box_categories_id: values.box_category_fk,
            spaceAvailability: flowName === flowOptions.APPOINTMENT_WITH_SPACE_AVAILABILITY
        }
        const slotsRes = await fetchApi('availability/getAvailabilitySlots', 'post', params, false, true);
        setSlots(slotsRes)
    }

    const onSlotSelected = (slot) => {
        setSelectedSlot(slot)
        setFieldValue('time', moment(slot.time, 'HH:mm').format(dbTimeFormat))
        setFieldValue('end_time', moment(slot.end_time, 'HH:mm').format(dbTimeFormat))
        setIsCurrentTargetSlot(false)
    }

    const onCurrentTargetSlotSelected = () => {
        setSelectedSlot(null)
        setFieldValue('time', moment(target.time, 'HH:mm').format(dbTimeFormat))
        setFieldValue('end_time', moment(target.end_time, 'HH:mm').format(dbTimeFormat))
        setIsCurrentTargetSlot(true)
    }

    const generateSummary = () => {
        const time = moment(values.time, dbTimeFormat).format(timeFormat);
        const endTime = moment(values.end_time, dbTimeFormat).format(timeFormat);
        const date = moment(values.date, dbDateFormat).format(`dddd, ${(lang?.language == 'Hebrew') ? 'DD MMM' : 'MMM DD'}, YYYY`)
        const daysAndTimes = `${date}, ${t('fromTime')} ${time} ${t('toTime')} ${endTime}`
        let summary;
        summary = t(`${flowName}-summary-one-time`, {daysAndTimes: daysAndTimes})
        dispatch(updateSummary(summary))
    }

    const onDateChange = (name, value) => {
        const date = moment(value).format(dbDateFormat)
        setFieldValue(name, date)
    }

    return (
        <VStack space={'1.5rem'}>
            {slots ?
                <>
                    <VStack space={'0.2rem'} width={mobile ? '100%' : '50%'}>
                        <AppText fontSize={GlobalStyleAttributes.fonts.infoText} mediumFont>{t('date')}</AppText>
                        <SharedTimepicker isDisabled={disableDate} type={'date'} value={moment(values.date, dbDateFormat)} onChange={(date) => onDateChange('date', date)} inputStyle={WebGlobalStyles.customInputNew}/>
                    </VStack>
                    <VStack space={'0.2rem'} flex={1}>
                        <AppText fontSize={GlobalStyleAttributes.fonts.infoText} mediumFont>{t('time')}</AppText>
                        <Radio.Group name={'availability-slot-group'} value={isCurrentTargetSlot ? target : selectedSlot}>
                            <HStack space={'0.5rem'} width={'100%'} flexWrap={'wrap'}>
                                {editType &&
                                    <CustomButtonV2 onClick={onCurrentTargetSlotSelected}
                                                    key={`payment-method-${target.time}-${target.end_time}`}
                                                    style={{width: 'calc(50% - 0.5rem)', marginBottom: '0.5rem'}}
                                                    override={() =>
                                                        <HStack w={'100%'} space={'1rem'} p={'0.8rem'} borderWidth={1} borderColor={Colors.borderColor} borderRadius={4}>
                                                            <Radio value={target} size="sm" checked={isCurrentTargetSlot}/>
                                                            <AppText fontSize={GlobalStyleAttributes.fonts.infoText}>{moment(target.time, 'HH:mm').format(timeFormat)} - {moment(target.end_time, 'HH:mm').format(timeFormat)}</AppText>
                                                        </HStack>}
                                    />
                                }
                                {slots?.map(slot => (
                                    <CustomButtonV2 onClick={() => onSlotSelected(slot)}
                                                    key={`payment-method-${slot.time}-${slot.end_time}`}
                                                    style={{width: 'calc(50% - 0.5rem)', marginBottom: '0.5rem'}}
                                                    override={() =>
                                                        <HStack w={'100%'} space={'1rem'} p={'0.8rem'} borderWidth={1} borderColor={Colors.borderColor} borderRadius={4}>
                                                            <Radio value={slot} size="sm" checked={slot === selectedSlot}/>
                                                            <AppText fontSize={GlobalStyleAttributes.fonts.infoText}>{moment(slot.time, 'HH:mm').format(timeFormat)} - {moment(slot.end_time, 'HH:mm').format(timeFormat)}</AppText>
                                                        </HStack>}
                                    />
                                ))}
                                {slots.length === 0 &&
                                    <NBText style={{direction: 'inherit'}}>
                                        <VStack mt={'5px'} marginRight={isRTL() ? 0 : '5px'} marginLeft={isRTL() ? '5px' : 0}><FontAwesomeIcon size={'1rem'} icon={solid('triangle-exclamation')}/></VStack>
                                        <AppText fontSize={GlobalStyleAttributes.fonts.standard16}>{t('no-slots-open')} </AppText>
                                    </NBText>
                                }
                            </HStack>
                        </Radio.Group>
                    </VStack>
                </>
                :
                <ActivityIndicator/>
            }
        </VStack>
    );
};

export default AvailabilitySlotSelection;
