const CategoryColors = {
    'cat-color-1': '#D0FFF9',
    'cat-color-2': '#C0DCFE',
    'cat-color-3': '#C0EBFE',
    'cat-color-4': '#C8F5D2',
    'cat-color-5': '#E4FCB5',
    'cat-color-6': '#FFF6B8',
    'cat-color-7': '#FFE0B2',
    'cat-color-8': '#FBD0E8',
    'cat-color-9': '#FEC8C8',
    'cat-color-10': '#E5D0FB',
    'cat-color-11': '#D7DEE2',
    'cat-color-12': '#E5CCC9'
}
export default CategoryColors