export const BoxTypes = {
    CROSSFIT: "CrossFit",
    GROUP_TRAINING: "Group Training",
    YOGA: "Yoga",
    PILATES: "Pilates",
    OUTDOOR: "Outdoor",
    MARTIAL_ARTS: "Martial Arts",
    CYCLING: "Cycling",
    DANCE_STUDIO: "Dance Studio",
    RUN: "Run",
    OTHER: "Other",
    CLIMBING: "Climbing",
    PERSONAL_TRAINING: "Personal Training",
    GYM: "Gym",
    CORPORATE_TRAINING: "Corporate Training",
    COMMUNITY_CENTER_POOL: "Community Center/Pool",
    NUTRITIONISTS: "Nutritionists",
    PHYSIOTHERAPISTS: "Physiotherapists",
    PSYCHOLOGISTS: "Psychologists",
    CHIROPRACTORS: "Chiropractors",
    SPA_MASSAGE: "Spa / Massage",
    CLINICS: "Clinics",
    CERAMIC: "Ceramic",
    MUSICIANS: "Musicians",
    BANDS: "Bands",
    TUTORS: "Tutors",
    LANGUAGE_TEACHERS: "Language Teachers",
    PROFESSIONAL_COURSES: "Professional Courses",
    ONLINE_COURSES: "Online Courses",
    CHILD_TUTORS: "Child Tutors",
    NAIL_SALON: "Nail Salon",
    WAXING_SALON: "Waxing Salon",
    HAIR_SALON: "Hair Salon",
    BARBER_SHOPS: "Barber Shops",
    BEAUTY_SALON: "Beauty Salon",
    TANNING_STUDIO: "Tanning Studio",
    CONSULTANT: "Consultant",
    HANDYMAN: "Handyman",
    DOG_WALKER: "Dog Walker",
    BABYSITTING: "Babysitting",
    HOUSEKEEPER: "Housekeeper",
    BEAUTICIAN: "Beautician",
    TENNIS: "Tennis",
    TATTOO_STUDIO: "Tattoo Studio",
    SHOOTING_RANGE: "Shooting Range",
    SURFING: "Surfing",
    AFTER_SCHOOL_ACTIVITIES: "After School Activities",
    ARTS: "Arts",
    BARRE: "Barre",
    BATTING_CAGES: "Batting Cages",
    CHILD_CARE: "Child Care",
    DOGS_CARE: "Dogs Care",
    DOGS_TRAINING: "Dogs Training",
    EDUCATION: "Education",
    FOOTVOLLEY: "Footvolley",
    HYDROTHERAPY: "Hydrotherapy",
    ICE_SKATING: "Ice Skating",
    NINJA: "Ninja",
    PLAYGROUND: "Playground",
    POLE_FITNESS: "Pole Fitness",
    POSTPARTUM: "Postpartum",
    SKATING: "Skating",
    SOCCER_FOOTBALL: "Soccer/Football",
    SWIMMING: "Swimming",
    VOLLEYBALL: "Volleyball",
    YOUTH_SPORTS: "Youth sports",
    ARMY_PREP: "Army prep"
};

export const Packages = {
    FREE_ACCOUNT: "Free Account",
    ARBOX_BE_BETTER_ANNUALLY: "Arbox - Be Better (Annually)",
    ARBOX_BE_BETTER_MONTHLY: "Arbox - Be Better (Monthly)",
    ARBOX_GET_STRONGER_ANNUALLY: "Arbox - Get Stronger (Annually)",
    ARBOX_GET_STRONGER_MONTHLY: "Arbox - Get Stronger (Monthly)",
    ARBOX_THINK_BIGGER_ANNUALLY: "Arbox - Think Bigger (Annually)",
    ARBOX_THINK_BIGGER_MONTHLY: "Arbox - Think Bigger (Monthly)",
    GLOBAL_BASIC_MONTHLY: "Global Basic (Monthly)",
    GLOBAL_BASIC_ANNUALLY: "Global Basic (Annually)",
    GLOBAL_STANDARD_MONTHLY: "Global Standard (Monthly)",
    GLOBAL_STANDARD_ANNUALLY: "Global Standard (Annually)",
    GLOBAL_PROFESSIONAL_MONTHLY: "Global Professional (Monthly)",
    GLOBAL_PROFESSIONAL_ANNUALLY: "Global Professional (Annually)",
    IL_BASIC_MONTHLY: "IL Basic (Monthly)",
    IL_BASIC_ANNUALLY: "IL Basic (Annually)",
    IL_STANDARD_MONTHLY: "IL Standard (Monthly)",
    IL_STANDARD_ANNUALLY: "IL Standard (Annually)",
    IL_PROFESSIONAL_MONTHLY: "IL Professional (Monthly)",
    IL_PROFESSIONAL_ANNUALLY: "IL Professional (Annually)",
    TRIAL_MODE: "Trial Mode",
    TRIAL_MODE_MAX: "Trial Mode Max",
};

export const Roles = {
    ADMIN: "ADMIN",
    BOXADMIN: "BOXADMIN",
    REGULAR: "REGULAR",
    COACH: "COACH",
    SECRETARY: "SECRETARY",
    BASICMANAGER: "BASICMANAGER",
    MANAGER: "MANAGER",
    SALESMANAGER: "SALESMANAGER",
    SALESMAN: "SALESMAN",
    SERVICE: "SERVICE",
    RECEPTIONLIST: "RECEPTIONLIST",
    SIGNUPACCESS: "SIGNUPACCESS",
    AGGREGATOR: "AGGREGATOR",
    UNION: "UNION",
    SUPPORT: "SUPPORT",
    CS: "CS",
    SALES: "SALES",
    LEAD: "LEAD",
    ACCESSCONTROL: "ACCESSCONTROL"
};

export const StaffMemberRoles = {
    BOXADMIN: "BOXADMIN",
    COACH: "COACH",
    MANAGER: "MANAGER",
    SALESMANAGER: "SALESMANAGER",
    SALESMAN: "SALESMAN",
    SERVICE: "SERVICE",
    RECEPTIONLIST: "RECEPTIONLIST",
};

export const translations = {
    FITNESS: "fitness",
    BARBARSHOP: "barbarshop",
    BEAUTY: "beauty",
    NAILS: "nails",
    NUTRITIONIST: "nutritionist",
    PHYSIOTHERAPIST: "physiotherapist",
    SPA: "spa",
    TUTORS: "tutors",
    GENERAL: "general"
}

export const MAX_PACKAGE_GROUP_ID = 6;

export const BoxCategoryTypes = {
    CLASS: 1,
    APPOINTMENT: 2,
    WORKSHOP: 3,
    HUGIM: 4
}

export const MembershipTypes = {
    SESSION: 'session',
    PLAN: 'plan',
    SERVICE: 'service',
    WORKSHOP: 'workshop',
    ITEM: 'item',
    TRIAL_CLASS: 'trial',
    REGULAR: 'regular',
    RECURRING: 'recurring',
}

export const Statuses = {
    ACTIVE: 'active',
    CANCELLED: 'cancelled',
    STAND_BY: 'stand_by',
    BOOKED: 'booked',
    CANCELLED_STAND_BY: 'cancelled_stand_by',
}

export const CUSTOM_FIELD_LEAD_FLOW = 'CUSTOM_FIELD_LEAD_FLOW';