// import i18n from '../services/i18n';
import { Platform } from "react-native";
import { isRTL } from '../services/i18n';


export const isTextRTLStyles = {
    ...Platform.select({
        ios: {
            writingDirection: isRTL() ? 'rtl' : 'ltr',
        },
    }),
};

export function getIsRTLStyle(ltrStyle, rtlStyle) {
    return isRTL() ? rtlStyle : ltrStyle;
}
