import React, { useMemo } from 'react';
import {HStack, VStack} from "native-base";
import AppText from "./AppText";
import {GlobalStyleAttributes} from "../../styles/GlobalStyles";
import {GlobalConsts} from "../../styles/GlobalConsts";
import {FontAwesomeIcon} from "@fortawesome/react-native-fontawesome";
import {Colors} from "../../styles/Colors";
import {t} from "../../services/i18n";
import {IconSwitcher} from "../Icons8Generator/IconSwitcher";
import {light, solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import { useToast } from 'react-native-toast-notifications';
import CustomButtonV2 from "./CustomButtonV2";
import usePermissions from '../../services/Permissions';
import { useDispatch } from 'react-redux';
import { setOpenLockedPopup } from '../../redux/rootManagerSlice';

const SuccessStep = (props) => {
    const { header, whatsNextArray, children, link, ...rest} = props;
    const toast = useToast()
    const dispatch = useDispatch()
    const { hasLock } = usePermissions()

    return (
        <VStack space={'2rem'} {...rest}>
            <HStack space={'1rem'} alignItems={'center'}>
                <FontAwesomeIcon icon={solid('circle-check')} size={GlobalConsts.iconSize.S} color={Colors.newDesign.mainText}/>
                <AppText fontSize={GlobalStyleAttributes.fonts.heading1Mobile} boldFont>{header}</AppText>
            </HStack>
            <VStack>
                {children}
            </VStack>
            {link &&
            <CustomButtonV2 type={'link'}
                            onClick={() => link.onPress(toast)}
                            iconName={link.icon}
                            iconProps={{iconColor: Colors.newDesign.mainBlue, iconHoverColor: Colors.newDesign.mainBlueHover}}
                            text={link.text}
                            style={{justifyContent: 'start'}}
                            applyHoverOnText={true}
            />}
            {whatsNextArray &&
            <VStack flex={1} space={'1rem'} height={'3rem'} p={'1rem'} bg={Colors.newDesign.lightBlue} borderRadius={4}>
                <AppText fontSize={GlobalStyleAttributes.fonts.heading2Desktop} boldFont>{t('whatsNext')}</AppText>
                <VStack space={'1rem'}>
                    {whatsNextArray.map(item => (
                        <CustomButtonV2 onClick={hasLock(item.permission) ? () => dispatch(setOpenLockedPopup(true)) : item.onPress}
                                        override={() =>
                                            <HStack bg={Colors.newDesign.white} p={'1rem'} flex={1} space={'1rem'} alignItems={'center'} borderRadius={4}>
                                                <FontAwesomeIcon icon={IconSwitcher(GlobalConsts.iconPrefix.LIGHT, item.iconName)} size={GlobalConsts.iconSize.M} />
                                                <VStack flex={1}>
                                                    <HStack space={'1rem'} alignItems={'center'}>
                                                        <AppText fontSize={GlobalStyleAttributes.fonts.heading3} boldFont>{item.header}</AppText>
                                                        {item.headerAddOns}
                                                    </HStack>
                                                    <AppText fontSize={GlobalStyleAttributes.fonts.heading3}>{item.subheader}</AppText>
                                                </VStack>
                                                {hasLock(item.permission) && <FontAwesomeIcon style={{alignSelf: 'start'}} icon={light('lock-keyhole')} size={GlobalConsts.iconSize.XS}/>}
                                            </HStack>}
                        />
                    ))}
                </VStack>
            </VStack>}
        </VStack>
    );
};

export default SuccessStep;
