import React, {useEffect, useMemo, useState} from 'react';
import {HStack, VStack} from "native-base";
import AppText from "../../../../../common/AppText";
import {GlobalStyleAttributes} from "../../../../../../styles/GlobalStyles";
import {t} from "../../../../../../services/i18n";
import {Colors} from "../../../../../../styles/Colors";
import CartSummary from "../../../../../common/CartSummary";
import {ENTER_DEBT, FREE_PURCHASE} from "../../../../../../services/ConstHelper";
import {optionalSteps} from "../StepOptions";
import {sendDataEvent} from "../../../../../../services/Helpers";
import {useSelector} from "react-redux";
import {Radio} from "antd";
import CustomButtonV2 from "../../../../../common/CustomButtonV2";

const PaymentMethod = (props) => {
    const { values, errors, setFieldValue } = props;
    const selectionsTree = useSelector((state) => state.stepper.selectionsTree);
    const options = useMemo(() => ({[ENTER_DEBT]: t('enterDebt'), [FREE_PURCHASE]: t('freeAppointment')}),[])

    useEffect(() => {
        if(!values.scheduleUser.paymentObject.method && options) setFieldValue('scheduleUser.paymentObject.method',Object.keys(options)[0])
    }, [options]);

    useEffect(() => {
        if(selectionsTree[optionalSteps.EVENT_TYPE]) {
            sendDataEvent(`add ${selectionsTree[optionalSteps.EVENT_TYPE]} pop up - Payment`);
        }
    }, [selectionsTree[optionalSteps.EVENT_TYPE]]);

    return (
        <VStack space={'1rem'}>
            <VStack space={'0.2rem'} w={'100%'}>
                <AppText fontSize={GlobalStyleAttributes.fonts.infoText} mediumFont>{t('payment-option')}</AppText>
                <Radio.Group name={'users-membership-group'} value={values.scheduleUser.paymentObject.method}>
                    <VStack space={'0.5rem'} width={'100%'}>
                        {Object.keys(options).map(option => (
                            <CustomButtonV2 onClick={() => setFieldValue('scheduleUser.paymentObject.method',option)}
                                            key={`payment-method-${option}`}
                                            iconProps={{iconColor: Colors.borderColor, iconHoverColor: Colors.hoverBlueLight}}
                                            override={({color}) => (
                                                <HStack space={'0.5rem'} p={'1rem'} w={'100%'} borderWidth={1} borderRadius={4} borderColor={option === values.scheduleUser.paymentObject.method ? Colors.newDesign.mainBlue : color}>
                                                    <Radio onClick={(e) => {
                                                        e.stopPropagation()
                                                        setFieldValue('scheduleUser.paymentObject.method',option)
                                                    }} value={option} size="sm" checked={option === values.scheduleUser.paymentObject.method}/>
                                                    <AppText fontSize={GlobalStyleAttributes.fonts.standard16} boldFont>{options[option]}</AppText>
                                                </HStack>
                                            )}
                            />
                        ))}
                    </VStack>
                </Radio.Group>
            </VStack>
            <CartSummary cart={[values.scheduleUser.paymentObject.cart]} isFree={values.scheduleUser.paymentObject.method === FREE_PURCHASE}/>
        </VStack>
    );
};

export default PaymentMethod;
