import { HStack } from "native-base";
import AppText from "../../../../../common/AppText";
import { GlobalStyleAttributes } from "../../../../../../styles/GlobalStyles";
import { t } from "../../../../../../services/i18n";
import InfoTooltip from "../../../../../common/InfoTooltip";
import { SCHEDULE_TYPE, USER_TYPE } from "../../../../../../services/ConstHelper";
import SimpleInput from "../../../../../common/SimpleInput";
import CustomButtonV2 from "../../../../../common/CustomButtonV2";
import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import {GlobalConsts} from "../../../../../../styles/GlobalConsts";

const DraggableCustomFieldOption = (props) => {
    const { option, index, setFieldValue, values, isScheduleType } = props;
    const { transform, transition, isDragging, setNodeRef, listeners } = useSortable({
        id: option.id
    });

    const commonStyle = {
        cursor: 'move',
        marginTop: '10px',
        transition: 'unset', // Prevent element from shaking after drag
    };

    const style = transform
        ? {
            ...commonStyle,
            transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
            transition: isDragging ? 'unset' : transition, // Improve performance/visual effect when dragging
        }
        : commonStyle;

    const handleOptionUpdate = (val, index) => {
        setFieldValue(`props.options[${index}].value`, val);
        setFieldValue(`props.options[${index}].label`, val);
    };

    const handleOptionDelete = (deleteIndex) => {
        const newArray = [...values.props.options].filter((option, index) => index !== deleteIndex);
        setFieldValue(`props.options`, newArray);
    };

    return (
        <div style={style} ref={setNodeRef}>
            <HStack space={'5px'} mb={'5px'}>
                <AppText fontSize={GlobalStyleAttributes.fonts.infoText} mediumFont>{t('option')} {index + 1}</AppText>
            </HStack>
            <HStack space={'1rem'} alignItems={'center'}>
                <SimpleInput onChangeCallback={val => handleOptionUpdate(val, index)}
                             maxLength={30}
                             value={values.props.options[index].label}
                             showCount
                             status={null}
                />
                <CustomButtonV2
                    onClick={() => handleOptionDelete(index)}
                    iconName={'fa-trash'} type={'link'} />
                <CustomButtonV2
                    iconName={'fa-grip-dots-vertical'}
                    type={'link'}
                    style={{ cursor: 'move' }}
                    iconProps={{ iconPrefix: GlobalConsts.iconPrefix.SOLID, iconHoverColor: 'unset' }}
                    key={index + option.id}
                    {...listeners}
                />
            </HStack>
        </div>
    );
};

export default DraggableCustomFieldOption;
