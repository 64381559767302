import {useEffect, useState} from "react";
import {useSelector} from "react-redux";

const usePermissions = () => {
    const user = useSelector((state) => state.user.user);
    const [allPermissions, setAllPermissions] = useState(user?.permissions || []);

    useEffect(() => {
        if(user) {
            setAllPermissions(user.permissions)
        }
    }, [user]);

    /**
     * 
     * @param {string} permission 
     * @returns {boolean} if user has this permission
     */
    const has = (permission) => allPermissions.includes(permission);

    /**
     * 
     * @param {string} permission 
     * @returns {boolean} if user has this permission locked
     */
    const hasLock = (permission) => allPermissions.includes(`${permission}Lock`);

    /**
     * 
     * @param {Array<string>} permissions 
     * @returns {boolean} if user has all permissions
     */
    const hasAll = (permissions) => permissions.every(p => allPermissions.includes(p));

    /**
     * 
     * @param {Array<string>} permissions 
     * @returns {boolean} if user has any of the permissions
     */
    const hasAny = (permissions) => permissions.some(p => allPermissions.includes(p));

    return { has, hasAny, hasAll, hasLock };
}

export default usePermissions;