import React from 'react';
import EStyleSheet from "react-native-extended-stylesheet";
import UnicodeHelper from "../../services/UnicodeHelper";
import { GlobalStyles, GlobalStyleAttributes } from "../../styles/GlobalStyles";
import { isRTL } from "../../services/i18n";
import { Text } from 'react-native';
import { isMobile } from '../../services/Helpers';
import { Colors } from '../../styles/Colors';

const AppText = (props) => {
    const { variant, boldFont, mediumFont, defaultColor, hoverColor, color, width, fontSize, mt, mb, my, children, textDirection, ...rest } = props;
    const styles = getStyle({ boldFont, mediumFont, color, width, mt, mb, my, textDirection });
    return (
        <Text {...rest}
              style={[
                styles.myAppText,
                styles[variant || GlobalStyleAttributes.textVariants.heading3],
                props.style,
                fontSize && { fontSize: fontSize },
                hoverColor && { color: hoverColor },
                defaultColor && { color: Colors.newDesign.mainText },
              ]}>
                <UnicodeHelper />
                {children}
        </Text>
    );
};

export default AppText;

const getStyle = ({ boldFont, mediumFont, color, width, mt, mb, my, textDirection }) => EStyleSheet.create({
    myAppText: {
        fontFamily: boldFont && 'PloniSemiBold' || mediumFont && 'PloniMedium' || 'Ploni' ,
        flexWrap: 'wrap',
        alignItems: 'flex-start',
        color: color || Colors.newDesign.mainText,
        marginTop: my || mt,
        marginBottom: my || mb,
        width: width,
        writingDirection: textDirection || 'inherit'
    },
    heading1: {
        fontFamily: 'PloniSemiBold',
        fontSize: isMobile ? GlobalStyleAttributes.fonts.heading1Mobile : GlobalStyleAttributes.fonts.heading1Desktop,
    }, 
    heading2: {
        fontFamily: 'PloniSemiBold',
        fontSize: isMobile ? GlobalStyleAttributes.fonts.heading2Mobile : GlobalStyleAttributes.fonts.heading2Desktop,
    }, 
    heading3: {
        fontSize: isMobile ? GlobalStyleAttributes.fonts.heading3Mobile : GlobalStyleAttributes.fonts.heading3,
    }, 
    heading4: {
        fontSize: GlobalStyleAttributes.fonts.heading4,
    }, 
});

