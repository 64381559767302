import React, {useEffect, useMemo, useState} from 'react';
import AppText from "../../../../../common/AppText";
import {GlobalStyleAttributes} from "../../../../../../styles/GlobalStyles";
import {isRTL, t} from "../../../../../../services/i18n";
import {Divider, HStack, Pressable, ScrollView, VStack, Text as NBText} from "native-base";
import ProTipBox from "../../../../../common/ProTipBox";
import AdvanceSelect from "../../../../../common/AdvanceSelect";
import {
    EXISTING_MEMBERSHIP,
    NO_MEMBERSHIP,
    ONE_TIME_PURCHASE,
    USERS_SELECT
} from "../../../../../../services/ConstHelper";
import {Colors} from "../../../../../../styles/Colors";
import moment from "moment";
import {dbDateFormat, sendDataEvent} from "../../../../../../services/Helpers";
import EStyleSheet from "react-native-extended-stylesheet";
import {useDispatch, useSelector} from "react-redux";
import {addToSelectionsTree, cleanStepper} from "../../../../../../redux/stepperSlice";
import {optionalSteps} from "../StepOptions";
import {FontAwesomeIcon} from "@fortawesome/react-native-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {closeModal, CREATE_EVENT_MODAL, CREATE_USER_MODAL, openModal, updateModalRes} from "../../../../../../redux/modalManagerSlice";
import {Radio} from "antd";
import CustomButtonV2 from "../../../../../common/CustomButtonV2";
import CustomCheckbox from "../../../../../common/CustomCheckbox";

const UserAndMembershipSelection = (props) => {
    const { values, errors, setFieldValue, oneTimeMembership, showOneTimePurchase, navigate, showProTip } = props;
    const selectedLocation = useSelector(state => state.userSettings.scheduleLocation)
    const selectionTree = useSelector(state => state.stepper.selectionsTree)
    const createUserModalRes = useSelector(state => state.modalManager.createUserModal.processRes)
    const currencySymbol = useMemo(() => selectedLocation ? selectedLocation.currency_symbol : '',[selectedLocation])
    const dispatch = useDispatch()

    useEffect(() => {
        sendDataEvent('add appointment pop up- UserAndMembershipSelection');
    }, []);

    useEffect(() => {
    },[values, errors])

    useEffect(() => {
        if(createUserModalRes) {
            onUserSelected({...createUserModalRes, label: createUserModalRes.full_name, membership_user: []})
            dispatch(updateModalRes({modalName: CREATE_USER_MODAL, res: null}))
        }
    }, [createUserModalRes]);

    const setValue = (name, value, shouldParseInt = false) => {
        setFieldValue(name, shouldParseInt ? (isNaN(parseInt(value)) ? '' : parseInt(value)) : value)
    }

    const onUserSelected = (user) => {
        setValue('scheduleUser.membership_user_fk', null)
        setValue('scheduleUser.user_fk', user?.user_fk)
        setValue('scheduleUser.isLead', user?.type === 'lead')
        setValue('scheduleUser.isNoMembership', false)
        const memberships = user?.membership_user.filter(item => !(item.sessions_left === 0 && item.membership_types.type === 'session'))
        const newUser = user ? {...user, membership_user: memberships} : null
        dispatch(addToSelectionsTree({user: newUser}))
        if(newUser) {
            if(newUser.membership_user.length > 0) {
                addUserMembership(newUser.membership_user[0].id)
            }
            else if(newUser.membership_user.length === 0 && !showOneTimePurchase) {
                addUserWithoutMembership();
            }
            else {
                //if only pay for slot option is available
                if(showOneTimePurchase && oneTimeMembership && newUser.type !== 'lead') {
                    addOneTimePurchaseMembership()
                }
            }
        }
    }

    const getMembershipSubheader = (membership) => {
        let subheader = t('membership-no-expiration-date')
        if(membership.end && membership.sessions_left) {
            subheader = t('membership-end-date-sessions-left', {date: moment(membership.end, dbDateFormat).format('MMM DD, YYYY'), sessions: membership.sessions_left})
        } else if(membership.end) {
            subheader = t('membership-end-date', {date: moment(membership.end, dbDateFormat).format('MMM DD, YYYY')})
        } else if (membership.sessions_left) {
            subheader = t('membership-sessions-left', {sessions: membership.sessions_left})
        }
        return subheader;
    }

    const addOneTimePurchaseMembership = () => {
        setValue('scheduleUser.membership_user_fk', null)
        setValue('scheduleUser.paymentObject', {cart: oneTimeMembership})
        setValue('scheduleUser.isNoMembership', false)
        dispatch(addToSelectionsTree({[optionalSteps.USER_AND_MEMBERSHIP_SELECTION]: ONE_TIME_PURCHASE}))
    }

    const addUserMembership = (membershipUserId) => {
        setValue('scheduleUser.membership_user_fk', membershipUserId)
        setValue('scheduleUser.paymentObject', null)
        setValue('scheduleUser.isNoMembership', false)
        dispatch(addToSelectionsTree({[optionalSteps.USER_AND_MEMBERSHIP_SELECTION]: EXISTING_MEMBERSHIP}))
    }

    const addUserWithoutMembership = () => {
        setValue('scheduleUser.membership_user_fk', null)
        setValue('scheduleUser.paymentObject', null)
        setValue('scheduleUser.isNoMembership', true)
        dispatch(addToSelectionsTree({[optionalSteps.USER_AND_MEMBERSHIP_SELECTION]: NO_MEMBERSHIP}))
    }

    const goToUserProfile = () => {
        navigate('/user-profile/' + selectionTree['user'].user_fk);
        dispatch(cleanStepper())
        dispatch(closeModal({modalName: CREATE_EVENT_MODAL}))
    }

    return (
        <VStack space={'1.5rem'}>
            <VStack space={'0.5rem'} w={'100%'}>
                <AppText fontSize={GlobalStyleAttributes.fonts.infoText} mediumFont>{t('select-client')}</AppText>
                <AdvanceSelect initValue={values.scheduleUser.user_fk} type={USERS_SELECT} onChange={onUserSelected} value={selectionTree['user']} isClearable={true} hideFreefitType hideCustomerId/>
                {showOneTimePurchase && oneTimeMembership &&
                <CustomButtonV2 onClick={() => dispatch(openModal({modalName: CREATE_USER_MODAL, props: {navigate, viaFunnel: true}}))}
                                text={`+ ${t('add-new-client')}`}
                                applyHoverOnText={true}
                                type={'link'}
                                style={{justifyContent: 'start'}}
                                iconProps={{iconColor: Colors.newDesign.mainBlue, iconHoverColor: Colors.newDesign.mainBlueHover}}
                />}
                <CustomCheckbox text={t('send-invitation-checkbox')} isChecked={!!values.scheduleUser.sendInvitation} onChange={val => setFieldValue('scheduleUser.sendInvitation', val)}/>
                {showProTip && <ProTipBox text={t('appointment-user-select-tip')}/>}
            </VStack>
            {selectionTree['user'] &&
                <Radio.Group name={'users-membership-group'} value={values.scheduleUser.membership_user_fk || values.scheduleUser.paymentObject?.cart.id}>
                    <VStack space={'0.2rem'} w={'100%'}>
                        <AppText fontSize={GlobalStyleAttributes.fonts.infoText} mediumFont>{t('booking-options')}</AppText>
                        <ScrollView maxHeight={EStyleSheet.value('$membershipSelectionListHeight')}>
                            <VStack space={'0.5rem'}>
                                {selectionTree['user'].membership_user.map(membership => (
                                    <CustomButtonV2 onClick={() => addUserMembership(membership.id)} key={`membership-user-radio-${membership.id}`}
                                                    iconProps={{iconColor: Colors.borderColor, iconHoverColor: Colors.hoverBlueLight}}
                                                    override={({ color }) => (
                                                        <HStack space={'1rem'} p={'1rem'} alignItems={'start'} borderWidth={1} borderRadius={4} borderColor={membership.id === values.scheduleUser.membership_user_fk ? Colors.newDesign.mainBlue : color} w={'100%'}>
                                                            <Radio onClick={(e) => {
                                                                e.stopPropagation()
                                                                addUserMembership(membership.id)
                                                            }} value={membership.id} size="sm" checked={membership.id === values.scheduleUser.membership_user_fk}/>
                                                            <VStack space={'0.5rem'}>
                                                                <AppText fontSize={GlobalStyleAttributes.fonts.standard16} boldFont>{membership.membership_types?.name}</AppText>
                                                                <AppText fontSize={GlobalStyleAttributes.fonts.standard16}>{getMembershipSubheader(membership)}</AppText>
                                                            </VStack>
                                                        </HStack>)}
                                    />
                                ))}
                                {showOneTimePurchase && oneTimeMembership && selectionTree['user'].type !== 'lead' &&
                                <>
                                    {selectionTree['user'].membership_user.length > 0 && <Divider orientation="horizontal" bg={Colors.borderColor} thickness="1" w={'100%'}/>}
                                    <CustomButtonV2 onClick={addOneTimePurchaseMembership}
                                                    iconProps={{iconColor: Colors.borderColor, iconHoverColor: Colors.hoverBlueLight}}
                                                    override={({ color }) => (
                                                        <HStack space={'1rem'} p={'1rem'} w={'100%'} borderWidth={1} borderRadius={4} borderColor={oneTimeMembership.id === values.scheduleUser.paymentObject?.cart.id ? Colors.newDesign.mainBlue : color}>
                                                            <Radio onClick={(e) => {
                                                                e.stopPropagation()
                                                                addOneTimePurchaseMembership()
                                                            }} value={oneTimeMembership.id} size="sm" selected={oneTimeMembership.id === values.scheduleUser.paymentObject?.cart.id}/>
                                                            <HStack space={'0.5rem'} alignItems={'center'}>
                                                                <AppText fontSize={GlobalStyleAttributes.fonts.standard16} boldFont>{oneTimeMembership.name}</AppText>
                                                                <Divider orientation="vertical" bg={Colors.newDesign.darkGrey} thickness="1" h={"15px"}/>
                                                                <AppText fontSize={GlobalStyleAttributes.fonts.standard16} color={Colors.newDesign.darkGrey}>{currencySymbol} {oneTimeMembership.price}</AppText>
                                                            </HStack>
                                                        </HStack>)}
                                    />
                                </>
                                }
                                {
                                    selectionTree['user'].membership_user.length === 0 && selectionTree['user'].type !== 'lead' && !showOneTimePurchase &&
                                    <CustomButtonV2
                                                    onClick={addUserWithoutMembership}
                                                    iconProps={{iconColor: Colors.borderColor, iconHoverColor: Colors.hoverBlueLight}}
                                                    override={() => (
                                                        <HStack space={'1rem'} p={'1rem'} w={'100%'} borderWidth={1} borderRadius={4} borderColor={Colors.newDesign.mainBlue}>
                                                            <Radio onClick={(e) => {
                                                                e.stopPropagation()
                                                                addUserWithoutMembership()
                                                            }} value={oneTimeMembership?.id} size="sm" selected={true}/>
                                                            <HStack space={'0.5rem'} alignItems={'center'}>
                                                                <AppText fontSize={GlobalStyleAttributes.fonts.standard16} boldFont>{t('blockRegisterWithoutMembershipTitle')}</AppText>
                                                            </HStack>
                                                        </HStack>)}
                                    />
                                }
                                {selectionTree['user'].type === 'lead' &&
                                <NBText style={{direction: 'inherit'}}>
                                    <VStack mt={'5px'} marginRight={isRTL() ? 0 : '5px'} marginLeft={isRTL() ? '5px' : 0}><FontAwesomeIcon size={'1rem'} icon={solid('triangle-exclamation')}/></VStack>
                                    <AppText fontSize={GlobalStyleAttributes.fonts.standard16}>{t('lead-appointment-register-explain')} </AppText>
                                </NBText>
                                }
                            </VStack>
                        </ScrollView>
                    </VStack>
                </Radio.Group>
            }
        </VStack>
    );
};

export default UserAndMembershipSelection;
