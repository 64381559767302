import React from 'react';
import EStyleSheet from 'react-native-extended-stylesheet';
import { View } from 'react-native';
import {GlobalStyleAttributes} from "../../styles/GlobalStyles";
import ArboxLoader from '../../loaders/arbox-loader.gif';

const Loader = props => {
    const { transparent, width, height, relative, marginTop, marginLeft, autoSize, ...rest } = props;
    const styles = getStyle({transparent, width, height, relative, autoSize});

    return (
        <View style={[styles.root, {top: marginTop, left: marginLeft}]}>
            <img style={styles.animation} src={ArboxLoader} />
        </View>
    );
};

export default Loader;

const getStyle = ({transparent, width, height, relative, autoSize}) => EStyleSheet.create({
    root: {
        width: width || '100%',
        height: height || '100vh',
        backgroundColor: transparent ? 'rgba(255,255,255, 0.6)' : 'rgba(255,255,255, 1)',
        transform: 'translate3d(0px, 0px, 0px)',
        display: 'flex',
        placeContent: 'center',
        position: relative ? 'relative' : 'absolute',
        zIndex: GlobalStyleAttributes.zIndex.loader,
    },
    animation: {
        width: width || 70,
        height: height || 70,
        margin: 'auto'
    }
});