import React, {useEffect, useMemo, useState} from 'react';
import EStyleSheet from "react-native-extended-stylesheet";
import {Divider, HStack, VStack} from "native-base";
import {Colors} from "../../styles/Colors";
import AppText from "./AppText";
import {GlobalStyleAttributes} from "../../styles/GlobalStyles";
import {t} from "../../services/i18n";
import {useSelector} from "react-redux";

const CartSummary = (props) => {
    const { cart, isFree } = props;
    const selectedLocation = useSelector(state => state.userSettings.scheduleLocation)
    const currencySymbol = useMemo(() => selectedLocation ? selectedLocation.currency_symbol : '',[selectedLocation])
    const [calculations, setCalculations] = useState(null);

    useEffect(() => {
        if(cart) calculatePrices()
    }, [cart]);

    const calculatePrices = () => {
        if(!isFree) {
            const taxes = cart[0].membership_types_taxes
            const data = cart.reduce((acc, item) => {
                const priceOfTaxes = taxes.length ? acc.tax + taxes.reduce((sum, tax) => sum + ((tax.percentage / 100) * item.price), 0) : acc.tax
                const subTotal = acc.subTotal + item.price
                return {
                    subTotal: subTotal,
                    tax: acc.tax + priceOfTaxes,
                    total: acc.total + subTotal + priceOfTaxes
                }
            }, {subTotal: 0, tax: 0, total: 0})
            setCalculations(data)
        } else {
            setCalculations({subTotal: 0, tax: 0, total: 0})
        }
    }

    return (
        <VStack bg={Colors.newDesign.menuBar} p={'1rem'} space={'0.5rem'}>
            <HStack justifyContent={'space-between'}>
                <AppText fontSize={GlobalStyleAttributes.fonts.heading3}>{t('subTotal')}</AppText>
                <AppText fontSize={GlobalStyleAttributes.fonts.heading3}>{currencySymbol}{calculations?.subTotal}</AppText>
            </HStack>
            {calculations?.tax > 0 &&
                <HStack justifyContent={'space-between'}>
                    <AppText fontSize={GlobalStyleAttributes.fonts.heading3}>{t('tax')}</AppText>
                    <AppText fontSize={GlobalStyleAttributes.fonts.heading3}>{currencySymbol}{calculations?.tax}</AppText>
                </HStack>
            }
            <Divider orientation="horizontal" bg={Colors.borderColor} thickness="1" w={'100%'} my={'0.5rem'}/>
            <HStack justifyContent={'space-between'}>
                <AppText fontSize={GlobalStyleAttributes.fonts.heading3} mediumFont>{t('total-price')}</AppText>
                <AppText fontSize={GlobalStyleAttributes.fonts.heading3} mediumFont>{currencySymbol}{calculations?.total}</AppText>
            </HStack>
        </VStack>
    );
};

export default CartSummary;
