import moment from "moment";
import { initFlowConfig } from "../components/Structure/Schedule/Popups/CreateEvent/FlowOptions";
import { Roles } from "../Configs/DatabaseConsts";
import { openModal } from "../redux/modalManagerSlice";
import { pushStepperStack } from "../redux/stepperSlice";
import { store } from "../redux/store";


export const openStepperModal = ({modalName, dispatch, props, onClose}) => {
    const modalManager = store.getState().modalManager;
    if (modalManager[modalName]?.isOpen) {
        dispatch(pushStepperStack(initFlowConfig({...props, selection: props.initSelection})))
    }
    else {
        dispatch(openModal({ modalName, props, onClose }))
    }

}

export const showToast = (defaultVal = true) => {
    const box = store.getState().box?.box
    const user = store.getState().user?.user
    if (user.roles.some((role) => [Roles.BOXADMIN, Roles.MANAGER].includes(role))) {
        return moment().diff(box?.created_at, 'months') >= 2 ? defaultVal : false
    }
    return true
}