import React, {useEffect, useMemo, useState} from 'react';
import {HStack, VStack} from "native-base";
import {Colors} from "../../../../../../styles/Colors";
import AppText from "../../../../../common/AppText";
import {GlobalStyleAttributes} from "../../../../../../styles/GlobalStyles";
import {t} from "../../../../../../services/i18n";
import {optionalSteps} from "../StepOptions";
import {useDispatch, useSelector} from "react-redux";
import {updateTempSelection} from "../../../../../../redux/stepperSlice";
import {Radio} from "antd";
import CustomButtonV2 from "../../../../../common/CustomButtonV2";

const OneOnOneType = (props) => {
    const { } = props;
    const selectionsTree = useSelector(state => state.stepper.selectionsTree)
    const types = useMemo(() => [
        {id: 'appointment', name: t('appointments-one-on-one')},
        {id: 'availability', name: t('availability-one-on-one')}
    ],[]);
    const [selection, setSelection] = useState(selectionsTree[optionalSteps.APPOINTMENT_OR_AVAILABILITY_STEP] ?? types[0].id);
    const dispatch = useDispatch()

    useEffect(() => {
        if(!selectionsTree[optionalSteps.APPOINTMENT_OR_AVAILABILITY_STEP] || selectionsTree[optionalSteps.APPOINTMENT_OR_AVAILABILITY_STEP] !== selection)
            dispatch(updateTempSelection({[optionalSteps.APPOINTMENT_OR_AVAILABILITY_STEP]: selection}))
    }, [selection]);

    return (
        <Radio.Group name={'event-type-radio'} value={selection}>
            <VStack space={'1rem'} w={'100%'}>
                {
                    types.map(type => (
                            <CustomButtonV2 onClick={() => setSelection(type.id)}
                                            key={`appointment-or-availability-radio-option-${type.id}`}
                                            iconProps={{iconColor: Colors.borderColor, iconHoverColor: Colors.hoverBlueLight}}
                                            override={({color}) =>
                                                (
                                                    <HStack space={'0.5rem'} alignItems={'start'} p={'1rem'} w={'100%'} borderWidth={1} borderColor={selection === type.id ? Colors.newDesign.mainBlue : color} borderRadius={4}>
                                                        <Radio value={type.id} size="sm"/>
                                                        <VStack flex={1}>
                                                            <AppText fontSize={GlobalStyleAttributes.fonts.heading3} boldFont>{type.name}</AppText>
                                                            <AppText fontSize={GlobalStyleAttributes.fonts.heading3}>{t(`${type.id}-one-on-one-explain`)}</AppText>
                                                        </VStack>
                                                    </HStack>
                                                )}
                            />
                        )
                    )
                }
            </VStack>
        </Radio.Group>
    );
};

export default OneOnOneType;
