import EStyleSheet from "react-native-extended-stylesheet";
import { isTextRTLStyles } from "./Layout";
import { isRTL } from '../services/i18n';
import i18n from 'i18next';
import {Colors} from "./Colors";
import {Platform} from "react-native";

export const GlobalStyles = EStyleSheet.create({
    directionText: {
        ...isTextRTLStyles
    },
    textAlignByRTL: {
        textAlign: isRTL() ? 'right' : 'left',
    },
    directionWrap: {
        alignItems: isRTL() ? 'flex-start' : 'flex-end',
    },
    direction: {
        writingDirection: i18n.dir()
    },
    errorLabel: {
        color: Colors.error
    },
    menuBoxShadow: {
        boxShadow: '1px 1px 7px #817c7c63'
    }
});

export const GlobalStyleAttributes = {
    zIndex: { // Manage ALL z-indexes of the system - ONLY here
        menu: 3,
        contentWrapper: 1,
        sideBar: 2,
        header: 1,
        mobileNavigator: 2,
        backDrop: 3,
        angularFrameWithBackdrop: 4,
        logoActionsOnHover: 2,
        loader: 99999999,
        mobiscrollPopup: 800
    },
    fonts: {
        standard16: '16px', //16px
        infoText: '0.9rem', //14.4px
        mainHeader: '1.25rem', //20px
        mobileHeader: '1.25rem', //20px
        bigHeader: '1.875rem', //30px
        heading1Desktop: '1.875rem', //30px
        heading2Desktop: '1.25rem', //20px
        heading3: '1rem',//16px
        heading4: '0.875rem',//14px
        heading1Mobile: '1.625rem',//26px
        heading2Mobile: '1.25rem', //20px
        heading3Mobile: '16px', //16px
        scheduleItemFont: '0.85rem',
        checkinHeader: '1.375rem', //22px
        heading18: '18px', //18px
    },
    textVariants: {
        heading1: 'heading1',
        heading2: 'heading2',
        heading3: 'heading3',
        heading4: 'heading4',
    },
}

export const WebGlobalStyles = EStyleSheet.create({
    ...Platform.select({
        web: {
            explanationScheduleSettings: {
                fontSize: '0.9rem',
                color: Colors.newDesign.mainText,
                padding: '1rem',
            },
            normalText: {
                fontSize: '0.9rem',
            },
            errorText: {
                color: Colors.error
            },
            smallGreyText: {
                fontSize: '0.85rem',
                color: Colors.newDesign.darkGrey,
            },
            smallBlackText: {
                fontSize: '0.85rem',
                color: Colors.newDesign.mainText,
            },
            customInput: {
                backgroundColor: Colors.newDesign.lightGrey,
                height: '40px',
                borderRadius: 4,
                padding: '0.7rem',
                justifyContent: 'center'
            },
            customInputNew: {
                backgroundColor: Colors.newDesign.white,
                borderColor: Colors.borderColor,
                borderWidth: 1,
                height: '$defaultButtonHeight',
                borderRadius: 4,
                padding: '0.7rem',
                justifyContent: 'center',
                fontSize: GlobalStyleAttributes.fonts.standard16
            },
            customTextField: {
                backgroundColor: Colors.newDesign.white,
                borderColor: Colors.borderColor,
                borderWidth: 1,
                height: '$defaultButtonHeight',
                borderRadius: 4,
                paddingBlock: '0',
                paddingInline: '0.7rem',
                justifyContent: 'center',
                fontSize: GlobalStyleAttributes.fonts.standard16
            },
            customTextAreaNew: {
                height: '$defaultTextAreaHeight',
            },
            popupHeader: {
                fontSize: '1.2rem'
            },
            textOverflow: {
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
            },
            blockedEvent: { //bah
                backgroundImage: `repeating-linear-gradient(45deg, ${Colors.newDesign.white} 0 6px, ${Colors.newDesign.greyBg} 6px 8px)`,
                border: `0.1rem solid ${Colors.borderColor}`
            },
            maxContent: {
                width: 'max-content'
            },
            filtersBodyDesktop: {
                maxHeight: '100% - $filtersOffsetScroll'
            },
            textIconGap: {
                gap: '0.5rem'
            },
            reportExtraHeader: {
                gap: '16px',
                height: '60px',
                padding: '10px',
                alignItems: 'center',
                backgroundColor: Colors.newDesign.white,
                marginBottom: '10px',
                flex: 1
            },
            reportFilterChip: {
                cursor: 'pointer',
                color: Colors.newDesign.mainText,
                borderColor: Colors.newDesign.white,
                backgroundColor: Colors.newDesign.white,
                paddingBlock: '0.3rem',
                paddingInline: '0.6rem',
                fontSize: 16,
                alignItems: 'center',
                columnGap: '5px'
            },
            reportExternalDateFilter: {
                backgroundColor: Colors.newDesign.white,
                borderRadius: '4px',
                boxShadow: '0 6px 16px 0 rgba(0, 0, 0, 0.08)',
            }
        }
    })
});

/**
 * @param {number} margin 
 * @returns {string}
 */
export const getMarginalMoveStyle = (margin) => {
    return {
        'margin-inline-start': `-${margin}px`,
        'margin-inline-end': `${margin}px`,
    };
}
