import React, {useEffect, useState} from 'react';
import {isMobile} from "../../services/Helpers";
import i18next from "i18next";
import {Modal} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Colors } from '../../styles/Colors';
import { GlobalConsts } from '../../styles/GlobalConsts';

const CustomModal = (props) => {
    const { open, onClose, onOk, body, header, bodyStyle, footer, width, height, children, footerStyle, okText, cancelText, confirmLoading, containerDOM, bottomOnMobile,  ...rest } = props;
    const [selfOpen, setSelfOpen] = useState(true);

    useEffect(() => {
        if(!selfOpen) { //This is for making the transition work on Drawer close
            setTimeout(() => onClose(), 500);
        }
    },[selfOpen])

     return (
             <Modal
                 getContainer={containerDOM || '#native-base-main-view'}
                 title={header}
                 open={selfOpen}
                 onOk={onOk}
                 onCancel={() => setSelfOpen(false)}
                 centered
                 width={isMobile ? '100%' : width}
                 height={isMobile ? (bottomOnMobile ? 'fit-content' : '100%') : height}
                 footer={footer}
                 bodyStyle={bodyStyle}
                 okText={okText}
                 cancelText={cancelText}
                 confirmLoading={confirmLoading}
                 closeIcon={<FontAwesomeIcon size={GlobalConsts.iconSize.SS} color={Colors.newDesign.darkGrey} icon={light('xmark')}/>}
                 wrapClassName={isMobile && bottomOnMobile ? 'mobile-bottom-modal' : ''}
                 {...rest}
             >
                 <div style={{ marginBottom: 15 }}>
                    {body}
                    {children}
                 </div>
             </Modal>
         );
     };


export default CustomModal;
