import React from 'react';
import {Button, Tooltip} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-native-fontawesome";
import {GlobalConsts} from "../../styles/GlobalConsts";
import {IconSwitcher} from "../Icons8Generator/IconSwitcher";
import './AntUI.scss'
import useIconHover from "../../Hooks/useIconHover";
import {isMobile} from "../../services/Helpers";
import {isRTL} from "../../services/i18n";

// WHAT THE WRITER (AKA ME (AKA eden AKA Ednan AKA Adnan AKA Edna)) INTENDED:   :)
// for CLICKABLE ICON use like: <CustomButtonV2 type="link" iconName={'fa-pen'}/>
// for CLICKABLE TEXT use like: <CustomButtonV2 type="link" text={'bla bla'}/>
// for SPECIFIC HOVER/INITIAL COLOR use iconProps like: <CustomButtonV2 type="link" text={'bla bla'} iconProps={{iconColor: 'RED', iconHoverColor: 'MORE-RED'}}/> (i know it's called icon props but it can be used regardless of icon)
// for SPECIFIC CLICKABLE CONTENT use override like: <CustomButtonV2 override={({color}) => <div>hi</div>} onClick={() => do()}/> (color is for hover uses if u need)
// for PRIMARY BLUE button use like: <CustomButtonV2 type={'primary'} />
// for OUTLINE button use like (no type needed): <CustomButtonV2 />

const CustomButtonV2 = (props) => {
    const { iconName, text, type, tooltipText, override, applyHoverOnText, textProps = {}, iconProps = {}, wrapperStyle = {}, additionalClassName, ...rest} = props;
    const [iconColorNew, handleHover, handleHoverOut, isHovered] = useIconHover({color: iconProps.iconColor, hoverColor: iconProps.iconHoverColor});

    const getBtn = () => {
        const textStyle = applyHoverOnText ? {color: iconColorNew, ...textProps} : textProps
        const moreProps = override ? {...rest} : {} //this is mostly for onClick when using override
        return (
            <div className={`custom-button-v2 ${!text && iconName ? 'hide-icon-margin' : ''} ${type === 'link' ? 'no-padding' : ''} ${additionalClassName ? additionalClassName : ''}`}
                 onMouseOver={handleHover}
                 onMouseOut={handleHoverOut}
                 style={{...wrapperStyle}}
                {...moreProps}
            >
                {override ? override({color: iconColorNew, isHovered}) :
                    <Button type={type} {...rest} icon={iconName ? <FontAwesomeIcon icon={IconSwitcher(iconProps.iconPrefix ?? GlobalConsts.iconPrefix.LIGHT, iconName)} size={iconProps.iconSize ?? GlobalConsts.iconSize.XS} color={iconColorNew} style={{...iconProps.restIconStyle}}  /> : null}>
                        <div style={textStyle}>{text}</div>
                    </Button>}
            </div>
        )
    }

    return (
        <>
        {(tooltipText && !isMobile) ?
            <Tooltip title={tooltipText} mouseEnterDelay={rest.tooltipMouseEnterDelay} placement={GlobalConsts.menu.placement.TOP}>
                {getBtn()}
            </Tooltip>
            :
            getBtn()
        }
        </>
    );
};

export default CustomButtonV2;
