import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {fetchApi} from "../services/HTTP";
import {hideLoader, showLoader} from "./rootManagerSlice";
import {t} from "i18next";

const tasksSlice = createSlice({
    name: 'tasks',
    initialState: {
        showCreateAnotherTaskPopUp: true,
        filters: {},
        tasksPreferences: {},
        error: null,
        successFlag: null,
        counter: null
    },
    reducers: {
        setShowCreateAnotherTaskPopUp: (state, data) => {
            state.showCreateAnotherTaskPopUp = data.payload;
        },
        setFilters: (state, data) => {
            if(data.payload) {
                state.filters = {...state.filters, ...data.payload};
            } else {
                state.filters = {...state.filters}
            }
        },
        resetFilters: (state) => {
          state.filters = {}
        },
        setTasksPreferences: (state, data) => {
            state.tasksPreferences = {...state.tasksPreferences, [data.payload.type]: data.payload.preference}
        },
        setSuccessFlag: (state, data) => {
            state.successFlag = data.payload
        },
        clearTasksOnBoxSwitch: (state) => {
            state.showCreateAnotherTaskPopUp = true
            state.filters = {}
            state.tasksPreferences = {}
            state.error = null
            state.successFlag = null
            state.counter = null
        }
    },
    extraReducers: builder => {
        builder.addCase(deleteTask.fulfilled, (state, action) => {
            state.error = null
            state.filters = {...state.filters}
        })
        builder.addCase(deleteTask.rejected, (state, action) => {
            state.error = action.payload?.error
        })
        builder.addCase(setSidebarCounter.fulfilled, (state, action) => {
            state.counter = action.payload
        })
        builder.addCase(setSidebarCounter.rejected, (state, action) => {
            state.error = action.payload?.error
        })
    }
})

export const {setShowCreateAnotherTaskPopUp, setFilters, resetFilters, setTasksPreferences, setSuccessFlag, clearTasksOnBoxSwitch} = tasksSlice.actions

export default tasksSlice.reducer

export const deleteTask = createAsyncThunk('deleteTask', async (params, {rejectWithValue, dispatch}) => {
    try {
        dispatch(showLoader())
        await fetchApi("tasks/deleteTask", 'POST', {taskId: params.taskId}, false, true)
        params.setConfirm(null)
        dispatch(hideLoader())
        params.toast.show(t('deleteTaskSuccess', {totalTasks: params.taskId.length}), { type: 'success', duration: 2000 });
    } catch (e) {
        dispatch(hideLoader())
        params.toast.show(t('deleteTask-failed'), { type: 'danger', duration: 2000 });
        return rejectWithValue(e);
    }
})

export const setSidebarCounter = createAsyncThunk('setSidebarCounter', async (params, {rejectedWithValue, dispatch}) => {
    try {
        return await fetchApi('tasks/getTasksTabCount', 'post', {extraFilters: params, fromDate: null, toDate: null, todayCount: true}, false, true);
    } catch (e) {
        return rejectWithValue(e);
    }

})