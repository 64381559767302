import React from 'react';
import { createRoot } from "react-dom/client";
import { store } from '../shared/redux/store';
import { Provider } from 'react-redux';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Entry from './Entry';

createRoot(document.getElementById('root')).render(
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID}>
        <Provider store={store}>
            <Entry />
        </Provider>
    </GoogleOAuthProvider>
);