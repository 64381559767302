import React from 'react';
import {Colors} from "../../styles/Colors";
import {Box, HStack, Text as NBText} from "native-base";
import {FontAwesomeIcon} from "@fortawesome/react-native-fontawesome";
import {GlobalConsts} from "../../styles/GlobalConsts";
import AppText from "./AppText";
import {GlobalStyleAttributes} from "../../styles/GlobalStyles";
import {t} from "../../services/i18n";

const ProTipBox = (props) => {
    const { text, children, header, ...rest } = props;

    return (
        <Box style={{backgroundColor: Colors.newDesign.lightMango, cursor: 'default', direction: 'inherit'}} padding={5} space={2} {...rest}>
            <HStack alignItems={'center'} space={'0.5rem'}>
                <FontAwesomeIcon icon={'fa-light fa-lightbulb-on'} size={GlobalConsts.iconSize.S}/>
                <AppText fontSize={GlobalStyleAttributes.fonts.standard16} boldFont>{header ? header : t('proTip')}</AppText>
            </HStack>
            <NBText style={{direction: 'inherit'}}>
                {text ? <AppText direction={'inherit'} fontSize={GlobalStyleAttributes.fonts.standard16}>{text}</AppText> : children}
            </NBText>
        </Box>
    );
};

export default ProTipBox;
