import {HStack} from "native-base";
import CategoryColors from "../../styles/CategoryColors";
import CustomButtonV2 from "./CustomButtonV2";
import {ColorPicker} from "antd";
import {Colors} from "../../styles/Colors";
import {t} from "i18next";

const CustomColorPicker = (props) => {
    const {colors, setCustomColor, customValue, onColorPick} = props
    return (
        <HStack justifyContent={'space-evenly'} flex={1} alignItems={'center'}>
            {Object.keys(colors).map(name => (
                <CustomButtonV2 onClick={() => onColorPick(name)}
                                key={`task-type-color-${name}`}
                                override={() => <ColorPicker defaultValue={colors[name]} disabled style={{width: '32px',minWidth: '32px', height: '32px', cursor: 'pointer', background: 'white', border: `1px solid ${customValue === name ? Colors.newDesign.mainBlue : Colors.newDesign.avatarBgColor}`}} rootClassName={'create-task-type-picker'}/>}
                />
            ))}
            <ColorPicker showText={() => <>{t('custom')}</>}
                         rootClassName={'create-task-type-picker'}
                         value={customValue}
                         style={{width: 'fit-content', borderColor: (customValue && customValue[0] === '#') ? Colors.newDesign.mainBlue : Colors.borderColor, maxHeight: '32px'}}
                         onChangeComplete={(color) => setCustomColor(color)}
                         disabledAlpha
            />
        </HStack>
    )
}

export default CustomColorPicker