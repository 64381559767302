import React from 'react';
import {HStack, Pressable, VStack} from "native-base";
import {Colors} from "../../styles/Colors";
import {FontAwesomeIcon} from "@fortawesome/react-native-fontawesome";
import {GlobalConsts} from "../../styles/GlobalConsts";
import AppText from "./AppText";
import {GlobalStyleAttributes} from "../../styles/GlobalStyles";
import CustomButtonV2 from "./CustomButtonV2";
import InfoTooltip from './InfoTooltip';
import { setOpenLockedPopup } from '../../redux/rootManagerSlice';
import { useDispatch } from 'react-redux';

const FoldableSettingSection = (props) => {
    const { isOpen, onToggle, header, children, infoTooltip, hasLock } = props;
    const dispatch = useDispatch();


    return (
        <VStack p={'1rem'} space={'1rem'} borderWidth={1} borderColor={Colors.borderColor} borderRadius={4} opacity={hasLock ? 0.5 : 1}>
            <CustomButtonV2 onClick={hasLock ? () => dispatch(setOpenLockedPopup(true)) : onToggle}
                            override={() => (
                                <HStack justifyContent={'space-between'} w={'100%'}>
                                    <HStack>
                                        <AppText fontSize={GlobalStyleAttributes.fonts.heading3} boldFont>{header}</AppText>
                                        {infoTooltip && <InfoTooltip title={infoTooltip}/>}
                                    </HStack>
                                    {hasLock ? <FontAwesomeIcon icon={'fa-light fa-lock-keyhole'} size={GlobalConsts.iconSize.XSS}/> :
                                    <FontAwesomeIcon icon={`fa-light fa-chevron-${isOpen ? 'up' : 'down'}`} size={GlobalConsts.iconSize.XSS}/>}
                                </HStack>)}
            />
            {children}
        </VStack>
    );
};

export default FoldableSettingSection;

