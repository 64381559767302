import React from 'react';
import {HStack, VStack} from "native-base";
import AppText from "../../../../../common/AppText";
import {GlobalStyleAttributes} from "../../../../../../styles/GlobalStyles";
import {Colors} from "../../../../../../styles/Colors";
import {t} from "../../../../../../services/i18n";
import SimpleInput from "../../../../../common/SimpleInput";
import {useSelector} from "react-redux";
import {optionalSteps} from "../StepOptions";
import {Radio} from "antd";
import InfoTooltip from "../../../../../common/InfoTooltip";

const LiveLinkSetting = (props) => {
    const { selectionValue, inputValue, onChangeSelection, onChangeText, isDisabled, initValue } = props;
    const selectionsTree = useSelector((state) => state.stepper.selectionsTree);

    return (
        <>
            <Radio.Group name={'onlineSetting-radio'} onChange={(e) => onChangeSelection(e.target.value)} value={selectionValue}>
                <VStack space={'0.5rem'} w={'100%'}>
                    <Radio value="in-person" size="sm" disabled={isDisabled}><AppText fontSize={GlobalStyleAttributes.fonts.heading3} color={Colors.newDesign.mainText}>{t(`personal-${selectionsTree[optionalSteps.EVENT_TYPE] ?? 'session'}-radio`)}</AppText></Radio>
                    <Radio value="online" size="sm" disabled={isDisabled}>
                        <VStack flex={1}>
                            <HStack space={'0.5rem'} alignItems={'center'}>
                                <AppText fontSize={GlobalStyleAttributes.fonts.heading3} color={Colors.newDesign.mainText}>{t(`online-${selectionsTree[optionalSteps.EVENT_TYPE] ?? 'session'}-radio`)}</AppText>
                                <InfoTooltip title={t('live-link-tooltip')}/>
                            </HStack>
                        </VStack>
                    </Radio>
                    {
                        selectionValue === 'online' &&
                        <SimpleInput value={inputValue}
                                     initValue={initValue}
                                     onChangeCallback={onChangeText}
                                     placeholder={t('online-link-placeholder')}
                                     disabled={isDisabled}
                        />
                    }
                </VStack>

            </Radio.Group>
        </>
    );
};

export default LiveLinkSetting;
