import { VStack, HStack } from 'native-base';
import React, { useEffect } from 'react';
import EStyleSheet from 'react-native-extended-stylesheet';
import { StaffMemberRoles as Roles } from '../../../Configs/DatabaseConsts';
import uuid from "react-native-uuid";
import { Colors } from '../../../styles/Colors';
import AppText from '../../common/AppText';
import { isRTL, t } from '../../../services/i18n';
import { useDispatch, useSelector } from 'react-redux';
import { hideLoader, showLoader } from '../../../redux/rootManagerSlice';
import { isMobile, sendDataEvent } from '../../../services/Helpers';
import { GlobalStyleAttributes } from '../../../styles/GlobalStyles';
import CustomCheckbox from "../../common/CustomCheckbox";

const StaffMemberRoleSelection = props => {
    const { setFieldValue, values, touched, errors } = props;
    const roles = useSelector(state => state.user.user.roles);
    const stepperProps = useSelector(state => state.stepper.props.initProps);
    const loading = useSelector(state => state.userFlow.loading);
    const hasBoxAdminRole = roles?.includes(Roles.BOXADMIN);
    const dispatch = useDispatch();

    const onRoleClick = (val, role) => {
        val ? setFieldValue('roles', [...values.roles, role]) : setFieldValue('roles', values.roles.filter(r => r !== role));
    }

    useEffect(() => {
        sendDataEvent('add new staff member pop up step 2')
    }, []);

    useEffect(() => {
        loading ? dispatch(showLoader()) : dispatch(hideLoader())
    }, [loading]);

    return (
        <VStack>
            {Object.values(Roles).map(role => <VStack key={uuid.v4()} mb={'1.25rem'}>
                <CustomCheckbox onChange={val => onRoleClick(val, role)}
                                style={{alignSelf: 'start'}}
                                text={
                                    <AppText mediumFont>{t(role)}</AppText>
                                }
                                isChecked={(values.roles || []).includes(role)}
                                disabled={(role === Roles.BOXADMIN && !hasBoxAdminRole) || (role === Roles.COACH && stepperProps?.roles.includes(role))}
                />
                <VStack maxWidth={'515px'} style={{[isRTL() ? 'marginRight' : 'marginLeft']: '25px'}}>
                    <AppText variant={isMobile && GlobalStyleAttributes.textVariants.heading4} width={isMobile && 'calc( 100vw - 65px)'}>
                        {t(`${role}RoleInfo`)}
                    </AppText>
                </VStack>
            </VStack>)}
            { touched.roles && errors.roles && <AppText color={Colors.error} fontSize={GlobalStyleAttributes.fonts.infoText} mediumFont>
                    {errors.roles}
                </AppText>}
        </VStack>
    );
};

export default StaffMemberRoleSelection;

const styles = EStyleSheet.create({
    //style object
});