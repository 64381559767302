import {createAsyncThunk, createSlice, current} from '@reduxjs/toolkit';
import {fetchApi} from "../services/HTTP";
import moment from "moment";
import {dbTimeFormat} from "../services/Helpers";

const accessControlSlice = createSlice({
    name: "accessControl",
    initialState: {
        isAccessControl: false,
        optionalPorts: [],
        settings: null,
        usersAccessControl: [],
        isManaged: false,
        isTypeTCP: false,
        gatesObj: {},
        locationsGates: []
    },
    reducers: {
        isAccessControl: (state) => {
            state.isAccessControl = true
        },
        setSettings: (state, action) => {
            state.settings = action.payload;
        },
        setOptionalPorts: (state, action) => {
            state.optionalPorts = action.payload;
        },
        setPairedPorts: (state, action) => {
            state.optionalPorts = action.payload;
        },
        clearAllPorts: (state, action) => {
            state.optionalPorts = [];
            if (current(state.settings)) {
                state.settings = {
                    ...state.settings,
                    gates: null
                };
            }
            state.gatesObj = {}
        },
        addUser: (state, action) => {
            const lastIndex = state.usersAccessControl.length - 1
            state.usersAccessControl[lastIndex] = {
                ...action.payload,
                enter_time: moment().format(dbTimeFormat).toString()
            }

        },
        updateUser: (state, action) => {
            const userIndex = state.usersAccessControl.findIndex(user => action.payload.id === user.id);
            if (userIndex >= 0) {
                state.usersAccessControl[userIndex] = {...action.payload}
            }
        },
        addLoader: (state) => {
            if (state.usersAccessControl.length >= 30) {
                state.usersAccessControl.shift()
            }
            state.usersAccessControl = [...state.usersAccessControl, {}]
        },
        logoutAC: (state) => {
            state.isAccessControl = false
            state.optionalPorts = []
            state.settings = null
            state.usersAccessControl = []
            state.isManaged = false
            state.isTypeTCP = false
            state.gatesObj = {}
            state.locationsGates = []
        },
        clearAllUsers: (state) => {
            state.usersAccessControl = []
        },
        clearLoader: (state) => {
            state.usersAccessControl = state.usersAccessControl.filter(user => Object.keys(user).length !== 0);
        },
        setLocation: (state, action) => {
            state.settings = {...state.settings, location: action.payload}
        },
        addScheduleUser: (state, action) => {
            const index = state.usersAccessControl.findIndex(user => user.id === action.payload.id)
            state.usersAccessControl[index] = {
                ...state.usersAccessControl[index],
                schedule_user: action.payload.schedule_user
            }
        },
        setIsManaged: (state, action) => {
            state.isManaged = action.payload
        },
        setIsTypeTCP: (state, action) => {
            state.isTypeTCP = action.payload
        },
        setGatesObj: (state, action) => {
            state.gatesObj = action.payload
        },
        addGateToGatesObj: (state, action) => {
            state.gatesObj[action.payload.key] = action.payload.value
        },
        addErrorCard: (state, action) => {
            const usersAccessControl = state.usersAccessControl.filter(user => Object.keys(user).length !== 0);
            state.usersAccessControl = [...usersAccessControl, {
                ...action.payload,
                enter_time: moment().format(dbTimeFormat).toString()
            }]
        },
        setLocationsGates: (state, action) => {
            state.locationsGates = action.payload
        },
    },
    extraReducers: builder => {
        builder.addCase(addUserCardByMembership.pending, (state) => {
            if (state.usersAccessControl.length >= 30) {
                state.usersAccessControl.shift()
            }
            state.usersAccessControl = [...state.usersAccessControl, {}]
        })
        builder.addCase(addUserCardByMembership.fulfilled, (state, action) => {
            const lastIndex = state.usersAccessControl.length - 1
            state.usersAccessControl[lastIndex] = {
                ...action.payload,
                enter_time: moment().format(dbTimeFormat).toString()
            }
        })
        builder.addCase(addUserCardByMembership.rejected, (state, action) => {
            state.usersAccessControl.shift()
            state.error = action.payload?.error
        })
    }
})
export const addUserCardByMembership = createAsyncThunk('addUserCardByMembership', async (params, {rejectWithValue}) => {
    try {
        return await fetchApi('accessControlByMembership', 'POST', params, false, true)
    } catch (e) {
        return rejectWithValue(e);
    }
})

export const {
    isAccessControl,
    setOptionalPorts,
    clearAllPorts,
    setSettings,
    setPairedPorts,
    addUser,
    addLoader,
    logoutAC,
    clearAllUsers,
    clearLoader,
    setLocation,
    addScheduleUser,
    updateUser,
    setIsManaged,
    setIsTypeTCP,
    setGatesObj,
    addGateToGatesObj,
    addErrorCard,
    setLocationsGates
} = accessControlSlice.actions
export default accessControlSlice.reducer;