import React from 'react';
import {Text,Platform} from 'react-native';
import i18n from 'i18next';

const UnicodeHelper = props => {
    // u200F RTL
    // u200e LTR
    if(Platform.OS.toLowerCase() === 'android'){
        const unicode = i18n.isRTL ?  '\u200F' : '\u200e';
        return (
            <Text>{unicode}</Text>
        )
    }else{
        return null;
    }

};

export default UnicodeHelper;

