import React, {useEffect, useRef, useState} from 'react';
import {Drawer} from "antd";
import {isRTL} from "../../services/i18n";
import {useDispatch} from "react-redux";
import {closeModal, EVENT_MANAGEMENT_MODAL} from "../../redux/modalManagerSlice";
import {isMobile} from "../../services/Helpers";
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { GlobalConsts } from '../../styles/GlobalConsts';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Colors } from '../../styles/Colors';

const CustomDrawer = (props) => {
    const { open, header, width, footer, onClose, children, placement, style, footerStyle, headerStyle, bodyStyle, closeable, hasPreviousState, containerDOM, closeElement, ...rest} = props;
    const [selfOpen, setSelfOpen] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {
        if(!selfOpen) { //This is for making the transition work on Drawer close
            setTimeout(() => onClose ? onClose() : console.warn('CustomDrawer - Was trying to run onClose() but its undefined'), 500);
        }
    },[selfOpen])

    useEffect(() => {
        if(open === false) {
            setSelfOpen(false)
        } else if(open === true) {
            setSelfOpen(true)
        }
    },[open])

    return (
        <Drawer
            getContainer={containerDOM || '#native-base-main-view'}
            title={header ?? null}
            placement={placement ?? (isRTL() ? 'left' : 'right')}
            headerStyle={headerStyle || {alignItems: 'flex-start', padding: isMobile ? 5 : '16px 20px 16px 20px', borderBottom: 0}}
            width={width ?? '100%'}
            open={selfOpen}
            closable={!closeable}
            closeIcon={closeElement || <FontAwesomeIcon size={GlobalConsts.iconSize.SS} color={Colors.newDesign.darkGrey} icon={light('xmark')}/>}
            onClose={() => hasPreviousState ? onClose() : setSelfOpen(false)}
            footer={footer ?? null}
            footerStyle={footerStyle || {padding: isMobile ? '16px' : '24px', display: 'flex', justifyContent: isMobile ? 'space-around' : 'flex-end'}}
            bodyStyle={bodyStyle || {padding: '16px', display: 'flex'}}
            contentWrapperStyle={{height: 'auto'}}
            style={style ?? {}}
            {...rest}
        >
            {children}
        </Drawer>
    );
};

export default CustomDrawer;