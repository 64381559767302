import React, { useEffect, useState } from 'react';
import { Dimensions } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';
import Loader from '../shared/components/common/Loader';
import loadable from '@loadable/component';
import { isMobile } from '../shared/services/Helpers';

const App = loadable(
    () => import(/* webpackChunkName: App */ './App'),
    {
        fallback: <Loader />,
        ssr: true,
        name: 'App',
    }
);

const Entry = () => {
    const [isEStyleSheetLoaded, setIsEStyleSheetLoaded] = useState(false);
    const [isAppLoaded, setIsAppLoaded] = useState(false);

    useEffect(() => {
        let width = Dimensions.get('window').width;
        EStyleSheet.build({
            $rem: width > 400 ? 16 : 17,
            $headerHeight: isMobile ? 48 : 45,
            $desktopSidebar: 236,
            $sideBarWidth: isMobile ? 0 : 230,
            $navigatorHeight: isMobile ? 60 : 0,
            $inputHeight: isMobile ? 50 : 40,
            $inputHeightContainer: isMobile ? 100 : 90,
            $signupInnerScreen: isMobile ? 350 : 405,
            $signupHeaderScreen: isMobile ? 60 : 90,
            $signupFooterScreen: 110,
            $signupFooterPlusHeaderScreen: 260,
            $bookingServicesDesktopRightSectionWidth: 200,
            $membershipSelectionListHeight: 290,
            $desktopSideBarTopSection: 180,
            $desktopSideBarBottomLogoHeight: 25,
            $desktopSideBarBottomUpgradeHeight: 70,
            $desktopSideBarBottomTrialHeight: 65,
            $filtersDesktopWidth: 370,
            $filtersOffsetScroll: 250,
            $defaultButtonHeight: 38,
            $defaultTextAreaHeight: 72,
            $mobileFiltersMaxHeight: 255,
            $smallInputHeight: 30,
            $homepageSubheaderCheckin: 60,
            $manageSubheaderCheckIn: 270,
            $headerCheckin: isMobile ? 94 : 60,
            $checkinFooterLogo: 40,
        });
        setIsEStyleSheetLoaded(true);
    }, []);
    const handleAppLoad = () => {
        setIsAppLoaded(true);
    };

    if (!isEStyleSheetLoaded) {
        return null;
    }

    if (!isAppLoaded) {
        return <App onLoad={handleAppLoad} />;
    }

    return <App />;
};

export default Entry;
