import {Radio} from "antd";
import {HStack, VStack} from "native-base";
import AppText from "../../../../../common/AppText";
import {GlobalStyleAttributes} from "../../../../../../styles/GlobalStyles";
import {Colors} from "../../../../../../styles/Colors";
import {t} from "../../../../../../services/i18n";
import React, {useEffect, useMemo, useState} from "react";
import CustomButtonV2 from "../../../../../common/CustomButtonV2";
import {useSelector} from "react-redux";

const HugPaymentsOptions = (props) => {
    const {errors, paymentOptions, setFieldValue, values, startDate} = props
    const selectedLocation = useSelector(state => state.userSettings.scheduleLocation)
    const [paymentOption, setSitePaymentOption] = useState(values.cart?.length ? values.cart[0].type : null);
    const currencySymbol = useMemo(() => selectedLocation ? selectedLocation.currency_symbol : '',[selectedLocation])

    useEffect(() => {
        console.log(values, errors)
    }, [values, errors]);

    const onClick = (option) => {
        const paymentMembership = paymentOptions.find(membershipType => membershipType.type === option)
        setSitePaymentOption(option)
        setFieldValue('cart', [startDate ? {...paymentMembership, start: startDate} : paymentMembership])
    }

    return (
        <Radio.Group name={'payment-option-radio'} value={paymentOption}>
            <VStack space={'1rem'} w={'100%'}>
                <CustomButtonV2 onClick={() => onClick('recurring')}
                                iconProps={{iconColor: Colors.borderColor, iconHoverColor: Colors.newDesign.mainBlue}}
                                override={({color}) => (
                                    <VStack w={'100%'}>
                                        <HStack space={'0.5rem'} alignItems={'start'} p={'1rem'} borderWidth={1} borderColor={paymentOption === 'recurring' ? Colors.newDesign.mainBlue : color} borderRadius={4}>
                                            <Radio value={'recurring'} size="sm" onClick={(e) => setSitePaymentOption('recurring')}/>
                                            <VStack flex={1}>
                                                <AppText fontSize={GlobalStyleAttributes.fonts.heading3} boldFont>{t('recurringHug')}</AppText>
                                                <AppText fontSize={GlobalStyleAttributes.fonts.heading3}>{t(`recurring-explain`, {price: `${paymentOptions.find(membershipType => membershipType.type === 'recurring').price.toLocaleString()}${currencySymbol}`})}</AppText>
                                            </VStack>
                                        </HStack>
                                    </VStack>)}
                />
                <CustomButtonV2 onClick={() => onClick('regular')}
                                iconProps={{iconColor: Colors.borderColor, iconHoverColor: Colors.newDesign.mainBlue}}
                                override={({color}) => (
                                    <VStack w={'100%'}>
                                        <HStack space={'0.5rem'} alignItems={'start'} p={'1rem'} borderWidth={1} borderColor={paymentOption === 'regular' ? Colors.newDesign.mainBlue : color} borderRadius={4}>
                                            <Radio value={'regular'} size="sm" onClick={(e) => setSitePaymentOption('regular')}/>
                                            <VStack flex={1}>
                                                <AppText fontSize={GlobalStyleAttributes.fonts.heading3} boldFont>{t('regularHug')}</AppText>
                                                <AppText fontSize={GlobalStyleAttributes.fonts.heading3}>{t(`regular-explain`, {price: `${paymentOptions.find(membershipType => membershipType.type === 'regular')?.price.toLocaleString()}${currencySymbol}`})}</AppText>
                                            </VStack>
                                        </HStack>
                                    </VStack>)}
                />
                {errors.cart && <AppText color={Colors.error} fontSize={GlobalStyleAttributes.fonts.infoText} width='max-content' mediumFont>
                    {t('hug-payment-option-required')}
                </AppText>}

            </VStack>
        </Radio.Group>

    )
}

export default HugPaymentsOptions