import React, {useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {HStack, VStack} from "native-base";
import AppText from "../../../../../common/AppText";
import {GlobalStyleAttributes} from "../../../../../../styles/GlobalStyles";
import {t} from "../../../../../../services/i18n";
import AdvanceSelect from "../../../../../common/AdvanceSelect";
import {Colors} from "../../../../../../styles/Colors";
import SimpleInput from "../../../../../common/SimpleInput";
import CustomSwitch from "../../../../../common/CustomSwitch";
import moment from "moment";
import {dbDateFormat} from "../../../../../../services/Helpers";
import MultiDayConfiguration from "./MultiDayConfiguration";
import {updateSummary} from "../../../../../../redux/stepperSlice";
import {APPOINTMENT} from "../../../../../../services/ConstHelper";
import InfoTooltip from "../../../../../common/InfoTooltip";

const SpaceConfiguration = (props) => {
    const { values, errors, setFieldValue, touched, eventDateTime } = props;
    const box = useSelector((state) => state.box.box);
    const selectedLocation = useSelector((state) => state.userSettings.scheduleLocation);
    const dispatch = useDispatch()
    const locations = useMemo(() => box ? box.locations_box.reduce((acc, location) => [...acc, {...location, label: location.location, value: location.id}],[]) : null, [box])
    const defaultAvailability = useMemo(() => ({
        date: eventDateTime?.date ?? moment().format(dbDateFormat),
        repeat: true,
        slot_length: 15,
        break_length: 0,
        block_registration_time: 0,
        multiDayConfig: [{day: moment(eventDateTime?.date).day(), setting: [{start_time: '09:00:00', end_time: '17:00:00', limitations: []}]}]}), []);

    useEffect(() => {
        if(box) {
            if(box.locations_box.length === 1 && 'locations_box_id' in values) {
                setFieldValue('locations_box_id', box.locations_box[0].id)
            }
        }
    }, [box]);

    useEffect(() => {
        if(selectedLocation && Object.keys(values).length && !values.locations_box_id) {
            setFieldValue('locations_box_id', selectedLocation.id)
        }
    }, [selectedLocation, values.locations_box_id]);

    const toggleAvailability = (isOpen) => {
        setFieldValue('availabilityInfo', isOpen ? defaultAvailability : null)
        if(!isOpen) dispatch(updateSummary(t('new-space-no-availability-summary')))
    }

    return (
        <VStack space={'1rem'}>
            <VStack space={'0.2rem'} flex={1}>
                <HStack justifyContent={'space-between'} alignItems={'center'}>
                    <AppText fontSize={GlobalStyleAttributes.fonts.infoText} mediumFont>{t('name')}*</AppText>
                    <AppText fontSize={GlobalStyleAttributes.fonts.infoText} color={values.name?.length > 20 ? Colors.error : Colors.newDesign.mediumGrey}>{values.name?.length}/20</AppText>
                </HStack>
                <SimpleInput value={values.name} onChangeCallback={val => setFieldValue('name', val)} status={touched.name && errors.name ? 'error' : null}/>
                {touched.name && errors.name && <AppText fontSize={GlobalStyleAttributes.fonts.infoText} color={Colors.error}>{errors.name}</AppText>}
            </VStack>
            { locations.length > 1 &&
            <VStack space={'0.2rem'}>
                <AppText fontSize={GlobalStyleAttributes.fonts.infoText} mediumFont>{t('location')}</AppText>
                <AdvanceSelect options={locations} initValue={values.locations_box_id} onChange={(val) => setFieldValue('locations_box_id', val?.id)} isError={!!errors.locations_box_id}/>
                <AppText fontSize={GlobalStyleAttributes.fonts.infoText} color={Colors.error}>{errors.locations_box_id}</AppText>
            </VStack>
            }
            <HStack justifyContent={'space-between'}>
                <HStack space={'1rem'}>
                    <AppText fontSize={GlobalStyleAttributes.fonts.infoText} mediumFont>{t('define-space-availability')}</AppText>
                    <InfoTooltip title={t('space-availability-explain')}/>
                </HStack>
                <CustomSwitch isChecked={values.availabilityInfo !== null} onToggle={toggleAvailability}/>
            </HStack>
            {values.availabilityInfo &&
            <MultiDayConfiguration showLocationSelect={false}
                                   showCoachSelect={false}
                                   servicesTypeOverride={APPOINTMENT}
                                   spaceName={values.name}
                                   values={values.availabilityInfo}
                                   errors={errors.availabilityInfo ?? {}}
                                   touched={touched.availabilityInfo ?? {}}
                                   setFieldValue={(name, val) => setFieldValue(`availabilityInfo.${name}`, val)}
            />}
        </VStack>
    );
};

export default SpaceConfiguration;
