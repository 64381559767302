import {createSlice} from "@reduxjs/toolkit";
import { PAGE_TYPE, TOAST_TYPE } from "../services/ConstHelper";
import { saveLead, saveStaffMember } from "./UserFlowSlice";
import {saveTaskType} from "./boxSlice";

const stepperSlice = createSlice({
    name: "stepper",
    initialState: {
        name: '',
        steps: [],
        currStep: null,
        selectionsTree: {},
        tempSelection: null,
        summary: null,
        editType: null,
        stepperSuccess: null,
        error: '',
        values: null,
        props: {},
        previousState: null,
        hideFooter: false,
        flags: {},
        mockSwitch: false
    },
    reducers: {
        updateStepper: (state, data) => {
            state.name = data.payload.name ?? state.name
            state.steps = data.payload.steps ?? state.steps
            state.currStep = data.payload.currStep ?? state.currStep
            state.selectionsTree = data.payload.selectionsTree ?? state.selectionsTree
            state.editType = data.payload.editType ?? state.editType
            state.values = data.payload.values ?? state.values
            state.props = data.payload.props ?? state.props
            state.tempSelection = data.payload.tempSelection ?? state.tempSelection ?? null
            state.flags = data.payload.flags ?? state.flags ?? {}
        },
        updateCurrStep: (state, data) => {
            if(state.currStep !== data.payload){
                state.currStep = data.payload;
            }
        },
        addToSelectionsTree: (state, data) => {
            state.selectionsTree = {...state.selectionsTree, ...data.payload}
            state.tempSelection = null
        },
        updateTempSelection: (state, data) => {
            state.tempSelection = data.payload
        },
        cleanStepper: (state) => {
            state.name = ''
            state.steps = []
            state.currStep = null
            state.selectionsTree = {}
            state.tempSelection = null
            state.editType = null
            state.stepperSuccess = null
            state.error = null
            state.values = null
            state.hideFooter = false
            state.flags = {}
        },
        pushStepperStack: (state, data) => {
            const shallowCopy = {...state};
            state.name = data.payload.name ?? ''
            state.steps = data.payload.steps ?? []
            state.currStep = data.payload.currStep ?? null
            state.selectionsTree = data.payload.selectionsTree ?? {}
            state.editType = data.payload.editType ?? null
            state.tempSelection = null
            state.stepperSuccess = data.payload.stepperSuccess ?? null
            state.props = data.payload.props ?? {}
            state.values = null
            state.previousState = shallowCopy
            state.hideFooter = false
            state.flags = {}
            state.mockSwitch = true
        },
        popStepperStack: (state) => {
            const previous = state.previousState;
            state.name = previous.name
            state.steps = previous.steps
            state.currStep = previous.currStep
            state.selectionsTree = previous.selectionsTree
            state.editType = previous.editType
            state.stepperSuccess = previous.stepperSuccess
            state.props = previous.props
            state.previousState = previous.previousState
            state.hideFooter = previous.hideFooter ?? false
            state.flags = previous.flags ?? {}
            state.mockSwitch = true
        },
        updateSummary: (state, data) => {
            state.summary = data.payload
        },
        toggleStepperSuccess: (state, data) => {
            state.stepperSuccess = data.payload
        },
        setError: (state, data) => {
            state.error = data.payload
        },
        clearError: (state) => {
            state.error = null
        },
        setHideFooter: (state, data) => {
            state.hideFooter = data.payload
        },
        updateFlags: (state, data) => {
            for (key in data.payload) {
                state.flags[key] = data.payload[key]
            }
        },
        resetMockSwitch: (state, data) => {
            state.mockSwitch = false
        },
    },
    extraReducers: builder => {
        builder.addCase(saveStaffMember.fulfilled, (state, action) => {
            state.error = ''
            state.stepperSuccess = {
                type: (state.previousState || action.payload.showToast) ? TOAST_TYPE : PAGE_TYPE,
                message: 'added-successfully',
                navigate: !state.previousState && action.payload.showToast ? `/user-profile/${action.payload.user?.user_fk}` : null
            }
            if (state.values && state.props.target) {
                if (Array.isArray(state.values[state.props.target.name])) {
                    const value = action.payload.user[state.props.target.value];
                    state.values[state.props.target.name] = [...(state.values[state.props.target.name]), {id: value, value}]
                }
                else state.values[state.props.target.name] = action.payload.user[state.props.target.value];
            }
        })
        builder.addCase(saveStaffMember.rejected, (state, action) => {
            state.stepperSuccess = null
            state.error = action.payload?.error
        })

        builder.addCase(saveLead.fulfilled, (state, action) => {
            state.error = ''
            state.stepperSuccess = {
                type: state.previousState ? TOAST_TYPE : action.payload.messageType,
                message: 'lead-added-successfully',
                navigate: !state.previousState && action.payload.messageType === TOAST_TYPE ? `/user-profile/${action.payload.user?.user_fk}` : null
            }
            if (state.values && state.props.target) {
                state.values[state.props.target.name] = action.payload.user[state.props.target.value];
            }
        })
        builder.addCase(saveLead.rejected, (state, action) => {
            state.stepperSuccess = null
            state.error = action.payload?.error
        })
        builder.addCase(saveTaskType.fulfilled, (state, action) => {
            state.error = ''
            state.stepperSuccess = {
                type: TOAST_TYPE,
                message: `task-type-${action.payload.id ? 'edited' : 'added'}-successfully`,
                navigate: null
            }
        })
        builder.addCase(saveTaskType.rejected, (state, action) => {
            state.stepperSuccess = null
            state.error = action.payload?.error
        })

      }
})

export const { updateStepper, updateCurrStep, addToSelectionsTree, updateTempSelection, cleanStepper,
    updateSummary, toggleStepperSuccess, setError, clearError, pushStepperStack, popStepperStack, setHideFooter,
    updateFlags, resetMockSwitch } = stepperSlice.actions
export default stepperSlice.reducer;