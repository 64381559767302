import {Colors} from "./Colors";

export const GlobalConsts = {
    menu: {
        placement: { // Placement is an attribute for Native-Base Menu and Popover components
            TOP: 'top',
            TOP_LEFT: 'top left',
            RIGHT_TOP: 'right top',
            TOP_RIGHT: 'top right',
            RIGHT: 'right',
            RIGHT_BOTTOM: 'right bottom',
            BOTTOM_LEFT: 'bottom left',
            BOTTOM: 'bottom',
            BOTTOM_RIGHT: 'bottom right',
            LEFT_TOP: 'left top',
            LEFT: 'left',
            LEFT_BOTTOM: 'left bottom'
        }
    },
    iconSize: {
        XXXS: 8,
        XXS10: 10,
        XXS: 12,
        XXSS: 13,
        XSS: 15,
        XS: 16,
        SS:18,
        S: 20,
        M: 24,
        L: 30,
        XL: 40,
        XXL: 50,
        XXXL: 60,
        SPECIAL_SIZE_75: 75,
        SPECIAL_SIZE_85: 85,
        SPECIAL_SIZE_100: 100,
        SPECIAL_SIZE_150: 150,
        SPECIAL_SIZE_200: 200
    },
    imageSize: {
        XS: 'xs',
        S: 'sm',
        M: 'md',
        L: 'lg',
        XL: 'xl',
        XXL: '2xl'
    },
    iconPrefix: {
        SOLID: 'fa-solid',
        LIGHT: 'fa-light',
        BRANDS: 'fa-brands',
        REGULAR: 'fa-regular',
    }
};