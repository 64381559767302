import { Box } from 'native-base';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleSendNotifications } from '../../../../../../redux/ScheduleSlice';
import {t} from "../../../../../../services/i18n";
import CustomCheckbox from '../../../../../common/CustomCheckbox';

const UpdateParticipants = (props) => {
    const { values, setFieldValue, isSingle } = props;
    const dispatch = useDispatch();
    const sendNotifications = useSelector(state => state.schedule.confirmationRes?.sendNotifications);

    useEffect(() => {
        // on init - always true
        dispatch(toggleSendNotifications(true))
    }, []);

    const onChange = (val) => {
        if (setFieldValue) {
            setFieldValue('send_notifications', val)
        } else {
            dispatch(toggleSendNotifications(val))
        }
    }

    return (
        <Box mt={'0.7rem'}>
            <CustomCheckbox text={t('cancel-send-notifications' + (isSingle ? '-signle' : ''))} isChecked={values?.send_notifications ?? sendNotifications} onChange={onChange} />
        </Box>
    );
};

export default UpdateParticipants;
