import React, {useEffect, useState} from 'react';
import {Input, InputNumber} from "antd";
const { TextArea } = Input;

const SimpleInput = (props) => {
    const { inputType, onChangeCallback, value, styleOverride, direction, numericOnly, width, formatAddon, placeholder, ...rest } = props;
    const styles = styleOverride ? {width: width ?? '100%', ...styleOverride} : {width: width ?? '100%'}
    const [val, setVal] = useState('');

    useEffect(() => {
        //this is a workaround for when we need a flag to update the default value (no dependency is not good and [value] dependency is keeping the bug)
        setVal(value)
    }, [value]);

    useEffect(() => {
        setVal(value)
    }, []);

    const onChange = (value) => {
        const val = inputType === 'float' || numericOnly ? value : value.target.value;
        setVal(val)
        if(onChangeCallback) onChangeCallback(val)
    }

    if(inputType === 'password') return <Input.Password value={val} onChange={onChange} {...rest} />

    if(inputType === 'float' || numericOnly) return <InputNumber value={val}
                                                                 onChange={onChange}
                                                                 style={styles}
                                                                 formatter={(value) => `${formatAddon ?? ''} ${value.replace(numericOnly ? /\D/g : /[^0-9.]/g, '')}`}
                                                                 parser={(value) => value.replace(numericOnly ? /\D/g : /[^0-9.]/g, '')}
                                                                 min={0}
                                                                 type={numericOnly ? 'tel' : 'text'}
                                                                 {...rest}/>

    if(inputType === 'multiline') {
        return <TextArea onChange={onChange}
                         style={styles}
                         value={val}
                         placeholder={placeholder}
                         onFocus={(e) => e.target.placeholder = ''}
                         onBlur={(e) => placeholder ? e.target.placeholder = placeholder : null}
                         {...rest}/>
    }

    return (
        <Input onChange={onChange}
               style={styles}
               value={val}
               placeholder={placeholder}
               onFocus={(e) => e.target.placeholder = ''}
               onBlur={(e) => placeholder ? e.target.placeholder = placeholder : null}
               {...rest}
        />
    );
};

export default SimpleInput;
