import { Box, HStack, Button } from 'native-base';
import React, { useEffect, useMemo, useState } from 'react';
import { ActivityIndicator } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';
import { useSelector } from 'react-redux';
import { MembershipTypes } from '../../../Configs/DatabaseConsts';
import { fetchApi } from '../../../services/HTTP';
import { t, langCode } from '../../../services/i18n';
import { Colors } from '../../../styles/Colors';
import { GlobalStyleAttributes } from '../../../styles/GlobalStyles';
import AdvanceSelect from '../../common/AdvanceSelect';
import AppText from '../../common/AppText';
import FormInput from '../../common/FormInput';
import SimpleInput from '../../common/SimpleInput';
import InfoTooltip from '../../common/InfoTooltip';
import CustomCheckbox from '../../common/CustomCheckbox';
import { Space } from 'antd';
import { getPenaltiesTags, penaltiesDefaultMessage } from '../../../services/Helpers';

const PenaltyExtraProperties = props => {
    const { values, setTouched, setFieldValue, errors, touched, target } = props;
    const box = useSelector(state => state.box?.box)
    const [membershipTypes, setMembershipTypes] = useState(null);
    const planOptions = useMemo(() => membershipTypes?.filter(mt => mt?.type === MembershipTypes.PLAN), [membershipTypes])
    const sessionOptions = useMemo(() => membershipTypes?.filter(mt => mt?.type === MembershipTypes.SESSION), [membershipTypes])
    const isIL = box?.locations_box[0]?.country_code === 'IL';
    const charCount = values.message_content?.length || 0;
    const maxCharCount = isIL ? 133 : 70;
    const tags = useMemo(() => {
        return getPenaltiesTags(values.type)
    }, [values.type]);

    const getMembershipTypes = async () => {
        const result = await fetchApi('getMembershipTypesOfBox?showInactive=true', 'get', null, false, true)
        const memberships = result.filter(r => (!r.penalties && r.active) || r.penalties?.penalties_id === target?.id).map(r => ({ ...r, label: r.name, value: r.id }))
        setMembershipTypes(memberships)
        if (target?.membership_types) {
            setFieldValue('membership_types', target.membership_types.map(mt => memberships.find(r => r.id === mt.membership_types_id)))
        }
    }

    useEffect(() => {
        getMembershipTypes()
        setTouched({...touched, membership_types: undefined})
    }, []);

    useEffect(() => {
        if ((!target || !target?.message_template) && values.type) {
            let message = penaltiesDefaultMessage[values.type][langCode()] || '';
            tags.forEach(tag => {
                message = message.replaceAll(`{{#${tag.id}#}}`, `[${tag.label}]`)
            });
            if (values.type === 'block_app_cancel') {
                message += `\n${t('note-events-during-block')}`
            }
            message += `\n[${t('clubName')}]`
            setFieldValue('message_content', message)
        }
    }, [values.type])

    const onMembershipTypeChange = (value, type) => {
        setFieldValue('membership_types', [...values.membership_types.filter(mt => mt.type !== type), ...value])
    }

    const onTagClick = (label) => {
        if (`${values.message_content || ''} [${label}]`.length <= 175) {
            setFieldValue('message_content', `${values.message_content || ''} [${label}]`)
        }
        document?.getElementById('text-area-message')?.focus()
    }

    if (!membershipTypes) {
        return <ActivityIndicator />
    }

    return (
        <>
            <FormInput infoTooltip={t('membership-without-end-tooltip')} collapsable={!!target} error={(touched.membership_types && errors.membership_types) || null} description={`${t('promoCodeSelectLabel-plan')}: ${values.membership_types?.filter(mt => mt?.type == 'plan').length}`} containerProps={{ mb: '1.5rem' }} label={t('promoCodeSelectLabel-plan')} width={'100%'} >
                <AdvanceSelect placeholder={t('select-plans')} isMulti options={planOptions} initValue={values.membership_types} onChange={value => onMembershipTypeChange(value, MembershipTypes.PLAN)} isError={touched.membership_types && errors.membership_types}/>
            </FormInput>
            <FormInput collapsable={!!target} containerProps={{ mb: '1.5rem' }} description={`${t('promoCodeSelectLabel-session')}: ${values.membership_types?.filter(mt => mt?.type == 'session').length}`}
                        label={t('promoCodeSelectLabel-session')} width={'100%'} error={(touched.membership_types && errors.membership_types) || null}>
                <AdvanceSelect placeholder={t('select-session-packs')} isMulti options={sessionOptions} initValue={values.membership_types} onChange={value => onMembershipTypeChange(value, MembershipTypes.SESSION)} isError={touched.membership_types && errors.membership_types}/>
            </FormInput>
            {values.type !== 'reduction' && <FormInput error={(touched.free_space && errors.free_space) || null} description={(values.allow_free_space && values.free_space > 0) ? `${t('the-user-can-book-on-block')} ${values.free_space} ${t('hours-before-session')}` :''} collapsable={!!target} width={'100%'} label={t('other-properties')} containerProps={{ mb: '1.5rem' }}>
                 <CustomCheckbox isChecked={values.allow_free_space} onChange={(val) => setFieldValue('allow_free_space', val)} 
                 text={<Space>
                         <AppText>{t('the-user-can-book-on-block')}</AppText>
                         <div onClick={(e) => e.preventDefault() | e.stopPropagation()}>
                            <SimpleInput numericOnly min={0} width={'4rem'} value={values.free_space}
                                        onChangeCallback={(val) => setFieldValue('free_space', val)}
                                        status={touched.free_space && errors.free_space ? 'error' : null}
                            />
                         </div>
                         <AppText>{t('hours-before-session')}</AppText>
                     <InfoTooltip title={t('block-free-space-tooltip')}/>
                 </Space>}/>
            </FormInput>}
            <FormInput  width={'100%'} collapsable={!!target} description={[values.send_push && t('send-push'), values.send_sms && t('send-sms'),values.send_email && t('send-email')].filter(t => t).join(', ')} label={t('absences-message')} containerProps={{ mb: '1.5rem' }}>
                <CustomCheckbox isChecked={values.send_push} onChange={(val) => setFieldValue('send_push', !!val)} text={t('send-push')}/>
                <CustomCheckbox isChecked={values.send_sms} onChange={(val) => setFieldValue('send_sms', !!val)} text={t('send-sms')}/>
                <CustomCheckbox isChecked={values.send_email} onChange={(val) => setFieldValue('send_email', !!val)} text={
                    <Space direction={'horizontal'}>
                    {t('send-email')}
                    <InfoTooltip title={t('penalties-email-tooltip')}/>
                    </Space>
                }/>
            </FormInput>
            <FormInput collapsable={!!target} label={t('messageContent')} width={'100%'} containerProps={{ mb: '1.5rem' }}
                error={(touched.message_content && errors.message_content) ? errors.message_content : null}
                topEndElement={<AppText variant={GlobalStyleAttributes.textVariants.heading4} color={Colors.newDesign.darkGrey}>{`${charCount % maxCharCount}/${maxCharCount}${` | ${parseInt(charCount / maxCharCount) + 1} ${t('messages')}`}`}</AppText>}>
                <Box>
                    <HStack borderColor={Colors.borderColor} flexWrap={'wrap'} borderWidth={1} p={'0.3rem'} space={'0.3rem'} borderRadius={4} top={'1px'} alignItems={'center'}>
                        <AppText variant={GlobalStyleAttributes.textVariants.heading4}>{`${t('tags')}:`}</AppText>
                        {tags.map(tag => (<Button
                            bgColor={Colors.newDesign.menuBar}
                            borderColor={Colors.newDesign.menuBar}
                            onPress={() => onTagClick(tag.label)}
                            key={tag.id}
                            width={'auto'}
                            mb={'0.3rem'}
                            size={'md'} h={8}>
                            <AppText variant={GlobalStyleAttributes.textVariants.heading4}>{tag.label}</AppText>
                        </Button>))}
                    </HStack>
                    <SimpleInput inputType={'multiline'}
                                 rows={6}
                                 id={'text-area-message'}
                                 maxLength={175}
                                 placeholder={t('add-message-here')}
                                 value={values.message_content}
                                 onChangeCallback={(val) => setFieldValue('message_content', val)}
                                 status={touched.message_content && errors.message_content ? 'error' : null}
                    />
                </Box>
            </FormInput>
        </>
    );
};

export default PenaltyExtraProperties;

const styles = EStyleSheet.create({
    //style object
});