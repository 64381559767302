import React, {useState} from 'react';
import {Colors} from "../styles/Colors";

const useIconHover = (props) => {
    const { color, hoverColor } = props;
    const [isHovered, setIsHovered] = useState(false)
    const [hoverColorState, setHoverColorState] = useState(color ?? Colors.newDesign.mainText);

    const handleHover = () => {
        setIsHovered(true)
        setHoverColorState(hoverColor ?? Colors.newDesign.mainBlue);
    }

    const handleHoverOut = () => {
        setIsHovered(false)
        setHoverColorState(color ?? Colors.newDesign.mainText);
    }

    return [hoverColorState, handleHover, handleHoverOut, isHovered];
};

export default useIconHover;