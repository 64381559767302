import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchApi } from "../services/HTTP";
import { t } from '../services/i18n';


export const getLanguages = createAsyncThunk('getLanguages', async (_, { rejectWithValue }) => {
  try {
    return await fetchApi('languages', 'get', {}, true, true);
  } catch (e) {
    return rejectWithValue(e)
  }
})

const rootManagerSlice = createSlice({
  name: "rootManager",
  initialState: {
    isLoading: false,
    showBackdrop: false,
    reactState: null,
    angularState: null,
    closeReactIframePopup: false,
    quickHelpSource: null,
    languageOptions: [],
    angularPath: null,
    openLockedPopup: false,
    angularPopupIsListening: false,
    csvHandler: null,
    isCheckIn: false,
    sessionFlag: false,
    checkinScheduleManageFlag: false,
    pageName: null,
    reportsEditLoader: false,
  },
  reducers: {
    showLoader: (state) => {
      state.isLoading = true;
    },
    hideLoader: (state) => {
      state.isLoading = false;
    },
    setReactState: (state, data) => {
      state.reactState = data.payload;
    },
    setAngularState: (state, data) => {
      state.angularState = data.payload;
    },
    setShowBackdrop: (state, data) => {
      state.showBackdrop = data.payload;
    },
    setCloseReactIframePopup: (state, data) => {
      state.closeReactIframePopup = data.payload;
    },
    setQuickHelpSource: (state, data) => {
      state.quickHelpSource = data.payload;
    },
    setAngularPath: (state, data) => {
      state.angularPath = data.payload;
    },
    setOpenLockedPopup: (state, data) => {
      state.openLockedPopup = data.payload;
    },
    setShowQuickAddMember: (state, data) => {
      state.showQuickAddMember = data.payload;
    },
    angularPopupIsListening: (state) => {
      state.angularPopupIsListening = true;
    },
    setCsvHandler: (state, data) => {
      state.csvHandler = data.payload;
    },
    setSessionFlag: (state, data) => {
      state.sessionFlag = data.payload;
    },
    setIsCheckIn: (state) => {
      state.isCheckIn = true
    },
    setCheckinScheduleManageFlag: (state, data) => {
      state.checkinScheduleManageFlag = data.payload
    },
    setPageName: (state, data) => {
      if (data.payload?.includes?.('Arbox')) {
        state.pageName = data.payload;
        return;
      }
      if (location.pathname.includes('user-profile')) {
          return;
      } else if (location.pathname.includes('calendar')) {
        if (location.pathname.includes('v5') && location.search) {
          if (location.search.includes('type=schedule')) {
            state.pageName = `${t('schedule')} - Arbox`
          }
          if (location.search.includes('type=availability')) {
            state.pageName = `${t('availability')} - Arbox`
          }
          if (location.search.includes('type=spaces')) {
            state.pageName = `${t('spaces')} - Arbox`
          }
        } else {
          state.pageName = `${t(location.pathname.split('/')[1])} - Arbox`
        }
      } else if (location.pathname.includes('reports')) {
        state.pageName = `${t(location.pathname.split('/')[2] || 'reports')} - Arbox`
      } else if (location.pathname.includes('external/')) {
        state.pageName = `${t(location.pathname.split('/')[2])} - Arbox`
      } else {
        state.pageName = `${t(location.pathname.substr(1))} - Arbox`
      }
    },
    setReportsEditLoader: (state, data) => {
      state.reportsEditLoader = data.payload;
    },
  },
  extraReducers: builder => {
    // getLanguages
    builder.addCase(getLanguages.fulfilled, (state, action) => {
      state.languageOptions = action.payload;
    })
  }
});

export const { showLoader, hideLoader, setReactState, setAngularState, setShowBackdrop,
  setCloseReactIframePopup, setQuickHelpSource, setAngularPath, setOpenLockedPopup,
  angularPopupIsListening, setShowQuickAddMember, setCsvHandler, setIsCheckIn, setSessionFlag, 
  setCheckinScheduleManageFlag, setPageName, setReportsEditLoader} = rootManagerSlice.actions;

export default rootManagerSlice.reducer;