import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { NO_MESSAGE_TYPE, PAGE_TYPE, TOAST_TYPE } from '../services/ConstHelper';
import { fetchApi } from "../services/HTTP";
import { sendMessageToAngular } from '../services/IframeIntegrationServices';
import { showToast } from '../services/stepperService';
import { updateCurrStep, updateTempSelection } from './stepperSlice';
import { store } from './store';
import {CREATE_EVENT_MODAL, CREATE_EVENT_TYPE_MODAL, updateModalRes} from "./modalManagerSlice";
import { flowOptions } from '../components/Structure/Schedule/Popups/CreateEvent/FlowOptions';
import { getCoachesOfBox } from './ScheduleSlice';

const userFlowSlice = createSlice({
  name: "userFlow",
  initialState: {
    loading: false,
    user: null,
    action: null,
    error: ''
  },
  reducers: {
    clearUserFlow: (state) => {
      state.loading = false;
      state.user = null;
      state.action = null;
      state.error = '';
    },
    setUserAction: (state, action) => {
      state.action = action.payload;
    },
    saveNewUser: (state, action) => {
      state.user = action.payload
    }
  },
  extraReducers: builder => {
    builder.addCase(isEmailAvailable.pending, (state, action) => {
      state.loading = true
    })
    builder.addCase(isEmailAvailable.fulfilled, (state, action) => {
      state.loading = false
      state.error = ''
      state.user = action.payload
    })
    builder.addCase(isEmailAvailable.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload?.error.message
      state.user = action.payload?.error.messageToUser
    })
    builder.addCase(saveStaffMember.pending, (state, action) => {
      state.loading = true
    })
    builder.addCase(saveStaffMember.fulfilled, (state, action) => {
      state.loading = false
      state.error = ''
      state.user = action.payload.user
    })
    builder.addCase(saveStaffMember.rejected, (state, action) => {
      state.loading = false
      state.user = null
      state.error = action.payload?.error
    })
    builder.addCase(saveLead.pending, (state, action) => {
      state.loading = true
    })
    builder.addCase(saveLead.fulfilled, (state, action) => {
      state.loading = false
      state.error = ''
      state.user = action.payload.user
    })
    builder.addCase(saveLead.rejected, (state, action) => {
      state.loading = false
      if (action.payload?.error) {
        state.error = action.payload?.error.message
        state.user = action.payload?.error.messageToUser
      } else if (action.payload?.message) {
        if (action.payload?.message?.includes('+')) {
          state.error = action.payload?.message?.split('+')[0]
          state.user = {user_fk: action.payload?.message.split('+')[1], deleted_at: action.payload?.message?.includes('DeletedAt')}
        }
        if (action.payload?.message?.includes('&token=')) {
          state.error = action.payload?.message?.split('&token=')[0]
          state.user = {token: action.payload?.message.split('&token=')[1]}
        }
      }
    })
  }

});

export const { clearUserFlow, setUserAction, saveNewUser } = userFlowSlice.actions;

export default userFlowSlice.reducer;

export const isEmailAvailable = createAsyncThunk('isEmailAvailable', async (params, { rejectWithValue, dispatch }) => {
  try {
    // note: add personal id as well to this
    (params?.email || params?.personal_id) && await fetchApi('usersBoxes/isEmailAvailable', 'POST', params, false, true);
    dispatch(updateTempSelection(null))
    const flowConfig = store.getState().stepper
    const stepIndex = flowConfig.steps.indexOf(flowConfig?.currStep);
    dispatch(updateCurrStep(flowConfig.steps[stepIndex + 1]))
    return null;
  } catch (e) {
    console.error(e);
    return rejectWithValue(e);
  }
})

export const saveStaffMember = createAsyncThunk('saveStaffMember', async ({ params }, { rejectWithValue, getState, dispatch }) => {
  try {
    const res = await fetchApi('usersBoxes/addNewStaffMember', 'POST', params, false, true);
    if(getState().modalManager.createEventModal.isOpen) {
      dispatch(updateModalRes({modalName: CREATE_EVENT_MODAL, res: {...res[0], flowName: flowOptions.STAFF_MEMBER}}))
      dispatch(getCoachesOfBox());
    }
    return { user: res[0], showToast: showToast() };
  } catch (e) {
    console.error(e);
    return rejectWithValue(e);
  }
})


export const saveLead = createAsyncThunk('saveLead', async ({ params }, { rejectWithValue, dispatch }) => {
  try {
    const res = await fetchApi('usersBoxes/addNewLead', 'POST', params, false, true);
    const box = store.getState().box?.box
    const showToast = moment().diff(box?.created_at, 'days') > 20
    const isEventManagementModalOpen = store.getState().modalManager?.eventManagementModal?.isOpen;
    const messageType = isEventManagementModalOpen ? NO_MESSAGE_TYPE : showToast ? TOAST_TYPE : PAGE_TYPE;
    return { user: res[0], messageType };
  } catch (e) {
    return rejectWithValue(e);
  }
})