import React, {useEffect, useMemo, useState} from 'react';
import AppText from "../../../../../common/AppText";
import {GlobalStyleAttributes, WebGlobalStyles} from "../../../../../../styles/GlobalStyles";
import {t} from "../../../../../../services/i18n";
import SharedTimepicker from "../../../../../common/SharedTimepicker";
import moment from "moment";
import {
    dbDateFormat,
    dbTimeFormat,
    getHourAndMinuteDiff,
    isMobile
} from "../../../../../../services/Helpers";
import {HStack, VStack, Text as NBText} from "native-base";
import {Colors} from "../../../../../../styles/Colors";
import {useDispatch, useSelector} from "react-redux";
import {
    COACH_SELECT,
    SINGLE_SESSION_EDIT_TYPE, SPACE_SELECT
} from "../../../../../../services/ConstHelper";
import {updateSummary} from "../../../../../../redux/stepperSlice";
import AdvanceSelect from "../../../../../common/AdvanceSelect";
import {getCategoriesByTypes} from "../../../../../../redux/ScheduleSlice";
import {fetchApi} from "../../../../../../services/HTTP";
import SimpleInput from "../../../../../common/SimpleInput";
import {optionalSteps} from "../StepOptions";
import {ActivityIndicator} from "react-native";
import {flowOptions} from "../FlowOptions";
import InfoTooltip from "../../../../../common/InfoTooltip";
import MultiSingleMenuWithSearch from "../../../../../common/MultiSingleMenuWithSearch";
import ConfirmModal from "../../../../../common/ConfirmModal";
import {Tooltip} from "antd";
import {GlobalConsts} from "../../../../../../styles/GlobalConsts";

const EditMultiDayConfig = (props) => {
    const { values, setFieldValue, errors, target,
        disableDate, disableDays, disableLocation, disableCoach, disableSpaces,
        allowDaySwitch, allowTimeExpandOnly,
        singleDay, showLength, showServices, showLocationSelect, showSpaceSelect, showCoachSelect, servicesTypeOverride,
        hideBlockRegistrationTime, showOnlyTimeSelectors, allowEditDateRange, showDaysEditInfoText } = props;
    const [coaches, setCoaches] = useState(null);
    const [selectedCoachName, setSelectedCoachName] = useState(null);
    const [selectedSpaceName, setSelectedSpaceName] = useState(null);
    const [timeLimitations, setTimeLimitations] = useState(null);
    const [initialDateRange, setInitialDateRange] = useState({start_date: null, end_date: null});
    const [hasDaysOrTimeChanged, setHasDaysOrTimeChanged] = useState(false);
    const editType = useSelector(state => state.stepper.editType)
    const box = useSelector((state) => state.box.box);
    const flowName = useSelector((state) => state.stepper.name);
    const selectionsTree = useSelector((state) => state.stepper.selectionsTree);
    const summary = useSelector((state) => state.stepper.summary);
    const services = useSelector(state => state.schedule.eventTypes.data)
    const lang = useSelector((state) => state.userSettings.lang);
    const selectedLocation = useSelector((state) => state.userSettings.scheduleLocation);
    const [boxLocale, setBoxLocale] = useState(lang?.language === 'Hebrew' ? 'he' : 'en');

    const mobile = isMobile
    const dispatch = useDispatch()

    const locations = useMemo(() => box ? box.locations_box.reduce((acc, location) => [...acc, {...location, label: location.location, value: location.id}],[]) : null, [box])
    const timeFormat = useMemo(() => box ? box.locations_box[0].time_format : null,[box])
    const dateFormat = useMemo(() => box ? box.locations_box[0].date_format : null,[box])
    const sessionTypes = useMemo(() => {
        if(services) {
            if(selectionsTree && selectionsTree[optionalSteps.EVENT_TYPE]) return services[selectionsTree[optionalSteps.EVENT_TYPE]].filter(service => !!service.active)
            else if(servicesTypeOverride) return services[servicesTypeOverride].filter(service => !!service.active)
        }
        return null
    },[services, selectionsTree])
    const spaces = useMemo(() => (selectedLocation?.spaces || []).reduce((acc, space) => [...acc, {...space, label: space.name, value: space.id}], []),[selectedLocation])
    const days = useMemo(() => (
        values.days || singleDay ? [
        {label: t(mobile ? 'sundayShortened' : 'sunday'), value: 0, isDisabled: singleDay ? values.day_of_week === 0 : values.days.some(day => day.day_of_week === 0)},
        {label: t(mobile ? 'mondayShortened' : 'monday'), value: 1, isDisabled: singleDay ? values.day_of_week === 1 : values.days.some(day => day.day_of_week === 1)},
        {label: t(mobile ? 'tuesdayShortened' : 'tuesday'), value: 2, isDisabled: singleDay ? values.day_of_week === 2 : values.days.some(day => day.day_of_week === 2)},
        {label: t(mobile ? 'wednesdayShortened' : 'wednesday'), value: 3, isDisabled: singleDay ? values.day_of_week === 3 : values.days.some(day => day.day_of_week === 3)},
        {label: t(mobile ? 'thursdayShortened' : 'thursday'), value: 4, isDisabled: singleDay ? values.day_of_week === 4 : values.days.some(day => day.day_of_week === 4)},
        {label: t(mobile ? 'fridayShortened' : 'friday'), value: 5, isDisabled: singleDay ? values.day_of_week === 5 : values.days.some(day => day.day_of_week === 5)},
        {label: t(mobile ? 'saturdayShortened' : 'saturday'), value: 6, isDisabled: singleDay ? values.day_of_week === 6 : values.days.some(day => day.day_of_week === 6)}
    ] : null), [values.days])

    useEffect(() => {
        if('coach_ub_id' in values && !coaches) fetchCoaches()
        if(!services) dispatch(getCategoriesByTypes(true))
    }, [values]);

    useEffect(() => {
        // console.log('values errors', values, errors)
    }, [values, errors]);

    useEffect(() => {
        if(values.start_date) {
            generateSummary()
        }
    }, [values.start_date, values.end_date]);

    useEffect(() => {
        if(values.date && values.days) {
            generateSummary()
        }
    }, [values.date, values.days]);

    useEffect(() => {
        if(values.date && values.start_time && values.end_time) {
            generateSummary()
        }
    }, [values.date, values.start_time, values.end_time, values.start_date]);

    useEffect(() => {
        if(coaches && values.coach_ub_id) {
            const coach = coaches.find(coach => coach.id === values.coach_ub_id)
            if(coach) setSelectedCoachName(coach.name)
        }
    }, [values.coach_ub_id, coaches]);

    useEffect(() => {
        if(showSpaceSelect && spaces) {
            const space = spaces.find(space => space.id === values.spaces_id)
            if(space) setSelectedSpaceName(space.name)
        }
    }, [showSpaceSelect, spaces]);

    useEffect(() => {
        if(Object.keys(values).length && !timeLimitations && allowTimeExpandOnly) {
            let limitations;
            if(singleDay) {
                limitations = {[values.day_of_week]: {min: moment(values.end_time, dbTimeFormat), max: moment(values.start_time, dbTimeFormat)}}
            } else {
                limitations = values.days.reduce((acc, day) => ({...acc, [day.day_of_week]: {min: moment(day.end_time, dbTimeFormat), max: moment(day.start_time, dbTimeFormat)}}),{})
            }
            setTimeLimitations(limitations)
        }
    }, [values.days, values.start_time, singleDay]);

    useEffect(() => {
        //save initial hug start date and end date (for confirm popup)
        if(!initialDateRange.start_date && values.start_date && allowEditDateRange) {
            setInitialDateRange({start_date: values.start_date, end_date: values.end_date})
        }
    }, [values.start_date, allowEditDateRange]);

    const onDateChange = (name, value) => {
        const date = moment(value).format(dbDateFormat)
        setFieldValue(name, date)
    }

    const onTimeChange = (name, value) => {
        const time = value.m.format(dbTimeFormat)
        setFieldValue(name, time)
        setHasDaysOrTimeChanged(true)
    }

    const generateSummary = () => {
        if(editType === SINGLE_SESSION_EDIT_TYPE) {
            const date = moment(values.date, dbDateFormat).locale(boxLocale).format(lang?.language == 'Hebrew' ? 'DD MMM, YYYY':'MMM DD, YYYY')
            const time = moment(values.start_time ?? target.time, dbTimeFormat).format(timeFormat);
            const endTime = moment(values.end_time ?? target.end_time, dbTimeFormat).format(timeFormat);
            const summary = t(`${flowName}-edit-single-summary`, {date, time, endTime})
            dispatch(updateSummary(summary))
        } else {
            const date = moment(values.date ,dbDateFormat).locale(boxLocale).format(lang?.language == 'Hebrew' ? 'DD MMM, YYYY':'MMM DD, YYYY');
            const startDate = values.start_date ? moment(values.start_date, dbDateFormat).locale(boxLocale).format(lang?.language == 'Hebrew' ? 'DD MMM, YYYY':'MMM DD, YYYY') : null;
            const endDate = values.end_date ? moment(values.end_date, dbDateFormat).locale(boxLocale).format(lang?.language == 'Hebrew' ? 'DD MMM, YYYY':'MMM DD, YYYY') : null;
            let timeDict = {}
            let days = values.days
            if(!days) {
                days = [{
                    start_time: values.start_time,
                    end_time: values.end_time,
                    day_of_week: moment(values.date, dbDateFormat).day(),
                }]
            }
            days.map(day => {
                const startTime = moment(day.start_time, dbTimeFormat).format(timeFormat)
                const endTime = moment(day.end_time, dbTimeFormat).format(timeFormat)
                const hourRangeText = `${t('fromTime')} ${startTime} ${t('toTime')} ${endTime}`
                timeDict[hourRangeText] = timeDict[hourRangeText] ? [...timeDict[hourRangeText], day.day_of_week] : [day.day_of_week]
            })

            const daysAndTimes = Object.keys(timeDict).reduce((acc, hours, i) => {
                const days = timeDict[hours].reduce((acc, day) => [...acc, t(`day-${day+1}`)],[])
                return `${acc} ${days.join(` ${t('and')} `)} ${hours}${i !== Object.keys(timeDict).length -1 ? ', ' : ''}`
            }, '');

            const summary = t(`${flowName}-summary`, {date: date, daysAndTimes: daysAndTimes, coach: showSpaceSelect ? selectedSpaceName : selectedCoachName, startDate: startDate, endDate: endDate})
            dispatch(updateSummary(summary))
        }
    }

    const fetchCoaches = async () => {
        if(showCoachSelect) {
            let coachesRes = await fetchApi('getCoachesOfBox', 'get', null, false, true);
            coachesRes.forEach(coach => {
                coach.label = `${coach.first_name} ${coach.last_name}`
                coach.value = coach.id
                return coach
            })
            setCoaches(coachesRes);
            if (coachesRes.length === 1) {
                setFieldValue('coach_ub_id', coachesRes[0].id)
            }
        } else {
            setCoaches([]);
        }
    }

    const getLengthText = (lengthObj) => {
        if(lengthObj.hours === 0) {
            return t('totalMinutesDuration', {totalMinutesDuration: lengthObj.minutes})
        } else if(lengthObj.minutes === 0) {
            return t('hour-length', {hour: lengthObj.hours})
        } else {
            return t('hour-minute-length', {hour: lengthObj.hours, minutes: lengthObj.minutes})
        }
    }

    const getDateRangeEditText = (isStart = true) => {
        const initDate = moment(initialDateRange[isStart ? 'start_date' : 'end_date'],dbDateFormat)
        const currDate = moment(values[isStart ? 'start_date' : 'end_date'],dbDateFormat)
        let isExtraDays = isStart ? currDate.isBefore(initDate) : currDate.isAfter(initDate)
        let dates;
        const initDateStr = initDate.format(dateFormat)
        const currDateStr = currDate.format(dateFormat)
        if(isStart) {
            dates = isExtraDays ? `${currDateStr} - ${initDateStr}` : `${initDateStr} - ${currDateStr}`
        } else {
            dates = isExtraDays ? `${initDateStr} - ${currDateStr}` : `${currDateStr} - ${initDateStr}`
        }
        return t(`date-edit-${isExtraDays ? 'add' : 'cancel'}`, {dates: dates})
    }

    const changeDayOfWeek = (index, value) => {
        setFieldValue(index !== null ? `days[${index}].day_of_week` : 'day_of_week', value)
        setHasDaysOrTimeChanged(true)
    }

    const renderDayRow = (day, index = null) => {
        const diffObj = getHourAndMinuteDiff(day.start_time, day.end_time)
        const max = allowTimeExpandOnly && timeLimitations ? timeLimitations[day.day_of_week].max : null
        const min = allowTimeExpandOnly && timeLimitations ? timeLimitations[day.day_of_week].min : null
        return (
            <HStack key={`edit-repeat-days-${day.id}`} alignItems={'center'} space={'0.8rem'}>
                {!showOnlyTimeSelectors &&
                <HStack w={mobile ? 'auto' : '9rem'}>
                    {allowDaySwitch ?
                        <AdvanceSelect options={days} initValue={day.day_of_week} onChange={(val) => changeDayOfWeek(index, val?.value)} dontSort={true} customStyle={{width: '100%'}}/>
                        :
                        <AppText fontSize={GlobalStyleAttributes.fonts.standard16} width={'5rem'}>{days[day.day_of_week].label}</AppText>
                    }
                </HStack>}
                <HStack space={'1rem'} alignItems={'center'}>
                    <HStack space={'0.3rem'} alignItems={'center'} width={showServices ? 'auto' : mobile ? '10rem' : '12rem'}>
                        <SharedTimepicker type={'time'}
                                          value={moment(index !== null ? values.days[index].start_time : values.start_time, dbTimeFormat)}
                                          onChange={(time) => onTimeChange(index !== null ? `days[${index}].start_time` : 'start_time', time)}
                                          inputStyle={[{width: mobile ? '4rem' : '6rem'}]}
                                          textStyle={{fontSize: mobile ? GlobalStyleAttributes.fonts.infoText : GlobalStyleAttributes.fonts.standard16}}
                                          isDisabled={disableDays}
                                          max={max}
                        />
                        <AppText fontSize={GlobalStyleAttributes.fonts.infoText} style={{opacity: disableDays ? 0.5 : 1}}>-</AppText>
                        <SharedTimepicker type={'time'}
                                          value={moment(index !== null ? values.days[index].end_time : values.end_time, dbTimeFormat)}
                                          onChange={(time) => onTimeChange(index !== null ? `days[${index}].end_time` : 'end_time', time)}
                                          inputStyle={[{width: mobile ? '4rem' : '6rem'}, (diffObj.hours + diffObj.minutes) <= 0 && {borderColor: Colors.error}]}
                                          textStyle={{fontSize: mobile ? GlobalStyleAttributes.fonts.infoText : GlobalStyleAttributes.fonts.standard16}}
                                          isDisabled={disableDays}
                                          min={min}
                        />
                        {showServices && sessionTypes && sessionTypes.length > 0 && <MultiSingleMenuWithSearch options={sessionTypes}
                                                                                                               defaultValue={day.limitations}
                                                                                                               onChange={(val) => setFieldValue('limitations', val)}
                                                                                                               placeholder={mobile ? t('choose') : t('select-services')}
                                                                                                               width={mobile ? '100px' : '150px'}/>}

                    </HStack>
                    {showLength &&
                    <HStack opacity={disableDays ? 0.5 : 1}>
                        <AppText fontSize={GlobalStyleAttributes.fonts.standard16} width={'5rem'}>{getLengthText(diffObj)}</AppText>
                    </HStack>
                    }
                </HStack>
            </HStack>
        )
    }

    return (
        <>
        {('coach_ub_id' in values && coaches) || !('coach_ub_id' in values) ?
        <VStack justifyContent={'space-between'} flex={1}>
            <VStack space={'0.5rem'}>
                {'date' in values && !allowEditDateRange &&
                <VStack space={'0.2rem'} width={mobile ? '100%' : '50%'}>
                    <AppText fontSize={GlobalStyleAttributes.fonts.infoText} mediumFont>{t('date')}</AppText>
                    <SharedTimepicker type={'date'} value={moment(values.date, dbDateFormat)} onChange={(date) => onDateChange('date', date)} isDisabled={disableDate} textStyle={{fontSize: GlobalStyleAttributes.fonts.standard16}}/>
                    <AppText fontSize={GlobalStyleAttributes.fonts.infoText} color={Colors.error}>{errors.date}</AppText>
                </VStack>
                }
                { allowEditDateRange &&
                    <VStack space={'0.2rem'}>
                        <HStack space={'0.5rem'} width={values.isUnlimited ? '50%' : '100%'}>
                            {'start_date' in values &&
                            <VStack space={'0.2rem'} flex={1}>
                                <AppText fontSize={GlobalStyleAttributes.fonts.infoText} mediumFont>{t('startDateLabel')}</AppText>
                                <Tooltip title={moment(values.start_date, dbDateFormat).isSameOrBefore(moment()) ? t('start-date-disabled-tooltip') : null} placement={GlobalConsts.menu.placement.TOP}>
                                    <div><SharedTimepicker type={'date'}
                                                          value={moment(values.start_date, dbDateFormat)}
                                                          onChange={(date) => onDateChange('start_date', date)}
                                                          textStyle={{fontSize: GlobalStyleAttributes.fonts.standard16}}
                                                          isDisabled={moment(values.start_date, dbDateFormat).isSameOrBefore(moment())}
                                                          min={moment(values.start_date, dbDateFormat).isAfter(moment()) ? moment().format(dbDateFormat) : null}
                                    /></div>
                                </Tooltip>
                            </VStack>}
                            {!values.isUnlimited &&
                            <VStack space={'0.2rem'} flex={1}>
                                <AppText fontSize={GlobalStyleAttributes.fonts.infoText} mediumFont>{t('endDateLabel')}</AppText>
                                <SharedTimepicker type={'date'} value={moment(values.end_date, dbDateFormat)} onChange={(date) => onDateChange('end_date', date)} textStyle={{fontSize: GlobalStyleAttributes.fonts.standard16}}/>
                            </VStack>}
                        </HStack>
                        {values.start_date !== initialDateRange?.start_date && <AppText fontSize={GlobalStyleAttributes.fonts.infoText} color={Colors.newDesign.darkGrey}>{getDateRangeEditText(true)}</AppText>}
                        {values.end_date && (values.end_date !== initialDateRange?.end_date) && <AppText fontSize={GlobalStyleAttributes.fonts.infoText} color={Colors.newDesign.darkGrey}>{getDateRangeEditText(false)}</AppText>}
                        <AppText fontSize={GlobalStyleAttributes.fonts.infoText} color={Colors.error}>{errors.date}</AppText>
                        <AppText fontSize={GlobalStyleAttributes.fonts.infoText} color={Colors.error}>{errors.start_date}</AppText>
                        <AppText fontSize={GlobalStyleAttributes.fonts.infoText} color={Colors.error}>{errors.end_date}</AppText>
                    </VStack>
                }
                {showCoachSelect && coaches && 'coach_ub_id' in values &&
                <VStack space={'0.2rem'}>
                    <AppText fontSize={GlobalStyleAttributes.fonts.infoText} mediumFont>{t('coach')}</AppText>
                    <AdvanceSelect options={coaches} initValue={values.coach_ub_id} type={COACH_SELECT} target={{name: 'coach_ub_id', value: 'id'}} onChange={(val) => setFieldValue('coach_ub_id', val?.id)} isDisabled={disableCoach} isError={!!errors.coach_ub_id}/>
                    <AppText fontSize={GlobalStyleAttributes.fonts.infoText} color={Colors.error}>{errors.coach_ub_id}</AppText>
                </VStack>
                }
                { showSpaceSelect && spaces &&
                <VStack space={'0.2rem'}>
                    <AppText fontSize={GlobalStyleAttributes.fonts.infoText} mediumFont>{t('space')}</AppText>
                    <AdvanceSelect type={SPACE_SELECT} options={spaces} initValue={values.spaces_id} onChange={(val) => setFieldValue('spaces_id', val?.id)} isClearable={true} isDisabled={disableSpaces} isError={!!errors.spaces_id}/>
                    <AppText fontSize={GlobalStyleAttributes.fonts.infoText} color={Colors.error}>{errors.spaces_id}</AppText>
                </VStack>
                }
                {locations.length > 1 && 'location_fk' in values && showLocationSelect &&
                <VStack space={'0.2rem'}>
                    <AppText fontSize={GlobalStyleAttributes.fonts.infoText} mediumFont>{t('location')}</AppText>
                    <AdvanceSelect options={locations} initValue={values.location_fk} onChange={(val) => setFieldValue('location_fk', val?.id)} isDisabled={disableLocation} isError={!!errors.location_fk}/>
                    <AppText fontSize={GlobalStyleAttributes.fonts.infoText} color={Colors.error}>{errors.location_fk}</AppText>
                </VStack>
                }
                {
                    'slot_length' in values &&
                    <VStack space={'0.2rem'} flex={1}>
                        <AppText fontSize={GlobalStyleAttributes.fonts.infoText} mediumFont>{t('increments')}</AppText>
                        <NBText style={{direction: 'inherit'}}>
                            <AppText fontSize={GlobalStyleAttributes.fonts.standard16}>{t('time-slot-half-1')}</AppText>
                            <SimpleInput value={values.slot_length}
                                         initValue={values.slot_length}
                                         onChangeCallback={(val) => setFieldValue('slot_length', val)}
                                         numericOnly={true}
                                         width={'3rem'}
                                         styleOverride={{margin: '0 0.5rem'}}
                                         status={errors.slot_length ? 'error' : null}
                            />
                            <AppText fontSize={GlobalStyleAttributes.fonts.standard16}>{t('time-slot-half-2')}</AppText>
                        </NBText>
                        <AppText fontSize={GlobalStyleAttributes.fonts.infoText} color={Colors.error}>{errors.slot_length}</AppText>
                    </VStack>
                }
                {
                    'break_length' in values &&
                    <VStack space={'0.2rem'} flex={1}>
                        <HStack>
                            <AppText fontSize={GlobalStyleAttributes.fonts.infoText} mediumFont>{t('break-length-header')}</AppText>
                            <InfoTooltip title={t('break-length-tooltip')}/>
                        </HStack>
                        <NBText style={{direction: 'inherit'}}>
                            <SimpleInput value={values.break_length}
                                         onChangeCallback={(val) => setFieldValue('break_length', val)}
                                         numericOnly={true}
                                         max={999}
                                         width={'4rem'}
                                         status={errors.break_length ? 'error' : null}

                            />
                            <AppText fontSize={GlobalStyleAttributes.fonts.standard16} style={{marginHorizontal: '0.5rem'}}>{t('break-length-explain')}</AppText>
                        </NBText>
                        <AppText fontSize={GlobalStyleAttributes.fonts.infoText} color={Colors.error}>{errors.break_length}</AppText>
                    </VStack>
                }
                {
                    'block_registration_time' in values && !hideBlockRegistrationTime &&
                    <VStack space={'0.2rem'} flex={1}>
                        <HStack>
                            <AppText fontSize={GlobalStyleAttributes.fonts.infoText} mediumFont>{t('limitRegistrationTimeAvailability')}</AppText>
                            <InfoTooltip title={t('limitRegistrationTimeAvailabilityTooltip')}/>
                        </HStack>
                        <NBText style={{direction: 'inherit'}}>
                            <AppText fontSize={GlobalStyleAttributes.fonts.standard16}>{t('limit-registration-time-half-1')}</AppText>
                            <SimpleInput value={values.block_registration_time}
                                         onChangeCallback={(val) => setFieldValue('block_registration_time', val)}
                                         inputType={'float'}
                                         width={'3rem'}
                                         styleOverride={{margin: '0 0.5rem'}}
                                         status={errors.block_registration_time ? 'error' : null}
                            />
                            <AppText fontSize={GlobalStyleAttributes.fonts.standard16}>{t('limit-registration-time-half-2')}</AppText>
                        </NBText>
                        <AppText fontSize={GlobalStyleAttributes.fonts.infoText} color={Colors.error}>{errors.block_registration_time}</AppText>
                    </VStack>
                }
                <VStack space={'0.2rem'} flex={1}>
                    <AppText fontSize={GlobalStyleAttributes.fonts.infoText} mediumFont>{t(allowDaySwitch ? 'schedule-on' : 'edit-event-time')}</AppText>
                    <VStack space={'0.5rem'}>
                        {!singleDay && values.days?.map((day, index) => renderDayRow(day, index))}
                    </VStack>
                    {singleDay && Object.keys(values).length > 0 && renderDayRow(values)}
                </VStack>
                {showDaysEditInfoText && hasDaysOrTimeChanged && <AppText fontSize={GlobalStyleAttributes.fonts.infoText} color={Colors.newDesign.darkGrey}>{t(`days-edit-info-text${isMobile ? '-mobile' : ''}`)}</AppText>}
            </VStack>
            <AppText fontSize={GlobalStyleAttributes.fonts.infoText} color={Colors.newDesign.darkGrey}>{summary}</AppText>
        </VStack>
        :
        <ActivityIndicator/>
        }
        </>
    );
};

export default EditMultiDayConfig;
