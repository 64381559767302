import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Box, HStack} from 'native-base';
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import AppText from "./AppText";
import { GlobalConsts } from "../../styles/GlobalConsts";
import orderBy from "lodash-es/orderBy";
import {Dropdown} from "antd";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";

const DropDownMenu = props => {
    const { items, title, onPress, fontSize, boldFont, sortAlphabetically, textStyles, width, showBorder, styleOverride = {}} = props;
    const [itemsNew, setItemsNew] = useState(items);

    useEffect(() => {
        const temp = items.reduce((acc, item) => [...acc, {...item, label: item.name ?? item.title, key: JSON.stringify(item)}],[])
        if(sortAlphabetically) {
            const sorted = orderBy(temp, [item => item.name.toLowerCase()], ['asc'])
            setItemsNew(sorted)
        } else {
            setItemsNew(temp)
        }
    }, [sortAlphabetically, items]);

    const onItemSelect = ({key}) => {
        const item = JSON.parse(key)
        onPress(item.id)
    }

    return (
        <>
             {itemsNew.length > 1 ?
                 <Dropdown
                     menu={{items: itemsNew, onClick: onItemSelect}}
                     trigger={['click']}
                     overlayStyle={{width: width ?? 'auto'}}
                 >
                     <div className={`custom-dropdown ${showBorder ? 'border' : ''}`} style={styleOverride}>
                         <AppText fontSize={fontSize} boldFont={boldFont || false} style={textStyles}>{title}</AppText>
                         <FontAwesomeIcon icon={solid('chevron-down')} size={GlobalConsts.iconSize.XXS} style={{marginLeft: 5, marginRight: 5}}/>
                     </div>
                 </Dropdown>
                 :
                <AppText fontSize={fontSize} boldFont={boldFont || false} style={textStyles}>
                    {title}
                </AppText>
            }
        </>
    );
}

export default DropDownMenu;