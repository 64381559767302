import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {fetchApi} from "../services/HTTP";
import axios from "axios";
import { logout } from './authSlice';
import { hideLoader } from './rootManagerSlice';

const userSlice = createSlice({
  name: "user",
  initialState: {
    loading: false,
    user: null,
    error: ''
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    }
  },
  /** GET USER DATA */
  extraReducers: builder => {
    builder.addCase(getUser.pending, (state, action) => {
      state.loading = true
    })
    builder.addCase(getUser.fulfilled, (state, action) => {
      state.loading = false
      state.error = ''
      state.user = action.payload
    })
    builder.addCase(getUser.rejected, (state, action) => {
      console.log('setUser error', action);
      state.loading = false
      state.error = action.payload?.error
      state.user = null
    })
  }

});

export const { setUser } = userSlice.actions;

export const getUser = createAsyncThunk('getUser', async (params, { rejectWithValue, dispatch }) => {
  try {
    return await fetchApi('getUserData', 'get', params, false, true);
  } catch (e) {
    console.log('setUser error', e);
    if (e?.statusCode == 401) {
      dispatch(hideLoader());
      dispatch(logout());
    }
    return rejectWithValue(e);
  }
})

export default userSlice.reducer;