import React, {useEffect, useMemo} from 'react';
import {HStack, VStack} from "native-base";
import {Colors} from "../../../../../../styles/Colors";
import AppText from "../../../../../common/AppText";
import {GlobalStyleAttributes} from "../../../../../../styles/GlobalStyles";
import {t} from "../../../../../../services/i18n";
import {Radio} from "antd";
import CustomButtonV2 from "../../../../../common/CustomButtonV2";
import {SCHEDULE_TYPE, USER_TYPE} from "../../../../../../services/ConstHelper";

const CustomFieldSelection = (props) => {
    const { values, setFieldValue } = props;

    console.log("CustomFieldSelection values", values)
    const types = useMemo(() => [
        {id: USER_TYPE, name: t('custom-field-clients')},
        {id: SCHEDULE_TYPE, name: t('custom-field-schedule')}
    ],[]);

    // useEffect(() => {
    //         setFieldValue('customFieldType', USER_TYPE)
    //
    // }, []);

    return (
        <Radio.Group name={'event-type-radio'} value={values.customFieldType}>
            <VStack space={'1rem'} w={'100%'}>
                {
                    types.map(type => (
                            <CustomButtonV2 onClick={() => setFieldValue('customFieldType',type.id)}
                                            key={`custom-button-type-selection-${type.id}`}
                                            iconProps={{iconColor: Colors.borderColor, iconHoverColor: Colors.hoverBlueLight}}
                                            override={({color}) =>
                                                (
                                                    <HStack space={'0.5rem'} alignItems={'start'} p={'1rem'} w={'100%'} borderWidth={1} borderColor={values.customFieldType === type.id ? Colors.newDesign.mainBlue : color} borderRadius={4}>
                                                        <Radio value={type.id} size="sm"/>
                                                        <VStack flex={1}>
                                                            <AppText fontSize={GlobalStyleAttributes.fonts.heading3} boldFont>{type.name}</AppText>
                                                            <AppText fontSize={GlobalStyleAttributes.fonts.heading3}>{t(`${type.id}-custom-field-explain`)}</AppText>
                                                        </VStack>
                                                    </HStack>
                                                )}
                            />
                        )
                    )
                }
            </VStack>
        </Radio.Group>
    );
};

export default CustomFieldSelection;
