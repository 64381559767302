import {combineReducers, configureStore} from '@reduxjs/toolkit';
import authReducer from "./authSlice";
import userReducer from "./userSlice";
import rootManagerReducer from "./rootManagerSlice";
import boxReducer from "./boxSlice";
import scheduleReducer from "./ScheduleSlice";
import accessControlReducer from "./accessControlSlice";
import tasksReducer from "./tasksSlice";
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import {createLogger} from "redux-logger";
import userSettingsSlice from "./userSettingsSlice";
import stepperSlice from "./stepperSlice";
import modalManagerSlice from "./modalManagerSlice";
import UserFlowSlice from './UserFlowSlice';

const logger = createLogger();

const persistConfig = {
    key: 'root',
    storage: storage,
    whitelist: ['auth', 'userSettings', 'accessControl', 'tasks']
}

const rootReducer = combineReducers({
    auth: authReducer,
    user: userReducer,
    box: boxReducer,
    rootManager: rootManagerReducer,
    userSettings: userSettingsSlice,
    schedule: scheduleReducer,
    stepper: stepperSlice,
    modalManager: modalManagerSlice,
    userFlow: UserFlowSlice,
    accessControl: accessControlReducer,
    tasks: tasksReducer
})

const persistedReducer = persistReducer(persistConfig, rootReducer);


export const store = configureStore({
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    }),
        // .concat(process.env.NODE_ENV !== 'production' ? logger : null),
    devTools: process.env.NODE_ENV !== 'production',
    reducer: persistedReducer,
})

export const persistor = persistStore(store);




