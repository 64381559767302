import {
    showLoader,
    hideLoader,
    setShowBackdrop,
    setReactState,
    setAngularState,
    setCloseReactIframePopup,
    setQuickHelpSource,
    setOpenLockedPopup,
    angularPopupIsListening,
    setCsvHandler,
    setAngularPath,
    setPageName
} from "../redux/rootManagerSlice";
import {store} from "../redux/store";
import {assumeUser, logout} from "../redux/authSlice";
import {generateUpgradeLink, getQueryObject, openUpgrade360Link, sendDataEvent} from "./Helpers";
import {getAutoTasks, getBoxCancelReasons, getBoxSuspendReasons, updateBox} from "../redux/boxSlice";
import i18next from "i18next";
import { getRedirectLinkToStripeConnect, isMobile } from "./Helpers";
import {t} from "./i18n";
import { openStepperModal } from "./stepperService";
import {
    BOX_CANCEL_REASONS_MODAL,
    CREATE_EVENT_MODAL,
    openModal,
    SETTINGS_VALUE_MODAL,
    MULTIPLE_RECURRING_PRICE_UPDATE_MODAL
} from "../redux/modalManagerSlice";
import { flowOptions } from "../components/Structure/Schedule/Popups/CreateEvent/FlowOptions";
import { clearUserFlow } from "../redux/UserFlowSlice";
import {cleanStepper, toggleStepperSuccess} from "../redux/stepperSlice";
import { fetchApi } from "./HTTP";
import {TOAST_TYPE, USER_FIELDS} from "./ConstHelper";
import {setSidebarCounter, setSuccessFlag} from "../redux/tasksSlice";
import Bugsnag from "@bugsnag/js";
import {getScheduleCustomFields} from "../redux/ScheduleSlice";
import {CANCEL_REASONS, LOST_REASONS_TYPE, SUSPEND_REASONS} from "../Configs/SettingsValueModalOptions";


/*------- OLD REACT INTEGRATION --------- */
export const formatOldReactQueryParams = (data) => {
    return `baseEp=${process.env.REACT_APP_API_URL}&lang_id=${store.getState().userSettings.lang?.id}&box_id=${data?.box_id}&token=${data?.token}`;
}

export const getReactIframeUrlByPath = (path, data) => {
    return `${process.env.REACT_APP_IFRAME_URL}/${path}?${formatOldReactQueryParams(data)}`;
}

/*------- ANGULAR INTEGRATION --------- */

export const formatOldAngularQueryParams = (data) => {
    return "boxId=" + data?.box_id + "&token=" + data?.token + "&langId=" + store.getState().userSettings.lang?.id + "&userBoxId=" + store.getState().user?.user?.id;
}

export const getAngularIframeUrlByPath = (path, data, withParams) => {
    if (!data) return "";
    return `${process.env.REACT_APP_WRAPPER_URL}/#/fragment/${path}${withParams ? '&' : '?'}${formatOldAngularQueryParams(data)}`;
}

export const openAngularPopup = (path, angularPopupIsListen, payload) => {
    if (!(store.getState().rootManager.angularPopupIsListening || angularPopupIsListen)) return;
    const userContext = store.getState().auth.data;
    if (path.includes('createUser')) {
        const boxesSettings = store.getState().box.box.settings;
        sendMessageToAngular("angularPopupHandler", {...(payload || getQueryObject(path.split('?')[1])), boxesSettings});
    } else {
        sendMessageToAngular("angularPopupHandler", payload || getQueryObject(path.split('?')[1]));
    }
    return getAngularIframeUrlByPath(path, userContext, true);
}

// Iframes sets

const microAppActionsHandler = (data, dispatch) => {
    const has = (permission) => store.getState().user?.user.permissions.includes(permission);
    if (data.source === 'angular') {
        switch (data.action) {
            case "close_popup":
                dispatch(setAngularState(false));
                break;
            case "show_backdrop":
                dispatch(setShowBackdrop(true));
                break;
            case "hide_backdrop":
                dispatch(setShowBackdrop(false));
                break;
            case "show_loader":
                dispatch(showLoader());
                break;
            case "hide_loader":
                dispatch(hideLoader());
                break;
            case "assume_user":
                (async () => {
                    await dispatch(assumeUser(data.payload));
                    if(data.payload.path){
                        window.location.replace(data.payload.path);
                        dispatch(setAngularState({navigate: data.payload.path }));
                    }
                    else window.location.reload();
                })()
                break;
            case "navigate_report":
                dispatch(setAngularState({navigate: `/reports/${data.payload.reportName}`}));
                break;
            case "open_quickHelp":
                dispatch(setQuickHelpSource(t(data.payload.path)));
                break;
            case "logout":
                dispatch(logout());
                break;
            case "go_to":
                if(!data.payload.permission || (data.payload.permission && has(data.payload.permission))) {
                    dispatch(setAngularState({navigate: data.payload.path}));
                }else{
                    dispatch(setAngularState({navigate: '/dashboard'}));
                }
                break;
            case "updateBox":
                dispatch(updateBox({
                    bio: data.payload.bio,
                    time_card: data.payload.time_card
                }));
                break;
            case "open_drawer":
                dispatch(setReactState(data.payload));
                break;
            case "add_to_schedule":
                if (store.getState().box?.box?.view_external_shcedule) {
                    dispatch(updateBox({view_external_shcedule: false}));
                }
                break;
            case "add_to_tasks":
                dispatch(updateBox({view_external_tasks: false}));
                break;
            case "add_to_forms":
                dispatch(updateBox({view_external_forms: false}));
                break;
            case "add_to_leads":
                dispatch(updateBox({view_external_leads: false}));
                break;
            case "purchase_to_member":
                dispatch(updateBox({view_external_members: false}));
                break;
            case "time_clock_in":
                dispatch(updateBox({time_card_in: true }))
                break;
            case "time_clock_out":
                dispatch(updateBox({time_card_in: false }))
                break;
            case 'send_freshpaint_event':
                sendDataEvent(data.payload.eventName, data.payload.metadata);
                break;
            case "angular_popup_listner":
                dispatch(angularPopupIsListening());
                break;
            case "export_to_csv":
                dispatch(setCsvHandler(data.payload));
                break;
            case 'open_new_message_modal':
            openStepperModal({ dispatch, modalName: CREATE_EVENT_MODAL,
                props: { initSelection: flowOptions.MESSAGE, navigate: () => null, target: data.payload.target},
                onClose: () => dispatch(cleanStepper())})
                break;
            case 'open_staff_modal':
            openStepperModal({ dispatch, modalName: CREATE_EVENT_MODAL,
                props: { initSelection: flowOptions.STAFF_MEMBER, navigate: (href) => dispatch(setAngularState({navigate: href}))},
                onClose: () => dispatch(clearUserFlow())})
                break;
            case 'open_task_modal':
                const taskModal = store.getState().modalManager;
                if (taskModal[CREATE_EVENT_MODAL]?.isOpen) return;
                openStepperModal({ dispatch, modalName: CREATE_EVENT_MODAL,
                    props: { initSelection: flowOptions.TASK_FLOW, addAnother: data.payload.target?.id? false : true, navigate: () => {}, target: data.payload.target},
                    onClose: () => dispatch(cleanStepper())})
                    break;
            case 'open_email_after_purchase':
                const modalManager = store.getState().modalManager;
                if (modalManager[CREATE_EVENT_MODAL]?.isOpen) return;
                openStepperModal({ dispatch, modalName: CREATE_EVENT_MODAL,
                    props: { initSelection: flowOptions.EMAIL_AFTER_PURCHASE, navigate: () => {}, target: data.payload.target},
                    onClose: () => dispatch(cleanStepper())})
                break;
            case 'open_penalty_modal':
                const modalManagerCheck = store.getState().modalManager;
                if (modalManagerCheck[CREATE_EVENT_MODAL]?.isOpen) return;
                openStepperModal({ dispatch, modalName: CREATE_EVENT_MODAL,
                    props: { initSelection: flowOptions.PENALTY_FLOW, navigate: () => {}, target: data.payload.penalty, all: data.payload.all},
                    onClose: () => dispatch(cleanStepper())})
                break;
            case 'open_lead_modal':
                openStepperModal({ dispatch, modalName: CREATE_EVENT_MODAL,
                    props: { initSelection: flowOptions.LEAD, target: data.payload.lead,
                        navigate: (href) => {
                            if (!href) return;
                            dispatch(setAngularPath(null))
                            setTimeout(() => dispatch(setAngularPath(`user_profile/${href.split('user-profile/')[1]}}`)), 200)
                        }},
                    onClose: () => dispatch(clearUserFlow())})
                break;
            case 'open_link_to_membership_modal':
                openStepperModal({ dispatch, modalName: CREATE_EVENT_MODAL,
                    props: { initSelection: flowOptions.SEND_LINK_TO_MEMBERSHIP_FLOW, target: data.payload.target,
                        navigate: () => null},
                    onClose: () => dispatch(cleanStepper())})
                break;
            case 'toggle_product_updates':
                fetchApi('setProductUpdate', 'post', {}, false, true)
                break;
            case 'set_page_name':
                dispatch(setPageName(t(data.payload.pageName)));
                break;
            case 'reload_page':
                window.location.reload()
                break;
            case 'get_box_integrations':
                sendMessageToAngular('box_info_integrations', {integrations: store.getState().box?.box?.integrations}, 'main_angular_frame_id');
                break;
            case 'open_upgrade_register_link':
                openUpgrade360Link();
                break;
            case 'open_convert_link_modal':
                openStepperModal({ dispatch, modalName: CREATE_EVENT_MODAL,
                    props: { initSelection: flowOptions.CONVERT_LINK_FLOW, target: data.payload.target,
                        navigate: () => null},
                    onClose: () => dispatch(cleanStepper())})
                break;
            case 'open_custom_field_edit':
                const customFieldModalManager = store.getState().modalManager;
                if (customFieldModalManager[CREATE_EVENT_MODAL]?.isOpen) return;
                dispatch(openModal({modalName: CREATE_EVENT_MODAL, props: { initSelection: flowOptions.CUSTOM_FIELD, navigate: (href) => dispatch(setAngularState({navigate: href})), target: data.payload.target ?? null}}))
                break;
            case 'is_box_has_new_site':
                sendMessageToAngular('is_box_has_new_site_info', {isBoxHasNewSite: store.getState().box?.box?.isBoxHasNewSite}, 'main_angular_frame_id');
                break;
            case 'get_auto_tasks':
                dispatch(setSuccessFlag({
                    type: TOAST_TYPE ,
                    message: t('auto-task-created-successfully')
                }))
                dispatch(getAutoTasks())
                break;
            case "open_permission_locked_popup":
                dispatch(setOpenLockedPopup(true));
                break;
            case 'send_bugsnag_error':
                const bugsnagUser = store.getState().user?.user;
                Bugsnag.notify(new Error(data.payload.error), function (event) {
                    event.severity = 'error'
                    event.context = data.payload.context
                    event.setUser(bugsnagUser?.id, bugsnagUser?.email, `Boxes Id: ${bugsnagUser?.box_fk}`)
                    event.addMetadata('errorData', { data: data.payload.data })
                });
                break;
            case 'add_edit_box_cancel_reason':
                dispatch(openModal({modalName: SETTINGS_VALUE_MODAL,  props: {type: CANCEL_REASONS, initialValue: data.payload.name, params: data.payload }}))
                break;
            case 'add_edit_box_suspend_reason':
                dispatch(openModal({modalName: SETTINGS_VALUE_MODAL,  props: {type: SUSPEND_REASONS, initialValue: data.payload.name, params: data.payload }}))
                break;
            case 'delete_restore_cancel_reason_successfully':
                dispatch(getBoxCancelReasons());
                break;
            case 'delete_restore_suspend_reason_successfully':
                dispatch(getBoxSuspendReasons());
                break;
            case 'open_required_fields_popup':
                const requiredFieldsManager = store.getState().modalManager;
                if (requiredFieldsManager[CREATE_EVENT_MODAL]?.isOpen) return;
                dispatch(openModal({modalName: CREATE_EVENT_MODAL, props: { initSelection: flowOptions.USER_FIELDS, navigate: () => {}, initProps: {type: USER_FIELDS}}}))
                break;
            case 'get_box_settings':
                sendMessageToAngular('box_settings', {settings: store.getState().box?.box?.settings}, 'main_angular_frame_id');
                break;

            case 'set_task_as_done':
                fetchApi('tasks/setTaskDone', 'POST', {id: data.payload.taskId, done: data.payload.done}, false, true)
                dispatch(setSidebarCounter())
                break;
            case 'custom_field_delete_success':
                dispatch(getScheduleCustomFields());
                break;
            case 'clear_cache':
                sendMessageToAngular('clear_cache', data.payload, 'main_angular_frame_id');
                break;
            case 'open_multiple_recurring_update':
                const modalManagerRecurring = store.getState().modalManager[MULTIPLE_RECURRING_PRICE_UPDATE_MODAL];
                if (modalManagerRecurring?.isOpen) return;
                dispatch(openModal({modalName: MULTIPLE_RECURRING_PRICE_UPDATE_MODAL, props: { membershipType: data.payload.membershipType }}))
                break;
        }
    } else if (data.source === 'react') {
        switch (data.action) {
            case 'open_drawer':
                dispatch(setReactState(data.payload));
                break;
            case "close_drawer":
                const iframe1 = document.getElementById("arbox-react-app-wrapper");
                const iWindow1 = iframe1.contentWindow;
                postMessageWithPayload(iWindow1, "rehydrate", data.payload, process.env.REACT_APP_IFRAME_URL);
                break;
            case "open_report":
                dispatch(setReactState({navigate: `/reports${data.payload.isV5 ? '-v5' : ''}/${data.payload.reportName}`}));
                break;
            case "go_back":
                dispatch(setReactState({navigate: `/reports-v5`}));
                break;
            case "member_details":
            case "lead_details":
            case "coach_details":
                window.open(`/user-profile/${data.payload.memberId || data.payload.leadId || data.payload.coachId}`, '_blank');
                break;
            case "navigate_to":
                dispatch(setReactState({navigate: `/${data.payload.path}`}));
                break;
            case "open_upgrade":
                const url = generateUpgradeLink();
                dispatch(setReactState({
                    url: url,
                    position: "center",
                    size: "90%",
                    height: "90%"
                }));
                break;
            case "add_task":
                const taskPopup = store.getState().modalManager;
                if (taskPopup[CREATE_EVENT_MODAL]?.isOpen) return;
                openStepperModal({ dispatch, modalName: CREATE_EVENT_MODAL,
                    props: { initSelection: flowOptions.TASK_FLOW, addAnother: data.payload.target?.id? false : true, navigate: () => {}, target: data.payload.target},
                    onClose: () => {
                        dispatch(cleanStepper())
                        dispatch(setSidebarCounter())
                    }})
                break;
            case "redirect_to_stripe":
                window.location.replace(getRedirectLinkToStripeConnect(store.getState().user?.user, store.getState().box?.box));
                break;
            case "open_permission_locked_popup":
                dispatch(setOpenLockedPopup(true));
                break;
            case "open_appointment_popup":
                dispatch(setReactState({navigate: `/calendar?action=appointment`}));
                break;
            case "pop_notification":
                const iframe = document.getElementById("arbox-react-app-wrapper");
                const iWindow = iframe.contentWindow;
                postMessageWithPayload(iWindow, "pop_notification", data.payload, process.env.REACT_APP_IFRAME_URL);
                break;
            case "after_rehydrate":
                dispatch(setReactState({}));
                break;
            case 'send_freshpaint_event':
                sendDataEvent(data.payload.eventName, data.payload.metadata);
                break;
            case 'update_counter':
                dispatch(setSidebarCounter())
                break;
        }
    } else if (data.source === 'webFlow') {
        console.log("webFlow" , data);
        switch (data.action.toLowerCase()) {
            case 'intercom': {
                dispatch(setCloseReactIframePopup(true));
                const alignment = i18next?.language === 'he' ? 'left' : 'right';
                window.Intercom?.('boot', {
                    ...window.intercomSettings,
                    alignment: alignment,
                    language_override: data.payload
                });
                window.Intercom?.('show');
                break;
            }
            case 'link': {
                // dispatch(setQuickHelpSource(null));
                dispatch(setCloseReactIframePopup(true));
                dispatch(setQuickHelpSource(data.payload));
                break;
            }
            case 'deeplink': {
                const path = data.payload.split('.com/').pop();
                if (handleLocksPermissionsPath(path, dispatch)) break;
                dispatch(setAngularState({navigate: path}));
                if(isMobile) dispatch(setCloseReactIframePopup(true));
                if (store.getState()?.rootManager?.quickHelpSource === t('product-updates-link')) {
                    // if product updates - deep link will close the popup
                    dispatch(setQuickHelpSource(null));
                }
                break;
            }
            case 'stripe': {
                window.location.replace(getRedirectLinkToStripeConnect(store.getState().user?.user, store.getState().box?.box));
                break;
            }
            default: {
                break;
            }
        }
    }
}

const handleLocksPermissionsPath = (path, dispatch) => {
    // TODO: complete all deeplinks
    const hasLock = (permission) => (store.getState().user?.user?.permissions || []).includes(`${permission}Lock`);
    const showLockedPopup = () => dispatch(setOpenLockedPopup(true));
    if (path.includes('workshop') && hasLock('workshops')) {
        return showLockedPopup();
    }
}

export const addWindowListner = (dispatch) => {
    if (!window.arboxIsListening)
        window.addEventListener("message", function (message) {
            if (message.data.channel === "arbox") {
                microAppActionsHandler(message.data, dispatch);
            }
        });
    window.arboxIsListening = true;
}

export const setDefaultMessageListners = (dispatch) => {
    addWindowListner(dispatch);
}

export const sendMessageToAngular = (message, payload, iframeId = null) => {
    var angularPopupsHandlerIframe = window.document.getElementById(iframeId || "angular-popups-handler-iframe");
    if (!angularPopupsHandlerIframe) return;
    var iWindow = angularPopupsHandlerIframe.contentWindow;
    if (iWindow) {
        iWindow.postMessage(
            {
                channel: "arbox",
                action: message,
                payload: payload || {},
            },
            process.env.REACT_APP_WRAPPER_URL
        );
    }
}

const postMessageWithPayload = (target, type, payload, url) => {
    target.postMessage(
        {
            channel: "arbox",
            action: type,
            payload: payload || {},
        },
        url
    );
}
