import React, {useState} from 'react';
import {Checkbox, ConfigProvider, Tooltip} from "antd";
import {t} from "i18next";

const CustomTaskDoneCheckbox = (props) => {
    const { onChange, onClick, checkedTooltip, uncheckedTooltip, colors, ...rest } = props;
    const [isChecked, setIsChecked] = useState(false);

    return (
        <ConfigProvider theme={{ components: {
                Checkbox: {
                    "borderRadiusSM": 10,
                    "colorPrimary": colors?.primary || "#0D7F56",
                    "colorPrimaryBorder": colors?.primaryBorder || "#E0E0E0",
                    "colorPrimaryHover": colors?.primaryHover || "#046141"
                }
            },}}>
            <Tooltip title={isChecked ? checkedTooltip : uncheckedTooltip}>
                <Checkbox onChange={(e) => {
                    onChange(e)
                    setIsChecked(e.target.checked)
                }} onClick={(e) => onClick(e)} {...rest}/>
            </Tooltip>

        </ConfigProvider>
    );
};

export default CustomTaskDoneCheckbox;