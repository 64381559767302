import { t } from './i18n';


const invalidEmailStrings = [
    'gamil.com',
    'walka.co.il',
    'walka.com',
    '.con',
    '.om',
    '.com.il',
    '.cim',
    'gmail.co',
    'gamil.com',
    'gamai.com',
    'gmail.co.il',
    'gimal.com',
    'gnail.com',
    'gmaul.com',
    'gmal.com',
    'gmail.con',
    '1gmail.com',
    'gimal.com',
    'yahoo.co.il',
    'yhaoo.com',
    'gmail.comm',
    'gmil.com',
]

export const emailRequired = value => (value ? undefined : t('emailIsRequired'));

export const emailRegex = value => value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,14}$/i.test(value) ? t('emailIsInvalid') : '';

export const validateAfterEmailSymbol = value => {
    if (value) {
        const str = value.split('@').pop();
        if (!str) return t('emailIsInvalid');
        if (str.length > 2) {
            if (invalidEmailStrings.includes(str))
                return t('invalidEmailDomain', {domain: str});
        }
        return '';
    } else {
        return '';
    }
}

export const required = value => (value ? '' : t('required'));

export const passwordRegex = value => !value || !/^(?=.*\d)(?=.*[a-zA-Z]).{6,}$/i.test(value) ? t('passwordTooWeak') : '';
