import React from 'react';
import {GlobalConsts} from "../../styles/GlobalConsts";
import {VStack} from "native-base";
import {FontAwesomeIcon} from "@fortawesome/react-native-fontawesome";
import {light} from "@fortawesome/fontawesome-svg-core/import.macro";
import {Colors} from "../../styles/Colors";
import {Tooltip} from "antd";
import {isMobile} from "../../services/Helpers";

const InfoTooltip = (props) => {
    const { title, ...rest } = props;

    if(isMobile) return null

    return (
        <Tooltip title={title} placement={GlobalConsts.menu.placement.TOP}>
            <VStack alignSelf={'center'} marginX={'0.5rem'} {...rest}><FontAwesomeIcon icon={light('circle-info')} color={Colors.newDesign.darkGrey} size={GlobalConsts.iconSize.XSS} /></VStack>
        </Tooltip>
    );
};

export default InfoTooltip;