import React from 'react';
import CustomModal from "./CustomModal";
import {VStack} from "native-base";
import AppText from "./AppText";
import {t} from "../../services/i18n";
import {isMobile} from "../../services/Helpers";
import {Button} from "antd";
import CustomDrawer from "./CustomDrawer";
import {Colors} from "../../styles/Colors";

const ConfirmModal = (props) => {
    const {isOpen, header, subheader, onCancel, onConfirm, cancelText, confirmText, notAnError, onClose, additionalText, hasSteps, confirmLoading, cancelLoading, disabled, containerDOM } = props;

    const renderBody = () => {
        return (
            <VStack>
                { subheader && <AppText>{subheader}</AppText> }
                { additionalText && <AppText>{additionalText}</AppText> }
            </VStack>
        )
    }

    const footerJSX = [
        <Button onClick={hasSteps ? onCancel : onClose} loading={cancelLoading} danger={!notAnError} style={{ color: !notAnError && Colors.newDesign.attendanceAbsentText, borderColor: !notAnError && Colors.newDesign.attendanceAbsentText, width: isMobile && '49%' }}>
            {cancelText || t('cancel')}
        </Button>,
        <Button type="primary" onClick={onConfirm} loading={confirmLoading} disabled={disabled} danger={!notAnError} style={!notAnError && { backgroundColor: !notAnError && Colors.newDesign.attendanceAbsentText, width: isMobile && '49%' }}>
            {confirmText || t('confirm')}
        </Button>
    ];

    return (
        !isMobile ?
        <CustomModal open={isOpen}
                     onCancel={onClose || onCancel} //onCancel is the X button, therefore it must call the closing function and not any CTA
                     height={'auto'}
                     width={isMobile ? '100%' : '500px'}
                     cancelButtonProps={{ danger: !notAnError }}
                     okButtonProps={{ danger: !notAnError }}
                     footer={footerJSX}
                     header={header}
                     centered
                     maskClosable={false}
                     confirmLoading={confirmLoading}
                     containerDOM={containerDOM}
        >
            {renderBody()}
        </CustomModal>
        :
        <CustomDrawer
            open={isOpen}
            onClose={onClose}
            header={header}
            footer={footerJSX}
            height={'max-content'}
            placement={'bottom'}
            style={{ borderTopLeftRadius: '25px', borderTopRightRadius: '25px' }}
            containerDOM={containerDOM}
        >
            {renderBody()}
        </CustomDrawer>
    );
};

export default ConfirmModal;