import { Radio, Space } from 'antd';
import moment from 'moment';
import { HStack } from 'native-base';
import React, {useEffect} from 'react';
import { useMemo } from 'react';
import EStyleSheet from 'react-native-extended-stylesheet';
import { useSelector } from 'react-redux';
import { dbDateFormat, isMobile } from '../../../services/Helpers';
import { t } from '../../../services/i18n';
import { Colors } from '../../../styles/Colors';
import { GlobalStyleAttributes } from '../../../styles/GlobalStyles';
import AdvanceSelect from '../../common/AdvanceSelect';
import AppText from '../../common/AppText';
import CustomCheckbox from '../../common/CustomCheckbox';
import FormInput from '../../common/FormInput';
import InfoTooltip from '../../common/InfoTooltip';
import SharedTimepicker from '../../common/SharedTimepicker';
import SimpleInput from '../../common/SimpleInput';
import PenaltyExtraProperties from './PenaltyExtraProperties';
import { useSearchParams } from "react-router-dom";
import DropDownMenu from '../../common/DropDownMenu';

const PenaltySettings = props => {
    const { values, handleChange, setFieldValue, target, errors, touched } = props;
    const box = useSelector((state) => state.box.box);
    const dateFormat = useMemo(() => box ? box.locations_box[0].date_format : null,[box])
    const [searchParams, setSearchParams] = useSearchParams();

    const onDateChange = (name, value) => {
        if (value && moment(value).isValid() && moment(value).format(dbDateFormat) !== moment(values.start_date).format(dbDateFormat)) {
            const date = moment(value).format(dbDateFormat);
            setFieldValue(name, date);
        }
    }

    useEffect(() => {
        if (searchParams.has('action')) {
            searchParams.delete('action');
            setSearchParams(searchParams);
        }
    }, []);

    const pastStartDate = useMemo(() => target?.start_date && target?.start_date < moment().format(dbDateFormat) ? true : false, [target])
    

    return (
        <>
            <FormInput collapsable={!!target} label={t('penalty-name')} description={values.name} maxLength={30} showCount error={touched.name && errors.name} status={touched.name && errors.name ? 'error' : null}
                placeholder={t('penalty-name-placeholder')} width={'100%'} value={values.name} onChangeCallback={handleChange('name')}  containerProps={{ mb: '1.5rem' }}/>
            <FormInput collapsable={!!target} label={t('starts-from')} description={moment(values.start_date, dbDateFormat).format(dateFormat)} infoTooltip={t('penalty-starts-tooltip')} containerProps={{ mb: '1.5rem' }} error={errors.start_date}>
                <SharedTimepicker min={pastStartDate ? null : moment()} isDisabled={pastStartDate} type={'date'} value={moment(values.start_date, dbDateFormat)} onChange={(date) => onDateChange('start_date', date)} textStyle={{ fontSize: GlobalStyleAttributes.fonts.standard16 }} />
            </FormInput>
            <FormInput collapsable={!!target} label={t('trigger')} description={`${t('punish-the-user-after')} ${values.trigger_occurrence_count} ${t('absences-in')} ${values.trigger_period_count} ${t('last-days')}`} width={'100%'} error={(touched.trigger_occurrence_count && errors.trigger_occurrence_count) || (touched.trigger_period_count && errors.trigger_period_count)} containerProps={{ mb: '1.5rem' }}>
                <HStack alignItems={'center'} space={'0.5rem'} flexWrap={isMobile && 'wrap'}>
                    <AppText>{t('punish-the-user-after')}</AppText>
                    <SimpleInput numericOnly width={'3rem'} value={values.trigger_occurrence_count} onChangeCallback={(val) => setFieldValue('trigger_occurrence_count', val)} status={touched.trigger_occurrence_count && errors.trigger_occurrence_count ? 'error' : null}/>
                    <AppText>{t('absences-in')}</AppText>
                    <SimpleInput numericOnly width={'3rem'} value={values.trigger_period_count} onChangeCallback={(val) => setFieldValue('trigger_period_count', val)} status={touched.trigger_period_count && errors.trigger_period_count ? 'error' : null}/>
                    <AppText>{t('last-days')}</AppText>
                </HStack>
            </FormInput>
            <FormInput collapsable={!!target} label={t('absences')} description={[...(values.late_cancellation ? [t('late-cancellation')] : []), ...(values.check_in ? [t('check-in')] : [])].join(', ')} containerProps={{ mb: '1.2rem' }} error={touched.check_in && errors.check_in || touched.late_cancellation && errors.late_cancellation}>
                <CustomCheckbox text={t('late-cancellation')} isChecked={values.late_cancellation} onChange={val => setFieldValue('late_cancellation', val)} />
                <CustomCheckbox text={t('check-in')} isChecked={values.check_in} onChange={val => setFieldValue('check_in', val)} />
            </FormInput>
            <FormInput collapsable={!!target} label={t('absence-type')} description={`${t(values.type?.replaceAll('_', '-') + '-punishment')} ${values[values.type]} ${t('days')}`} width={'100%'} error={touched[values.type] && errors[values.type]}  containerProps={{ mb: '1.5rem' }}>
                <Radio.Group onChange={(e) => handleChange('type')(e.target.value)} value={values.type}>
                    <Space size={0} direction={'vertical'} >
                    <Radio value={'block_app_cancel'}>
                        <HStack alignItems={'center'} space={'0.5rem'} height={'2.5rem'}>
                            <AppText>{t('block-app-cancel-punishment')}</AppText>
                            {values.type === 'block_app_cancel' && <SimpleInput numericOnly width={'3rem'} value={values.block_app_cancel} onChangeCallback={(val) => setFieldValue('block_app_cancel', val)} status={touched.block_app_cancel && errors.block_app_cancel ? 'error' : null}/>}
                            {values.type === 'block_app_cancel' && <AppText>{t('days')}</AppText>}
                            <InfoTooltip title={t('block-app-cancel-punishment-tooltip')}/>
                        </HStack>
                    </Radio>
                    <Radio value={'block_app_no_cancel'}>
                        <HStack alignItems={'center'} space={'0.5rem'} height={'2.5rem'}>
                            <AppText>{t('block-app-no-cancel-punishment')}</AppText>
                            {values.type === 'block_app_no_cancel' && <SimpleInput numericOnly width={'3rem'} value={values.block_app_no_cancel} onChangeCallback={(val) => setFieldValue('block_app_no_cancel', val)} status={touched.block_app_no_cancel && errors.block_app_no_cancel ? 'error' : null}/>}
                            {values.type === 'block_app_no_cancel' && <AppText>{t('days')}</AppText>}
                            <InfoTooltip title={t('block-app-no-cancel-punishment-tooltip')}/>
                        </HStack>
                    </Radio>
                    <Radio value={'reduction'}>
                        <HStack alignItems={'center'} space={'0.5rem'} height={'2.5rem'}>
                            <AppText>{t('reduction-punishment')}</AppText>
                            {values.type === 'reduction' && <SimpleInput numericOnly width={'3rem'} value={values.reduction} onChangeCallback={(val) => setFieldValue('reduction', val)} status={touched.reduction && errors.reduction ? 'error' : null}/>}
                            {values.type === 'reduction' && <AppText>{t('days')}</AppText>}
                            <InfoTooltip title={t('reduction-punishment-tooltip')}/>
                        </HStack>
                    </Radio>
                    </Space>
                </Radio.Group>
            </FormInput>
            <FormInput collapsable={!!target} label={t('time-after-class')} description={`${t('block-the-client')} ${values.time_after_class} ${t('hours-after-the-session')}`} width={'100%'} infoTooltip={t('time-after-class-tooltip')} containerProps={{ mb: target ? '1.5rem' : '4rem' }}>
                <HStack alignItems={'center'} space={'0.5rem'} flexWrap={isMobile && 'wrap'}>
                    <AppText>{t('block-the-client')}</AppText>
                    <DropDownMenu showBorder items={[1,2,3,4].map(o => ({id: o, name: `${o}`}))} title={values.time_after_class} onPress={(option) => setFieldValue('time_after_class', option ?? null)}/>
                    <AppText>{t('hours-after-the-session')}</AppText>
                </HStack>
            </FormInput>
            {target && <PenaltyExtraProperties {...props} />}
        </>
    );
};

export default PenaltySettings;

const styles = EStyleSheet.create({
    //style object
});