import axios from 'axios';
import { store } from '../redux/store';


export const axiosInstance = axios.create({
    headers: {
        'Content-Type': 'application/json',
    },
});

export const fetchApi = async (apiCall, method = 'get', data = null, isOpen = false, newServer = false, cancelToken = null) => {
    if (newServer && !isOpen) {
        axiosInstance.defaults.headers.common.token = store.getState().auth?.data?.token;
    } else {
        delete axiosInstance.defaults.headers.common.token;
        delete axiosInstance.defaults.headers.common.manage;
    }
    axiosInstance.defaults.headers.common.lang = store.getState().userSettings?.lang?.language === 'Hebrew' ? 'he' : 'en';
    if (newServer) {
        const isAccessControl = store.getState().accessControl.isAccessControl
        const isCheckIn = store.getState().rootManager.isCheckIn
        axiosInstance.defaults.headers.common.manage = isAccessControl ? 'ac' : isCheckIn ? 'checkin' : 'system';
    }

    const api = getUrl(apiCall, isOpen ,newServer);
    const options = {
        method,
        url: api,
        cancelToken: cancelToken ? cancelToken.token : null
    };
    if (data) {
        if (method === 'get') {
            options.params = data;
        } else {
            options.data = data;
        }
    }
    try {
        const response = await axiosInstance.request(options);
        return response.data;
    } catch (err) {
        throw err.response.data;
    }
};


 const getUrl = (apiCall , isOpen ,newServer) => {
    if(isOpen){
        return newServer ? `${process.env.REACT_APP_NEW_API_URL_OPEN}${apiCall}?XDEBUG_SESSION_START=PHPSTORM` : `${process.env.REACT_APP_API_URL}${apiCall}`;
    }
    else if(newServer) {
        return `${process.env.REACT_APP_NEW_API_URL}${apiCall}?XDEBUG_SESSION_START=PHPSTORM`;
    }
    else{
        return `${process.env.REACT_APP_API_URL}${apiCall}?XDEBUG_SESSION_START=PHPSTORM`;
    }

}