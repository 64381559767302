import React, {useEffect, useMemo, useState} from 'react';
import usePermissions from "../../../../../../services/Permissions";
import {useDispatch, useSelector} from "react-redux";
import {t} from "../../../../../../services/i18n";
import {HStack, Pressable, VStack} from "native-base";
import AppText from "../../../../../common/AppText";
import {GlobalStyleAttributes} from "../../../../../../styles/GlobalStyles";
import InfoTooltip from "../../../../../common/InfoTooltip";
import FormInput from "../../../../../common/FormInput";
import MultiSingleMenuWithSearch from "../../../../../common/MultiSingleMenuWithSearch";
import {Divider, Radio} from "antd";
import {Colors} from "../../../../../../styles/Colors";
import AdvanceSelect from "../../../../../common/AdvanceSelect";
import {HOK, IMMEDIATE, TRIAL} from "../../../../../../services/ConstHelper";
import {isBoxIL, isMobile} from "../../../../../../services/Helpers";
import { updateFlags } from '../../../../../../redux/stepperSlice';

const SITE_SHOW_IMMEDIATE_PAYMENT = 'showImmediate'
const SITE_SHOW_HOK_PAYMENT = 'showHOK'
const SITE_SHOW_DEBT_PAYMENT = 'showDebt'
const SITE_HIDE_ALL_PAYMENTS = 'hideOnSite'

const RELATIVE = 'hug_relative'
const FULL = 'hug_full'

const SeriesPaymentOptions = (props) => {
    const { values, setFieldValue, errors, touched, showEditMessage, isEdit, showTrialOption } = props;
    const box = useSelector((state) => state.box.box);
    const [siteDisplayOption, setSiteDisplayOption] = useState(null);
    const [isReady, setIsReady] = useState(!isEdit); // if edit => not ready, else => ready!
    const { has } = usePermissions();
    const dispatch = useDispatch()

    const hasHOKOption = useMemo(() => has('recurringPaymentActions'), []);
    const immediatePaymentOptions = useMemo(() => [{label: t('userPayNow'), value: 1}, {label: t('userEnterDebt'), value: 2}, {label: t('userPayNowOrDebt'), value: 3}], []);
    const selectedLocation = useMemo(() => {
        if(box && values.locations_box_fk) {
            return box.locations_box.find(l => l.id === values.locations_box_fk)
        }
    },[box, values.locations_box_fk])

    const currencySymbol = useMemo(() => selectedLocation?.currency_symbol,[selectedLocation])
    const allowOnlyDebt = useMemo(() => selectedLocation?.hasPayments === false, [selectedLocation]);
    const hasImmediate = useMemo(() => !values.isUnlimited, [values.isUnlimited]);
    const isGlobal = useMemo(() => !isBoxIL(), [box]);
    const taxes = useMemo(() => {
        if (selectedLocation && selectedLocation.taxes.length > 0) {
            return selectedLocation.taxes.reduce((acc, tax) => [...acc, {id: tax.id, name: `${tax.name} (${tax.percentage}%)`}],[])
        }
    },[selectedLocation])

    useEffect(() => {
        let paymentOptions = values.paymentOptions.length > 0 ? values.paymentOptions : []
        let shouldUpdate = false
        if(hasHOKOption && !values.paymentOptions.some(payment => payment.type === HOK)) {
            shouldUpdate = true
            paymentOptions.push({type: HOK, show_in_app: 0, price: null, max_installments: 1, taxes: [], relative_payment: FULL})
        }
        if(hasImmediate && !values.paymentOptions.some(payment => payment.type === IMMEDIATE)) {
            shouldUpdate = true
            paymentOptions.push({type: IMMEDIATE, show_in_app: 0, price: null, max_installments: 1, taxes: []})
        }
        if(showTrialOption && !values.paymentOptions.some(payment => payment.type === TRIAL)) {
            shouldUpdate = true
            paymentOptions.push({type: TRIAL, show_in_app: 0, price: 0, max_installments: 1, taxes: []})
        }

        if(!isReady || (paymentOptions.length && !siteDisplayOption)) {
            setIsReady(true)
            initEditSitePaymentOption(paymentOptions)
        }
        if(shouldUpdate) {
            if (isEdit) {
                // add new payment option 
                dispatch(updateFlags({overrideValues: true}))
            }
            setFieldValue('paymentOptions', paymentOptions)
        }
    }, [hasHOKOption, hasImmediate, values.paymentOptions]);

    useEffect(() => {
        if(values.paymentOptions) {
            if(siteDisplayOption) {
                handleShowOnSiteLogic()
            } else {
                const showInSite = values.paymentOptions.some(payment => payment.show_in_app > 0)
                if(!showInSite) setSiteDisplayOption(SITE_HIDE_ALL_PAYMENTS)
            }
        }
    }, [siteDisplayOption]);

    useEffect(() => {
        console.log('values, errors, touched',values, errors, touched)
    }, [values, errors, touched]);

    const handleShowOnSiteLogic = () => {
        let indexHOK = findPaymentTypeIndex(HOK)
        let indexImmediate = findPaymentTypeIndex(IMMEDIATE)
        let HOKShowInApp
        let immediateShowInApp

        switch (siteDisplayOption) {
            case SITE_HIDE_ALL_PAYMENTS:
                HOKShowInApp = 0;
                immediateShowInApp = 0;
                break;
            case SITE_SHOW_HOK_PAYMENT:
                HOKShowInApp = 1;
                immediateShowInApp = 0;
                break;
            case SITE_SHOW_IMMEDIATE_PAYMENT:
                HOKShowInApp = 0;
                immediateShowInApp = values.paymentOptions[indexImmediate].show_in_app > 0 ? values.paymentOptions[indexImmediate].show_in_app : 1;
                break;
            case SITE_SHOW_DEBT_PAYMENT:
                HOKShowInApp = 0;
                immediateShowInApp = 2;
                break;
            default:
                break;
        }

        if(indexHOK !== -1) setFieldValue(`paymentOptions[${indexHOK}].show_in_app`, HOKShowInApp)
        if(indexImmediate !== -1) setFieldValue(`paymentOptions[${indexImmediate}].show_in_app`, immediateShowInApp)
    }

    const initEditSitePaymentOption = (paymentOptions) => {
        const siteOption = paymentOptions.find(option => option.show_in_app !== 0)
        let siteSelectedDisplay = SITE_HIDE_ALL_PAYMENTS;
        if(siteOption) {
            if(siteOption.type === HOK) {
                siteSelectedDisplay = SITE_SHOW_HOK_PAYMENT
            } else if(siteOption.type === IMMEDIATE && siteOption.show_in_app === 2 && allowOnlyDebt) {
                siteSelectedDisplay = SITE_SHOW_DEBT_PAYMENT
            } else if(siteOption.type === IMMEDIATE && siteOption.show_in_app >= 1) {
                siteSelectedDisplay = SITE_SHOW_IMMEDIATE_PAYMENT
            }
        }
        setSiteDisplayOption(siteSelectedDisplay)
    }

    const onTrialSelectChange = (selection) => {
        const trialIndex = findPaymentTypeIndex(TRIAL)
        if(selection === 'no-trial') {
            setFieldValue(`paymentOptions[${trialIndex}].show_in_app`, 0)
            setFieldValue(`paymentOptions[${trialIndex}].price`, 0)
        } else {
            setFieldValue(`paymentOptions[${trialIndex}].show_in_app`, 1)
            setFieldValue(`paymentOptions[${trialIndex}].price`, 0)
        }
    }

    const findPaymentTypeIndex = (type) => {
        return values.paymentOptions.findIndex(option => option.type === type)
    }

    const getError = (paymentType, propName) => {
        const typeIndex = findPaymentTypeIndex(paymentType)
        if(typeIndex !== -1 && errors.paymentOptions && touched.paymentOptions && errors.paymentOptions[typeIndex] && touched.paymentOptions[typeIndex]) {
            return errors.paymentOptions[typeIndex][propName]
        }
    }

    const getSummary = () => {
        let summary = '';
        let paymentOptionsText = `${t('series-payments-office')} ${siteDisplayOption === SITE_HIDE_ALL_PAYMENTS ? `${t('only')} ` : ''}`;
        if(values.paymentOptions && values.paymentOptions.length) {
            values.paymentOptions.map((option, i) => {
                paymentOptionsText += t(`series-payments-price-${option.type}-summary`, {price: `${option.price || 0}${currencySymbol}`, max_installments: option.max_installments})
                if(option.type === HOK) {
                    paymentOptionsText += ` ${t(`series-HOK-summary-${option.relative_payment === RELATIVE ? 'relative' : 'full'}${values.isUnlimited ? '-unlimited' : ''}`)}`
                }
                if(i < values.paymentOptions.length - 1) {
                    paymentOptionsText += ` ${t('and')} `
                }
            })
            summary = `${t('series-payments-summary', {paymentOptions: paymentOptionsText})}. ${t(`${siteDisplayOption}-site-display-summary`)}`
        }
        return summary
    }

    const renderTrialSection = () => {
        const trialIndex = findPaymentTypeIndex(TRIAL)
        if(trialIndex < 0) return null

        const selectedOption = values?.paymentOptions?.[trialIndex].show_in_app === 0 ? 'no-trial' : 'allow-trial-class'

        return (
            <VStack space={'1rem'}>
                <Divider style={{margin: 0}}/>
                <VStack space={'0.5rem'}>
                    <HStack alignItems={'center'}>
                        <AppText fontSize={GlobalStyleAttributes.fonts.infoText} boldFont>{t('trial-hugim-selection-header')}</AppText>
                        <InfoTooltip title={t(`${isEdit ? 'edit-' : ''}trial-hugim-selection-tooltip`)}/>
                    </HStack>
                    <Radio.Group name={'trial-hugim-radio'} onChange={e => onTrialSelectChange(e.target.value)} value={selectedOption}>
                        <VStack space={'1rem'}>
                            <Radio value="no-trial" size="sm"><AppText fontSize={GlobalStyleAttributes.fonts.heading3} color={Colors.newDesign.mainText}>{t('no-trial-hugim-site')}</AppText></Radio>
                            <VStack space={'0.5rem'}>
                                <Radio value="allow-trial-class" size="sm">
                                    <HStack><AppText fontSize={GlobalStyleAttributes.fonts.heading3} color={Colors.newDesign.mainText}>{t(`${allowOnlyDebt ? 'debt-' : ''}${selectedOption === 'allow-trial-class' ? 'price-' : ''}allow-trial-hugim-site`)}</AppText></HStack>
                                </Radio>
                                {selectedOption === 'allow-trial-class' &&
                                    <VStack marginX={'1.5rem'}>
                                        <FormInput
                                            value={values?.paymentOptions?.[trialIndex]?.price}
                                            onChangeCallback={(val) => setFieldValue(`paymentOptions[${trialIndex}].price`, val)}
                                            label={taxes ? t('price') : null}
                                            width={'6rem'}
                                            formatAddon={currencySymbol}
                                            inputType={'float'}
                                            error={getError(TRIAL, 'price')}
                                            containerProps={{mb: 0}}
                                            errorHeight={'unset'}
                                        />
                                        {taxes && <VStack space={'0.2rem'} w={'12rem'}>
                                            <AppText fontSize={GlobalStyleAttributes.fonts.infoText} mediumFont>{t('taxes')}</AppText>
                                            <MultiSingleMenuWithSearch options={taxes} onChange={(val) => setFieldValue(`paymentOptions[${trialIndex}].taxes`, val)} placeholder={t('taxSelectorLabel')} defaultValue={values?.paymentOptions?.[trialIndex]?.taxes}/>
                                        </VStack>}
                                    </VStack>
                                }
                            </VStack>
                        </VStack>
                    </Radio.Group>
                </VStack>
            </VStack>
        )
    }

    return (
        <VStack flex={1} justifyContent={'space-between'} space={'1rem'}>
            {values.paymentOptions && values.paymentOptions.length > 0 &&
            <VStack space={'1rem'}>
                {values.paymentOptions.map((option, i) => (
                    <>
                    {hasHOKOption && option.type === HOK &&
                        <VStack space={'0.5rem'}>
                            <HStack>
                                <AppText fontSize={GlobalStyleAttributes.fonts.infoText} boldFont>{t('recurring-payment-settings')}</AppText>
                                <InfoTooltip title={t('recurring-payment-tooltip')}/>
                            </HStack>
                            <VStack space={'0.5rem'}>
                                <FormInput
                                    value={option.price}
                                    onChangeCallback={(val) => setFieldValue(`paymentOptions[${i}].price`, val)}
                                    label={t('price-per-month')}
                                    width={'6rem'}
                                    formatAddon={currencySymbol}
                                    inputType={'float'}
                                    error={getError(HOK, 'price')}
                                    containerProps={{mb: 0}}
                                    errorHeight={'unset'}
                                />
                                {taxes &&
                                <VStack space={'0.2rem'} w={'12rem'}>
                                    <AppText fontSize={GlobalStyleAttributes.fonts.infoText} mediumFont>{t('taxes')}</AppText>
                                    <MultiSingleMenuWithSearch options={taxes} onChange={(val) => setFieldValue(`paymentOptions[${i}].taxes`, val)} placeholder={t('taxSelectorLabel')} defaultValue={option.taxes}/>
                                </VStack>
                                }
                            </VStack>
                            <VStack space={'0.5rem'}>
                                <AppText fontSize={GlobalStyleAttributes.fonts.infoText} boldFont>{t('hug-relative-radio-header')}</AppText>
                                <Radio.Group name={'hug-relative-radio'} value={option.relative_payment} onChange={e => setFieldValue(`paymentOptions[${i}].relative_payment`, e.target.value)}>
                                    <VStack space={'0.8rem'}>
                                        <Radio value={FULL} size="sm"><AppText fontSize={GlobalStyleAttributes.fonts.heading3} color={Colors.newDesign.mainText}>{t(`hug-no-relative-radio${values.isUnlimited ? '-unlimited' : ''}`)}</AppText></Radio>
                                        <Radio value={RELATIVE} size="sm"><AppText fontSize={GlobalStyleAttributes.fonts.heading3} color={Colors.newDesign.mainText}>{t(`hug-with-relative-radio${values.isUnlimited ? '-unlimited' : ''}`)}</AppText></Radio>
                                    </VStack>
                                </Radio.Group>
                            </VStack>
                            {showEditMessage && <AppText fontSize={GlobalStyleAttributes.fonts.infoText} color={Colors.newDesign.darkGrey}>{t(`edit-payment-option-price${isGlobal ? '' : '-global'}${isMobile ? '-mobile' : ''}`)}</AppText>}
                        </VStack>
                    }
                    {hasImmediate && option.type === IMMEDIATE &&
                    <VStack space={'0.5rem'}>
                        <HStack>
                            <AppText fontSize={GlobalStyleAttributes.fonts.infoText} boldFont>{t('immediate-payment-settings')}</AppText>
                            <InfoTooltip title={t('immediate-payment-tooltip')}/>
                        </HStack>
                        <VStack space={'0.5rem'}>
                            <HStack space={'0.5rem'}>
                                <FormInput
                                    value={option.price}
                                    onChangeCallback={(val) => setFieldValue(`paymentOptions[${i}].price`, val)}
                                    label={t('total-price')}
                                    width={'6rem'}
                                    formatAddon={currencySymbol}
                                    inputType={'float'}
                                    error={getError(IMMEDIATE, 'price')}
                                    containerProps={{mb: 0}}
                                    errorHeight={'unset'}
                                />
                                {!allowOnlyDebt && !isGlobal &&
                                <FormInput
                                    value={option.max_installments}
                                    onChangeCallback={(val) => setFieldValue(`paymentOptions[${i}].max_installments`, val)}
                                    label={t('max-installments')}
                                    width={'4rem'}
                                    numericOnly={true}
                                    min={1}
                                    max={24}
                                    error={getError(IMMEDIATE, 'max_installments')}
                                    containerProps={{mb: 0}}
                                    errorHeight={'unset'}
                                />}
                            </HStack>
                            {taxes &&
                            <VStack space={'0.2rem'} w={'12rem'}>
                                <AppText fontSize={GlobalStyleAttributes.fonts.infoText} mediumFont>{t('taxes')}</AppText>
                                <MultiSingleMenuWithSearch options={taxes} onChange={(val) => setFieldValue(`paymentOptions[${i}].taxes`, val)} placeholder={t('taxSelectorLabel')} defaultValue={option.taxes}/>
                            </VStack>
                            }
                        </VStack>
                        {showEditMessage && <AppText fontSize={GlobalStyleAttributes.fonts.infoText} color={Colors.newDesign.darkGrey}>{t(`edit-payment-option-price${isGlobal ? '' : '-global'}${isMobile ? '-mobile' : ''}`)}</AppText>}
                    </VStack>
                    }
                </>
                ))}

                <VStack space={'0.5rem'}>
                    <AppText fontSize={GlobalStyleAttributes.fonts.infoText} boldFont>{t('payment-display-selection-header')}</AppText>
                    <Radio.Group name={'show-payment-on-site-radio'} value={siteDisplayOption} onChange={e => setSiteDisplayOption(e.target.value)}>
                        <VStack space={'0.8rem'}>
                            <Radio value={SITE_HIDE_ALL_PAYMENTS} size="sm"><AppText fontSize={GlobalStyleAttributes.fonts.heading3} color={Colors.newDesign.mainText}>{t('hideOnSite-radio')}</AppText></Radio>
                            {hasHOKOption && <Radio value={SITE_SHOW_HOK_PAYMENT} size="sm"><AppText fontSize={GlobalStyleAttributes.fonts.heading3} color={Colors.newDesign.mainText}>{t('showHOK-radio')}</AppText></Radio>}
                            {hasImmediate &&
                            <>
                                {allowOnlyDebt ?
                                    <Radio value={SITE_SHOW_DEBT_PAYMENT} size="sm"><AppText fontSize={GlobalStyleAttributes.fonts.heading3} color={Colors.newDesign.mainText}>{t('showDebt-radio')}</AppText></Radio>
                                    :
                                    <HStack alignItems={'start'}>
                                        <Radio value={SITE_SHOW_IMMEDIATE_PAYMENT} size="sm"/>
                                        <Pressable onPress={() => setSiteDisplayOption(SITE_SHOW_IMMEDIATE_PAYMENT)}>
                                            <VStack space={'0.5rem'}>
                                                <AppText fontSize={GlobalStyleAttributes.fonts.heading3} color={Colors.newDesign.mainText}>{t('showImmediate-radio')}</AppText>
                                                {siteDisplayOption === SITE_SHOW_IMMEDIATE_PAYMENT &&
                                                <VStack w={'16rem'}>
                                                    <AdvanceSelect options={immediatePaymentOptions} initValue={values.paymentOptions[findPaymentTypeIndex(IMMEDIATE)].show_in_app} onChange={(val) => setFieldValue(`paymentOptions[${findPaymentTypeIndex(IMMEDIATE)}].show_in_app`, val?.value)}/>
                                                </VStack>}
                                            </VStack>
                                        </Pressable>
                                    </HStack>
                                }
                            </>}
                        </VStack>
                    </Radio.Group>
                </VStack>
                {showTrialOption && renderTrialSection()}
            </VStack>}
            <AppText fontSize={GlobalStyleAttributes.fonts.infoText} color={Colors.newDesign.darkGrey}>{getSummary()}</AppText>
        </VStack>
    );
};

export default SeriesPaymentOptions;