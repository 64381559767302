import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { intercomLanguageOnChange } from './Helpers';

// import {getLocales} from 'react-native-localize';
import { fetchApi } from './HTTP';

// export const languageCodes = {en: '1', he: '5'};

const getLanguageByCode = (languageId, segmentId) => {
    try {
        return fetchApi(`external/translation?langId=${languageId}${segmentId ? ('&segment=' + segmentId) : ''}`, 'get', null, true);
    } catch (error) {
        console.log(error);
        return {};
    }
};

export const translation = {
    init: async (language, segmentId) => {
        const lang = await getLanguageByCode(language.id, segmentId);
        const langCode = language?.language?.slice(0,2)?.toLowerCase();
        intercomLanguageOnChange(langCode);
        i18n
        .use(initReactI18next)
        .init({
            lng: langCode,
            fallbackLng: [langCode],
            segmentId: segmentId,
            resources: {
                [langCode]: {translation: lang}
            },
            initImmediate: true,
            interpolation: {
                escapeValue: false,
            },
        }, (error) => {
            if (error) {
                return reject(error);
            }
        });
    },
    t: (key, options) => i18n.t(key, options),
};

export const t = (key, options) => i18n.t(key, options) || key;

export const isRTL = () => i18n.dir() === 'rtl';

export const langCode = () => i18n.language

