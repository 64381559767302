import React, { useEffect, useMemo, useState } from 'react';
import { ActivityIndicator } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';
import { useSelector } from 'react-redux';
import { MembershipTypes } from '../../../Configs/DatabaseConsts';
import { COACH_SELECT } from '../../../services/ConstHelper';
import { fetchApi } from '../../../services/HTTP';
import { t } from '../../../services/i18n';
import usePermissions from '../../../services/Permissions';
import { Colors } from '../../../styles/Colors';
import { GlobalStyleAttributes } from '../../../styles/GlobalStyles';
import AdvanceSelect from '../../common/AdvanceSelect';
import AppText from '../../common/AppText';
import FormInput from '../../common/FormInput';
import _ from "lodash";
import CustomCheckbox from '../../common/CustomCheckbox';

const EmailAfterPurchase = props => {
    const { values, target, touched, errors, setFieldValue } = props;
    const box = useSelector((state) => state.box.box);
    const [staffMembers, setStaffMembers] = useState(null);
    const [membershipTypes, setMembershipTypes] = useState(null);
    const locationOptions = box?.locations_box?.map(location => ({ value: location.id, label: location.location })) || [];
    const planOptions = useMemo(() => membershipTypes?.filter(mt => mt.type === MembershipTypes.PLAN), [membershipTypes])
    const sessionOptions = useMemo(() => membershipTypes?.filter(mt => mt.type === MembershipTypes.SESSION), [membershipTypes])
    const serviceOptions = useMemo(() => membershipTypes?.filter(mt => mt.type === MembershipTypes.SERVICE), [membershipTypes])
    const itemOptions = useMemo(() => membershipTypes?.filter(mt => mt.type === MembershipTypes.ITEM), [membershipTypes])
    const workshopOptions = useMemo(() => membershipTypes?.filter(mt => mt.type === MembershipTypes.WORKSHOP), [membershipTypes])
    const hugimOptions = useMemo(() => Object.values(_.groupBy(membershipTypes?.filter(mt => mt.type === MembershipTypes.REGULAR || mt.type === MembershipTypes.RECURRING), 'series_id')).map(mts => ({...mts[0], mts})), [membershipTypes])
    const { has, hasLock } = usePermissions()

    const getStaffMembers =  async () => {
        const result = await fetchApi('getCoachesOfBox', 'get', {all: true}, false, true)
        setStaffMembers(result.map(r => ({...r, label: `${r.first_name || ''} ${r.last_name || ''}`, value: r.id})))
    }

    const getMembershipTypes =  async () => {
        const result = await fetchApi('getMembershipTypesOfBox', 'get', null, false, true)
        setMembershipTypes(result.filter(mt => mt.location_box_fk === target?.locations_box_fk).map(r => ({...r, label: r.name, value: r.id})))
    }

    useEffect(() => {
        getStaffMembers()
        getMembershipTypes()
        setFieldValue('locations_box_id', target?.locations_box_fk)
    }, []);

    const onMembershipTypeChange = (value, type) => {
        if (type === 'hugim') {
            setFieldValue('membership_types' ,[...values.membership_types.filter(mt => ![MembershipTypes.REGULAR, MembershipTypes.RECURRING].includes(mt.type) || mt.location_box_fk !== target?.locations_box_fk 
            ), ...value.map(v => v.mts).flat()])//all membership type for the same hug
        } else {
            setFieldValue('membership_types' ,[...values.membership_types.filter(mt => mt.type !== type || mt.location_box_fk !== target?.locations_box_fk 
                ), ...value])
        }
    }

    if (!staffMembers || !membershipTypes) return <ActivityIndicator/>
    return (
        <>
            {locationOptions.length > 1 && <FormInput containerProps={{ mb: '2rem' }} label={t('location')} width={'100%'}>
                <AdvanceSelect isDisabled dontSort options={locationOptions} initValue={target?.locations_box_fk} />
            </FormInput>}
            <FormInput containerProps={{ mb: '2rem' }} label={t('staffMembersSettings')} width={'100%'} error={(touched.users_boxes && errors.users_boxes) ? errors.users_boxes : null}>
                <AdvanceSelect placeholder={t('select-staff')} isMulti type={COACH_SELECT} target={{name: 'users_boxes', value: 'id'}} options={staffMembers} initValue={values.users_boxes} onChange={(value) => setFieldValue('users_boxes' ,value)} isError={touched.users_boxes && errors.users_boxes}/>
            </FormInput>
            <FormInput containerProps={{ mb: '2rem' }} label={t('promoCodeSelectLabel-plan')} width={'100%'} >
                <AdvanceSelect placeholder={t('select-plans')} isMulti options={planOptions} initValue={values.membership_types} onChange={value => onMembershipTypeChange(value, MembershipTypes.PLAN)} isError={touched.membership_types && errors.membership_types}/>
            </FormInput>
            <FormInput containerProps={{ mb: '2rem' }} label={t('promoCodeSelectLabel-session')} width={'100%'} >
                <AdvanceSelect placeholder={t('select-session-packs')} isMulti options={sessionOptions} initValue={values.membership_types} onChange={value => onMembershipTypeChange(value, MembershipTypes.SESSION)} isError={touched.membership_types && errors.membership_types}/>
            </FormInput>
            <FormInput containerProps={{ mb: '2rem' }} label={t('promoCodeSelectLabel-service')} infoTooltip={t('services-info-tooltip')} width={'100%'} >
                <AdvanceSelect placeholder={t('select-services-types')} isMulti options={serviceOptions} initValue={values.membership_types} onChange={value => onMembershipTypeChange(value, MembershipTypes.SERVICE)} isError={touched.membership_types && errors.membership_types}/>
            </FormInput>
            <FormInput containerProps={{ mb: '2rem' }} label={t('promoCodeSelectLabel-item')} width={'100%'} >
                <AdvanceSelect placeholder={t('select-items')} isMulti options={itemOptions} initValue={values.membership_types} onChange={value => onMembershipTypeChange(value, MembershipTypes.ITEM)} isError={touched.membership_types && errors.membership_types}/>
            </FormInput>
            { has('hugim') && <FormInput containerProps={{ mb: '2rem' }} label={t('hugim')} width={'100%'} >
                <AdvanceSelect placeholder={t('select-hugim')} isMulti options={hugimOptions} initValue={values.membership_types} onChange={value => onMembershipTypeChange(value, 'hugim')} isError={touched.membership_types && errors.membership_types}/>
            </FormInput>}
            { has('workshops') && !hasLock('workshops') && <FormInput containerProps={{ mb: '2rem' }} label={t('promoCodeSelectLabel-workshop')} width={'100%'} >
                <AdvanceSelect placeholder={t('select-workshops')} isMulti options={workshopOptions} initValue={values.membership_types} onChange={value => onMembershipTypeChange(value, MembershipTypes.WORKSHOP)} isError={touched.membership_types && errors.membership_types}/>
            </FormInput>}
            { has('bookForTrialClassSite') && !hasLock('bookForTrialClassSite') &&  <FormInput containerProps={{ mb: '2rem' }} label={t('enable-trial-class-on-site')} width={'100%'} >
                <CustomCheckbox isChecked={values.trial_class} onChange={(val) => setFieldValue('trial_class', val)} text={t('email-after-purchase-for-all-trial-classes')}/>
            </FormInput>}
            {touched.membership_types && errors.membership_types && <AppText color={Colors.error} fontSize={GlobalStyleAttributes.fonts.infoText} mediumFont>{errors.membership_types}</AppText>}
        </>
    );
};

export default EmailAfterPurchase;

const styles = EStyleSheet.create({
    //style object
});