import React from 'react';
import {Switch} from "antd";

const CustomSwitch = (props) => {
    const { isChecked, onToggle } = props;
    return (
        <Switch checked={isChecked} onChange={onToggle} />
    );
};

export default CustomSwitch;
