import React, {useEffect, useMemo, useRef, useState} from 'react';
import {isMobile, isWeb} from "../../services/Helpers";
import {Datepicker, momentTimezone, locale} from "@mobiscroll/react";
import moment from "moment";
import {useSelector} from "react-redux";
import 'moment/locale/he'
import { Colors } from '../../styles/Colors';
import EStyleSheet from "react-native-extended-stylesheet";

const SharedTimepicker = (props) => {
    const { type, value, onChange, inputStyle, wrapperStyle, isDisabled, textStyle, isError, ...rest} = props
    const box = useSelector((state) => state.box.box);
    const lang = useSelector(state => state.userSettings.lang)
    const [timeFormat, setTimeFormat] = useState(null);
    const [dateFormat, setDateFormat] = useState(null);
    const [prevValue, setPrevValue] = useState(null);
    const boxLocale = useMemo(() => lang?.language === 'Hebrew' ? 'he' : 'en', [lang]);
    momentTimezone.moment = moment;
    moment.locale(boxLocale)

    const placeholder = useMemo(() => {
        if(type && timeFormat && dateFormat) {
            switch (type) {
                case 'time':
                    return rest?.select === 'range' ? `${timeFormat} - ${timeFormat}` : timeFormat

                case 'date':
                    return rest?.select === 'range' ? `${dateFormat} - ${dateFormat}` : dateFormat
                default:
                    return null
            }
        }
    }, [type, timeFormat, dateFormat]);

    useEffect(() => {
        if(box) {
            const time_format = box.locations_box[0].time_format
            const date_format = box.locations_box[0].date_format
            setTimeFormat(time_format)
            setDateFormat(date_format)
        }
    }, [box]);

    const valueChanged = (e) => {
        if(onChange && e?.valueText && e?.valueText !== '' && prevValue !== e?.valueText) {
            setPrevValue(e?.valueText)
            onChange(e.value)
        }
    }

    return (
        <>
            {
                isWeb() ?
                    <>
                        <Datepicker
                            timezonePlugin={momentTimezone}
                            controls={[type === 'date' && !isMobile ? 'calendar' : type]}
                            touchUi={isMobile}
                            responsive={{medium: {display: 'bottom'}, large: {display: 'anchored'}}}
                            onChange={valueChanged}
                            value={value}
                            timeFormat={timeFormat}
                            dateFormat={dateFormat}
                            defaultValue={moment()}
                            returnFormat="moment"
                            locale={locale[boxLocale]}
                            stepMinute={5}
                            inputProps={{placeholder: rest.placeholder ? rest.placeholder : placeholder, className: 'custom-timepicker-input', style: EStyleSheet.flatten([isDisabled && {borderColor: Colors.borderColorInput}, isError && {borderColor: Colors.newDesign.attendanceAbsentText}, wrapperStyle, inputStyle])}}
                            disabled={isDisabled}
                            {...rest}
                        />
                    </>
                    :
                    <></>
            }
        </>
    );
};

export default SharedTimepicker;
